/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 */

// Sessions that are not purchased and which are recomended will list here.

import React, { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import { Grid } from "@mui/material";
import Navbar from "../../../shared/Navbar";
import { useNavigate } from "react-router-dom";
import {  LiveTuitionCommonCard,
} from "../../../components/uiComponents/uiComponents";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import "../liveTuition.scss";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import { getWindowDimensions, getxsValue } from "../../../shared/utils/utilityFunctions";


/** Actual Live LiveTuitionRecommended page function starts here **/
export function LiveTuitionRecommended() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    userName: "",
    standard: "",
    syllabus: "",
    profile_image:""
  });
  const [nonPurchasedSessions, setNonPurchasedSessions] = useState([
    {
      TransactionId: "",
      topic_name: "",
      teacher_name: "",
      subject_name: "",
      mst_session_course_id: "",
    },
  ]);
  const [loader, setLoader] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    window.scrollTo(0, 80);

    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };

  useEffect(() => {
    if (profileData.syllabus) getNonPurchasedSessions();
    function getNonPurchasedSessions() {
      setLoader(true);
      let apiData = {
        user: profileData.userName,
        standardId: profileData.standard,
        syllabusId: profileData.syllabus,
      };
      console.log(apiData);
      services.getNonPurchasedSessions(apiData).then((res: any) => {
        setLoader(false);
        console.log(res);
        if (res.failed) console.log("failed");
        else {
          console.log(res.data.data);
          setNonPurchasedSessions(res.data.data);
        }
      });
    }
  }, [profileData.syllabus]);
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

    function navigateTo(path: string, props?: any) {
      navigate(path, { state: props });
    }
  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [selected, setSelected] = React.useState([]);

  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };

  // const [open, setOpen] = React.useState(false);
  
   const handleOpen = (course?: any) => {
    // opens or navigates to details page with course ID
    console.log(course,"live t rec components 114 ")
    
    navigateTo("/live-tuition/details", course.mst_session_course_id);
  };
  // const handleClose = () => setOpen(false);
  const handleOpenTeacher = () =>{
    //  setTeacherDetails(true);
    return "function not implemented as of now"
    }
  // const [openTeacherDetails, setTeacherDetails] = React.useState(false);

  return (
    loader ? <LoaderComponent /> :
    <Grid container style={{ width: "100vw" }}>

      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"live-tuition"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <TopBarComponent search={true} logo={false} profileData={profileData} />
        <span
          className="backToLiveTuition"
          onClick={() => {
            navigateTo("/live-tuition");
          }}
        >
          {"<"} Live Tuition
        </span>
        {/* scheduled sessions */}
        <div style={{ marginLeft: "3vw" }}>
          <Grid style={{ marginTop: "48px" }}>
            <span className="recommendedLabelText1">
              Recommended Live Tuitions
            </span>
            <br />
            <span className="recommendedLabelText2">
              Test your subject knowledge.
            </span>
          </Grid>
          <Grid container spacing={1} style={{ paddingLeft: "24px" }}>
            {nonPurchasedSessions?.map((obj: any) => (
              <LiveTuitionCommonCard
                itemId={obj.mst_session_course_id} // NOTE: itemId is required to track items
                title={obj.course_name}
                key={obj.content_uuid}
                onClick={handleClick(obj.mst_session_course_id)}
                selected={isItemSelected(obj.mst_session_course_id)}
                button={"More Details"}
                handleOpen={handleOpen}
                handleOpenTeacher={handleOpenTeacher}
                teacherName={obj.teacher_name}
                date={obj.session_date}
                time={obj.from_time}
                seats={"4/5"}
                subject_name={
                  obj.session_subject_alias_name
                    ? obj.session_subject_alias_name
                    : obj.subject_name
                }
                mst_session_course_id={obj.mst_session_course_id}
              />
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
