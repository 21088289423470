/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Reports page.
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

import { services } from "../../services/api_service";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import { formatSecTommss, getDate } from "../../shared/utils/utilityFunctions";
import LoaderComponent from "../../shared/loader/loader";

const getxsValue = (isfor: string, windowFlag: boolean) => {
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual My orders page function starts here */
export function MyOrdersPage() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
    profile_image:""
  });

  const [orderData, setOrderData] = useState([{}]);
  const [topicData, setTopicData] = useState([{ QID: "" }]);

  const [loader, setLoader] = useState(true);
  const [stage, setStage] = useState("default");

  useEffect(() => {
    validateLoggedIn();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  useEffect(() => {
    function fetchMyOrders() {
      setLoader(true);
      services.getOrders(profileData.userName).then((res) => {
        setLoader(false);
        console.log(res);
        if (res.failed) return;
        else {
          console.log(res.data.data);
          setOrderData(res.data.data);
        }
      });
    }
    if (profileData.userName) fetchMyOrders();
  }, [profileData.userName]);

  function moreDetails(one: any) {
    console.log(one);
    setTopicData(one);
    setStage("overallPerformance");
  }

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => setStage("default")}
    >
      Assess Me Report
    </Link>,
    <Typography key="3" color="text.primary">
      Overall Report
    </Typography>,
  ];
  return (
    <Grid container style={{ width: "100vw" }}>
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag)}>
        <Navbar path={"reports"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag)}>
        <TopBarComponent
          logo={false}
          search={false}
          profileData={profileData}
        />
        {stage === "overallPerformance" && (
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            style={{ marginTop: "28px", marginLeft: "25px" }}
          >
            {breadcrumbs}
          </Breadcrumbs>
        )}
        <Grid
          container
          spacing={2}
          item
          xs={11}
          className="reportsMainContainer"
          style={{ marginTop: "20px" }}
        >
         
              <Grid container item>
                <Grid container item className="hedderRow">
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Order ID
                    </span>
                  </Grid>
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Transaction For
                    </span>
                  </Grid>
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Transaction Date
                    </span>
                  </Grid>
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Amount (₹)
                    </span>
                  </Grid>
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Transaction Type
                    </span>
                  </Grid>
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Transaction Status
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              {orderData && orderData.length > 0 ? (
                orderData.map((one: any) => (
                  
                  <Grid container item key={one?.OrderID}>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one?.OrderID}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one?.TransactionFor}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {getDate(one?.TransactionDate)}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one?.Amount}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one?.TransactionType}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one?.TransactionStatus}
                      </span>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <div style={{ padding: "25px", fontSize: "30px" }}>
                  No Orders Found
                </div>
              )}
        
          
        </Grid>
      </Grid>
    </Grid>
  );
}
