import { ReactComponent as DoubtboxLogo } from "../../assets/logo/doubtbox_logo.svg";
import { ReactComponent as SearchLogo } from "../../assets/icons/search.svg";
import "./topBarComponent.scss";
import { ReactComponent as NotificationLogo } from "../../assets/icons/notification.svg";
import { ReactComponent as CartLogo } from "../../assets/icons/cart.svg";
import { ReactComponent as SwitchLearnerLogo } from "../../assets/icons/switchLearner.svg";
import { ReactComponent as LogoutLogo } from "../../assets/icons/logout.svg";
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Badge,
  ClickAwayListener,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import {
  ProfileSettingsSubmenu,
  PurchaseSettingsSubmenu,
  SettingsSubmenu,
} from "./topBaSubMenu";
import Add from "@mui/icons-material/Add";
import { NavIconProvider } from "../navIconComponents/navIconComponents";
import { FreeTrial } from "../navbarPromoComponents/navbarPromos";
import { base64ToDataURL, getFromLocalStorage, getInitials } from "../../shared/utils/utilityFunctions";

// /** Helps to Fetch window dimensions in real-time */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function TopBarComponent(props: {
  search: boolean;
  logo: boolean;
  profileData: any;
  searchData?: any;
  header?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [showLearners, setShowLearners] = useState(false);
  const [showProfileSubmenu, setShowProfileSubmenu] = useState(false);
  const [showPuchaseSubmenu, setShowPuchaseSubmenu] = useState(false);
  const [showSettingsSubmenu, setShowSettingsSubmenu] = useState(false);
  const [itemInCart, setItemInCart] = useState(false)
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [userData, setUserData] = useState({ profile: [] });
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const navBarHidden =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    validateLoggedIn();
    /** Fetches window dimensions in real-time */
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props.profileData]);

  const validateLoggedIn = () => {
    var val = localStorage.getItem("userData");
    var cartDetails = getFromLocalStorage("cartDetails");

    // console.log(JSON.parse(val))
    if (val !== undefined && val !== null)
      setUserData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
    if (cartDetails && cartDetails.length > 0)
      setItemInCart(true)
  };
  function navigateTo(path: string, props?: any) {
    // if(path === '/login')
    // call login api if available, then clear all cached data

    navigate(path, props);
  }
  function switchLearner(data: any) {
    localStorage.setItem("profileData", JSON.stringify(data));
    var path: any[] = window.location.href.split("/");
    console.log(path);
    if (path.includes("dashboard")) window.location.reload();
    else navigateTo("/dashboard");
  }

  function addKid() {
    // localStorage.clear();
    let state = { addKid: true }
    navigateTo("/signup", { state: state });
  }

  function logout() {
    localStorage.clear();
    // Remove all cookies
    const cookies = Cookies.get();
    for (const cookieName in cookies) {
      Cookies.set(cookieName, '', { expires: new Date(0) });
    }
    navigateTo("/login");
  }

  function toggleView(menu: string) {
    switch (menu) {
      case "profile":
        if (!showProfileSubmenu) setShowProfileSubmenu(true);
        else setShowProfileSubmenu(false);
        break;
      case "puchase":
        if (!showPuchaseSubmenu) setShowPuchaseSubmenu(true);
        else setShowPuchaseSubmenu(false);
        break;
      case "settings":
        if (!showSettingsSubmenu) setShowSettingsSubmenu(true);
        else setShowSettingsSubmenu(false);
        break;
      case "learners":
        if (!showLearners) setShowLearners(true);
        else setShowLearners(false);
        break;
    }
  }

  function startSearch() {
    console.log(search);
    let state = {
      search,
    };
    navigateTo("/search", { state });
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);

    setSearch(event.target.value);
  };
  return (
    <Grid container>
      {navBarHidden ? <DrawerComponent /> : null}
      {props.logo && !navBarHidden ? (
        <DoubtboxLogoContainer navigateTo={navigateTo} />
      ) : null}
      {props.search && !navBarHidden ? (
        <Grid container item xs={7} sm={9} lg={10}>
          {/* <div className="dashSearch">
            <SearchLogo className="icon" />
            <input
              type="search"
              placeholder="Search for courses"
              className="input"
              onChange={handleChange}
              onKeyDown={(e) => e.keyCode === 13 && startSearch()}
            ></input>
          </div>
          <Button
            variant="contained"
            className="searchButtton"
            onClick={startSearch}
            disabled={!search}
          >
            Go ➟
          </Button> */}
        </Grid>
      ) : (
        <Grid item xs={7} sm={9} lg={10}>{props.logo && <DoubtboxLogo className="floatingLogo" />}</Grid>
      )}
      {!navBarHidden ? (
        <>
          {/* <IconButton aria-label="Notifications" className="notificationLogo">
            <Badge color="error" variant="dot" invisible={false}>
              <NotificationLogo />
            </Badge>
          </IconButton> */}
          <IconButton aria-label="Notifications" className="notificationLogo" onClick={() => { navigateTo('/cart') }}>
            <Badge color="error" variant="dot" invisible={!itemInCart}>
              <ShoppingCartIcon />
            </Badge>
          </IconButton>

        </>
      ) : null}

      <div className="userProfleLogo">
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={props.profileData.profile_image}>
              {getInitials(props.profileData.name)}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => toggleView("profile")}>
            <ListItemIcon>
              <Avatar src={props.profileData.profile_image} />
            </ListItemIcon>
            <ListItemText primary="Profile Settings" />
            {showProfileSubmenu ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </MenuItem>
          <ProfileSettingsSubmenu
            show={showProfileSubmenu}
            navigateTo={navigateTo}
          />

          {navBarHidden ? (
            <MenuItem>
              <ListItemIcon>
                <Badge color="error" variant="dot" invisible={false}>
                  <NotificationLogo />
                </Badge>
              </ListItemIcon>
              Notifications
            </MenuItem>
          ) : null}

          {/* pofile submenu */}

          <MenuItem onClick={() => toggleView("puchase")}>
            <ListItemIcon>
              <CartLogo fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Purchase Settings" />
            {showPuchaseSubmenu ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </MenuItem>

          <PurchaseSettingsSubmenu
            show={showPuchaseSubmenu}
            navigateTo={navigateTo}
          />

          <MenuItem onClick={() => toggleView("settings")}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            {showSettingsSubmenu ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </MenuItem>
          <SettingsSubmenu show={showSettingsSubmenu} navigateTo={navigateTo} />

          {userData.profile.length > 1 && <MenuItem onClick={() => toggleView("learners")}>
            <ListItemIcon>
              <SwitchLearnerLogo fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Switch Learner" />
            {showLearners ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </MenuItem>}
          <Collapse in={showLearners} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {userData?.profile?.length > 1
                ? userData.profile.map(
                  (item: { name: string; standard: any }) => (
                    <MenuItem
                      onClick={() => switchLearner(item)}
                      key={item.name}
                    >
                      <Avatar /> {item.name}
                    </MenuItem>
                  )
                )
                : null}
              {/* <MenuItem onClick={() => addKid()}>
                <Avatar>
                  {" "}
                  <Add />
                </Avatar>
                Add Kid
              </MenuItem> */}
            </List>
          </Collapse>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutLogo fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          <Divider />
        </Menu>
      </div>
    </Grid>
  );
}

function DoubtboxLogoContainer(props: { navigateTo: any }) {
  return (
    <div className="floatingLogo">
      <DoubtboxLogo onClick={() => props.navigateTo("/dashboard")} />
    </div>
  );
}

type Anchor = "top" | "left" | "bottom" | "right";

export function DrawerComponent() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [modifiedNavBarItems, setModifiedNavBarItems] = useState(constants.NAVIGATION_ITEMS);
  // Hiding Worksheets for classess 11 and 12 Starts
  // Can comment this part to disable the logic
  const [profileData, setProfileData] = useState({
    standard: 0,
    syllabus: "",
    name: "",
    userName: "",
    hasTrialSession: false,
    profile_image: ""
  });


  useEffect(() => {
    if (!profileData.name) {
      var prof = localStorage.getItem("profileData");

      if (typeof prof !== "undefined" && prof !== null)
        setProfileData(JSON.parse(prof));
    }

  }, []);
  useEffect(() => {
    if (profileData.standard === 11 || profileData.standard === 12) {
      const itemToRemove = "Worksheets";
      // Filter out the object with display "Worksheets"
      let items = constants.NAVIGATION_ITEMS.filter((item) => item.display !== itemToRemove);
      setModifiedNavBarItems(items)
    }
  }, [profileData]);
  // Hiding Worksheets for classess 11 and 12 Ends

  const navigate = useNavigate();
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };
  const handleClickAway = (anchor: Anchor) => {
    console.log('cclickaway')
    toggleDrawer(anchor, true)
  }
  function navigateClassPlus() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("android") !== -1) {
      window.location.replace(constants.CLASSPLUSS_APP_URL)

    } else {
      window.location.replace(constants.LEARN_URL)
    }
  }
  const list = (anchor: Anchor, navigateTo: any) => (
    <ClickAwayListener onClickAway={() => handleClickAway(anchor)}>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"

      >
        <List>
          {/* {constants.NAVIGATION_ITEMS.map((data: any) => { */}
          {modifiedNavBarItems.map((data: any) => {
            return (
              <ListItem disablePadding onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}>
                <ListItemButton onClick={() => navigateTo(data.to)}>
                  <ListItemIcon>
                    <NavIconProvider item={data} window={true} selected={""} />
                    {/* <DashIcon /> */}
                  </ListItemIcon>
                  <ListItemText primary={data.display} />
                </ListItemButton>
              </ListItem>
            );
          })}
          <ListItem disablePadding onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <ListItemButton onClick={() => navigateClassPlus()}>
              <ListItemIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                  height="16"
                  viewBox="0 0 20 16"
                ><path fill="currentColor" d="M12 2.5a1.5 1.5 0 0 0-1.376.9l-.262.6H4.5v16h15V4h-5.862l-.262-.6A1.5 1.5 0 0 0 12 2.5ZM9.128 2A3.496 3.496 0 0 1 12 .5c1.19 0 2.24.594 2.872 1.5H21.5v20h-19V2h6.628Zm8.53 7.586l-7.072 7.07l-4.242-4.242L7.758 11l2.828 2.829l5.657-5.657l1.414 1.414Z" /></svg>
                {/* <NavIconProvider item={data} window={true} selected={""} /> */}
                {/* <DashIcon /> */}
              </ListItemIcon>
              <ListItemText primary={constants.ASSIGNMENTS} />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ marginTop: "50%" }} disablePadding onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, true)}>
            <FreeTrial windowFlag={false} />
          </ListItem>

        </List>
        <Divider />
      </Box>
    </ClickAwayListener>
  );
  function navigateTo(path: string) {
    navigate(path);
  }
  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <ListItemIcon>
              <MenuIcon
                fontSize="large"
                style={{ marginTop: "15px", marginLeft: "5px" }}
              />
            </ListItemIcon>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            variant="persistent"
            className="persistentDrawer"
          >
            <DoubtboxLogo
              className="dbLogo p-28"
              onClick={() => navigateTo("/dashboard")}
            />
            {list(anchor, navigateTo)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
