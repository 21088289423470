/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 * Dashboard component
 */
import React, { useEffect, useState } from "react";

import "./Dashboard.scss";
import { Grid } from "@mui/material";
import {
  RecommendedLiveTuitions,
  AssessmeLayoutCard,
  LearnOnLayout,
  FreeLiveTuitions,
  UpcommingSessions,
} from "../../components/uiComponents/uiComponents";
// import { BookFreeClass } from "../../components/banners/banners";
import Navbar from "../../shared/Navbar";
import { constants } from "../../constants/constants";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import { useNavigate } from "react-router-dom";
import { services } from "../../services/api_service";
// import { userData } from "../../constants/tempDatas";
import AlertService from "../../shared/alert/alertComponent";
import { messageUtil } from "../../shared/utils/messages";
import LoaderComponent from "../../shared/loader/loader";
import {
  setToLocalStorage,
  capitalizeFirstLetter,
  getFromLocalStorage,
} from "../../shared/utils/utilityFunctions";
import swal from "sweetalert";
import { TuitionRoomInterface } from "../../constants/interfaces";

/** For providing container xs values dynamically as screen size falls beyond a point */
const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

/** Helps to Fetch window dimensions in real-time */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Dashboard component starts here */
function Dashboard() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    name: "",
    syllabus: "",
    standard: "",
    userName: "",
    syllabusName: "",
    hasTrialSession: false,
    profile_image: "",
  });
  const [userData, setUserData] = useState({
    mobile: "",
    hasPass: false,
    hasPassString: "loading",
  });
  const [loader, setLoader] = useState(true);
  const [sessionData, setSessionData] = useState([]);
  const [upcommingSessions, setUpcommingSessions] = useState([]);

  const [subjectList, setSubjectList] = useState([]);
  const [assesmeList, setAssessmeList] = useState([]);

  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };
  useEffect(() => {
    function checkIsFromSession() {
      let from_session = getFromLocalStorage(constants.FROM_SESSION);
      console.log(from_session);
      if (from_session === true) {
        console.log("from session");
        setToLocalStorage(constants.FROM_SESSION, false);
        window.location.reload();
      }
    }
    function fetchUserData() {
      var usr = localStorage.getItem("userData");
      if (usr !== undefined && usr !== null) {
        let parsedUser: any = JSON.parse(usr);
        console.log(parsedUser);
        setUserData(JSON.parse(usr));
      }
    }
    checkIsFromSession();
    fetchUserData();
  }, []);
  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    fetchData();

    /** Fetches window dimensions in real-time */
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userData && userData.hasPassString === "false") {
      console.log("hey");
      swal({
        title: "Set a password!",
        text: "Save time by logging in using a password",
        icon: "info",
        buttons: {
          cancel: {
            text: "Not Now",
            visible: true,
            value: false,
          },
          confirm: {
            text: "Yes",
            visible: true,
            value: true,
          },
        },
      }).then((confirmed) => {
        let updatedUsrData = userData;
        updatedUsrData.hasPassString = "rejected";
        setToLocalStorage("userData", updatedUsrData);
        if (confirmed) {
          navigateTo("/settings/password");
        }
      });
    }
    console.log(userData.hasPassString, profileData.syllabus);
    //  promptSetPassword(true)
  }, [userData.hasPassString]);

  // useEffect for fetching dashboard
  useEffect(() => {
    console.log(profileData);
    if (profileData && profileData.syllabus) {
      setLoader(true);
      console.log(profileData);

      let apiDataDashboard = {
        syllabusId: profileData.syllabus,
        standardId: profileData.standard,
        user: profileData.userName,
      };

      let p1 = services.getStudentDashboard(apiDataDashboard);
      let p2 = services.getSubjects(profileData.syllabus, profileData.standard);
      let p3 = services.getUpcommingSessions(apiDataDashboard);
      // calls multiple apis simultaneously to reduce load times
      Promise.all([p1, p2, p3])
        .then(function ([
          responseOfDashboard,
          responseOfgetSubjects,
          responseOfCurrentSessions,
        ]) {
          setLoader(false);

          console.log(
            responseOfDashboard,
            responseOfgetSubjects,
            responseOfCurrentSessions
          );
          if (
            responseOfDashboard.data.hasOwnProperty("message") &&
            responseOfDashboard.data.message === "Sessions got successfull !"
          )
            setSessionData(responseOfDashboard.data.data.sessionData);
          setSubjectList(responseOfgetSubjects.data);
          services
            .getDashAssessMe(apiDataDashboard)
            .then((responseOfAssessme) => {
              if (
                responseOfAssessme.status &&
                responseOfAssessme.status === constants.SUCCESS_CODE
              ) {
                let temp = responseOfAssessme.data.data;
                temp.sort(function (a: any, b: any) {
                  return parseInt(a.chapter_no) - parseInt(b.chapter_no);
                });
                setAssessmeList(temp);
              }
            });
          if (responseOfCurrentSessions && responseOfCurrentSessions.data)
            setUpcommingSessions(responseOfCurrentSessions.data.data);
          else {
            console.log("No upcomming sessions");
          }
        })
        .catch(function (error) {
          // if there's an error, log it
          console.log(error);
          showAlert(
            "error",
            messageUtil.SOMETHING_WENT_WRONG + ". Err: " + error.message
          );
        });
    }
  }, [profileData.syllabus]);

  const fetchData = () => {
    try {
      var val = localStorage.getItem("profileData");
      console.log(val);
      if (val === undefined || val === "undefined") return;
      if (val !== undefined && val !== null) setProfileData(JSON.parse(val));
      else {
        showAlert("error", messageUtil.SESSION_EXPIRED);
        navigateTo("/login");
      }
    } catch (e) {
      localStorage.clear();
      navigateTo("/login");
    }
    //  localStorage.setItem("userData",JSON.stringify(userData))
    //   console.log(userData)
  };
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;

  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  function selectSubject(props: any) {
    console.log(props);
    let state = { sub: props.id, name: props.name };

    navigate("/learn-on", { state: state });
  }
  function navigateTo(path: string, state?: any) {
    navigate(path, { state });
  }
  // function selectAssessMe(prop: string) {
  //   console.log(prop);
  //   navigate("/assess-me");
  // }

  function selectAssessMe(
    subjectName: string,
    subject: string,
    textbook: string,
    textbookName: string,
    chapterId: string,
    name: string
  ) {
    setLoader(true);
    const selectedChapterArr: string[] = [];
    selectedChapterArr.push(name);
    localStorage.setItem(
      "selectedChapterArr",
      JSON.stringify(selectedChapterArr)
    );
    console.log(profileData);
    let apiData = {
      type: "PracticeTest",
      userName: profileData.userName,
      selection: {
        content_type: "Assessment",
        pgtype: 1,
        syllabus: profileData.syllabus,
        standard: profileData.standard,
        subject: subject,
        subjectName: subjectName, //value is in full
        textbook: textbook,
        textbookName: subjectName,
        chapter: chapterId,
        chapterName: name,
      },
    };
    console.log(apiData);
    services.getPracticeTest(apiData).then((res) => {
      setLoader(false);
      console.log(res);
      if (res.failed)
        return showAlert(
          "error",
          messageUtil.ASSESSME_FETCH_FAILED + " ERROR: " + res.message
        );
      else {
        if (res.message === "Get questions unsuccessfull !") {
          return swal({
            title: "No questions found",
            icon: "error",
            timer: 3000,
          });
        }
        let packageId = res.data.package_id;
        setToLocalStorage("packageId", packageId);
        let allQuestions = res.data.questions;
        for (let one in allQuestions) {
          let oneData: any;
          oneData = allQuestions[one];
          oneData.answersDone = {};
          oneData.marked = false;
        }
        console.log(allQuestions);
        setToLocalStorage("practiceQuestions", allQuestions);
        // setToLocalStorage("practiceQuestions",res.data.questions )
        navigateTo("/assess-me", "stage3");
        // setStage("stage3");
      }
    });
  }
  function joinNow(props: any) {
    try {
      let apiData = {
        mobile: userData.mobile,
        userName: profileData.userName,
      };
      if (profileData.hasTrialSession) {
        services.setFreetrialFLag(apiData);
      }

      console.log(props);

      services
        .getCourseById(
          props.mst_session_course_id,
          profileData.userName,
          profileData.name
        )
        .then((res) => {
          console.log(res);
          if (res.failed) console.error(res);
          else {
            console.log(res.data);
            const text = res.data.data.course_description;
            const pattern = /(meet\.google\.com\/[a-zA-Z0-9\-]+)/g;

            const matches = text.match(pattern);
            console.log(matches);
            if (matches) {
              const meetLink = "https://" + matches[0]; // Assuming the first match is the link you want to redirect to
              window.location.href = meetLink;
            } else {
              swal({
                title: "Oops! something went wrong",
                text: "Could'nt join the the session! Session does not have a valid joining link",
                icon: "error",
              });
            }
          }
        });
      // var TuitionRoom: TuitionRoomInterface = {
      //   ActivityID: props.itemId,
      //   ActivityType: "TuitionRoom",
      //   User: profileData.userName,
      //   TimeConsumed: "50",
      //   UserJoined: "0",
      //   syllabus: profileData.syllabus,
      //   syllabusName: profileData.syllabusName,
      //   standard: profileData.standard,
      //   standardName: "Class " + profileData.standard,
      //   Subject: props.mst_session_subject_id,
      //   SubjectName: props.subject_name,
      //   TextBook: props.mst_session_textbook_id,
      //   TextbookName: props.textbook_name,
      //   Chapter: props.mst_session_chapter_id,
      //   ChapterName: props.chapter_name,
      //   Teacher: props.teacher_id,
      //   TeacherName: props.teacher_name,
      //   startTime: props.SessionStartTime,
      //   SessionStatus: "Creating",
      //   SessionType: "Tuition",
      //   teacherVideoURL: "null",
      // };
      // console.log(TuitionRoom);
      // setToLocalStorage(constants.TUITION_ROOM, TuitionRoom);
      // console.log("Setting required data to localstroage...");
      // navigateTo("/live-tuition-room");
    } catch (e) {
      console.log("Failed to Join");
      console.log("error: " + e);
    }
  }
  function setFreetrialFLag(childProps: any) {
    let apiData = {
      mobile: userData.mobile,
      userName: profileData.userName,
    };
    services.setFreetrialFLag(apiData).then((res: any) => {
      if (res.failed) {
        swal({
          title: "Oops! something went wrong",
          text: "Could'nt join the the session",
          icon: "info",
        });
      } else {
        joinNow(childProps);
      }
    });
  }
  return loader ? (
    <LoaderComponent />
  ) : (
    <>
      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />

      <Grid container style={{ width: "99vw" }} className="fade-in-animate">
        <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
          <Navbar path={"dashboard"} />
        </Grid>
        <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
          <TopBarComponent
            search={true}
            logo={false}
            profileData={profileData}
          />
          <Grid container className="dashLayout">
            <Grid container style={{ maxHeight: "80vh" }}>
              <Grid className="layout1 hideScrollBar" item xs={12} md={8}>
                <div className="nameContainer" style={{ marginBottom: "55px" }}>
                  <span className="nameContainer nameSpan">
                    Hello, {capitalizeFirstLetter(profileData.name)} !
                  </span>
                  <br />
                  <span className="nameContainer captchaSpan">
                    Explore your lessons!
                  </span>
                </div>
                {/* Book free class banner shown for students for the 1st time they login to the app.
                    after they attend the free class the banner is hidden */}
                {/* <BookFreeClass /> */}

                {/* For New Users Free live tuition sessions are shown*/}
                {/* {profileData.hasTrialSession && (
                    <FreeLiveTuitions
                      sessionData={sessionData}
                      joinNow={joinNow}
                      setFreetrialFLag={setFreetrialFLag}
                    />
                  )} */}
                {upcommingSessions && upcommingSessions.length > 0 && (
                  <UpcommingSessions
                    sessionData={upcommingSessions}
                    // sessionData={sessionData}
                    joinNow={joinNow}
                  />
                )}
                {/* Shows the relevant courses/sessions for students should pass value as array*/}
                <RecommendedLiveTuitions
                  sessionData={sessionData}
                  joinNow={joinNow}
                />

                {/* <iframe title="none" width="768" height="998" src="https://subscriptions.zoho.in/subscribe/cf502c4ede6d44b7ac1e3c22f6b89b300696566f3244a427dbde1ccf1c7a189d/T1" style={{border:"none"}}></iframe> */}
                <LearnOnLayout
                  subjectList={subjectList}
                  selectSubject={selectSubject}
                />
              </Grid>
              <Grid className="layout2" item xs={12} md={4}>
                <Grid className="layout2Container hideScrollBar">
                  <div className="nameContainer">
                    <span className="nameContainer assessmeNameSpan">
                      Practice more! Score high!
                    </span>
                    <br />
                    <span className="nameContainer assessmeCaptchaSpan">
                      Scroll down and take your test.
                    </span>
                  </div>
                  <AssessmeLayoutCard
                    selectAssessMe={selectAssessMe}
                    assesmeList={assesmeList}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default Dashboard;
