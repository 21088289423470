import { Avatar, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import LoaderComponent from "../../../shared/loader/loader";
import { ReactComponent as FabRightArrow } from "../../../assets/icons/fabRightArrow.svg";
import { messageUtil } from "../../../shared/utils/messages";
import { ConfirmClassUpgrade, OtpValidate } from "../../../components/uiComponents/modals";
import { services } from "../../../services/api_service";
import swal from "sweetalert";
import { constants } from "../../../constants/constants";

export function ClassUpgradePage() {
  const navigate = useNavigate();

  const classIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [profileData, setProfileData] = useState({
    name: "",
    standard: "",
    altMobileNo: "",
    city: "",
    syllabus: "",
    altEmailId: "",
    syllabusName: "",
    userName: "",
    className: "",
    altMobileCode:""
  });

  const [userData, setUserData] = useState({
    email: "",
    mobile: "",
    mobileCountryCode:""
  });
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);

  const [otpToggle, setOtpToggle] = useState(true);

  useEffect(() => {
    validateLoggedIn();
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");
    if (typeof val !== "undefined" && val !== null) {
      var userData = localStorage.getItem("userData");
      setProfileData(JSON.parse(val));
      if (typeof userData !== "undefined" && userData !== null)
        setUserData(JSON.parse(userData));
    } else {
      navigateTo("/login");
    }
  };

  function navigateTo(path: string) {
    navigate(path);
  }
  async function upgradeClass() {
    await services
    .generateLoginOtp({
      country: userData.mobileCountryCode,
      mobile: userData.mobile,
    }).then((res)=>{
      handleOpen();

    })
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOnChange = (res: string) => {
    setOtp(res);
  };
  useEffect(() => {
    if(otp && otp.length === constants.AUTH_CODE_LENGTH){
      console.log('submit')
      var apiData = {
        mobile: userData.mobile,
        otp,
        partner: constants.PARTNERS.DOUBTBOX,
      };
      services
        .verifyLoginOtp(apiData)
        .then((data) => {
          // props.setLoader(false);
          console.log(data);
          // if (data.failed) return props.showAlert("error", data.message);

          let resultData = data.data;
          // console.log(resultData);
          // console.log(resultData.data.profile[0].syllabus);
          if (
            resultData &&
            resultData.message === constants.LOGIN_SUC_FROM_SERVER
          ) {
            upgradeConfirmed()
          }else{
            setOtpErr(true)
          }
        })
    }
  }, [otp]);
function upgradeConfirmed(){
  handleClose()
  setLoader(true)

  var apiData = {
    mobile: userData.mobile,
    altMobileCode:profileData.altMobileCode,
    altMobileNo: profileData.altMobileNo,
    userName: profileData.userName,
    name: profileData.name,
    altEmailId: profileData.altEmailId,
    city: profileData.city,
    standard : profileData.standard+1,
    className:`Class ${profileData.standard+1}`,
    syllabus: profileData.syllabus,
    syllabusName:profileData.syllabusName,
    programtype: 1,
    programtype_t: "school",  
    updatedOn: new Date(),
    finalExam:"",
  };
  console.log(apiData);
  services.updateProfile(apiData).then((res) => {
    
    if (res.failed) {
      console.log(res.message);
      setLoader(false)
    } else {
      console.log(res);

      services.getLearnerProfile(userData.mobile).then((res) => {
      setLoader(false)
        console.log(res);
        if (res.failed) {
          console.log(res, "failed");
        } else {
          setUserData(res.data.data);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          let profileArr = res.data.data.profile;
          for (let p = 0; p < profileArr.length; p++) {
            let one = profileArr[p];
            if (one.userName === profileData.userName) {
              setProfileData(one);
              localStorage.setItem("profileData", JSON.stringify(one));
            }
          }
          swal({
            title: "Congratulations",
            text : messageUtil.CLASS_UPDATE_SUCCESS,
            icon: "success",
            timer:3000

          });
          navigateTo("/dashboard");
          
        }
      });
    }
  });
}
  return (
    <div className="SettingsProfileContainer fade-in-animate">
      {loader && <LoaderComponent />}
      <TopBarComponent search={false} logo={true} profileData={profileData} />
      {/* <div>{"<"} Profile</div>
      <div className="settingsContainer bgRed">
        <div className="subSettingsContainer">My profile</div>
      </div> */}
      <div className="backToSettings" onClick={() => navigateTo("/dashboard")}>
        <FabRightArrow className="invertFavArrow" />
        <span className="backToSettingslabel">Dashboard</span>
      </div>
      <div className="settingsContainer subContainer pd-t-50 hideScrollBar">
        <Grid
          className="subSettingsContainer"
          container
          item
          xs={12}
          sm={8}
          md={4}
        >
          <div>
            <span className="settingMenuContainerHeader">Class Upgrade</span>
            <br />
            <span className="listLabel">
              Hi {profileData.name}! Enhance your educational journey by
              upgrading to class {profileData.standard + 1}.
            </span>
          </div>
          {/* <div className="settingsCardContaianer subSettingsOptionsContainer"> */}
          <Grid
            className="settingsCardContaianer subSettingsOptionsContainer"
            container
            item
            xs={12}
            sm={12}
            md={12}
          >
            <Avatar sx={{ width: 90, height: 90, marginTop: 5 }}>H</Avatar>
            <span className="profileName">{profileData.name}</span>
            <span className="profileStandard">
              Class &nbsp;{profileData.standard}
            </span>
            <Grid item container>
              <Grid
                container
                className="userBasicInfoContainer mt-28"
                item
                xs={12}
              >
                <Grid item xs={6}>
                  <div>
                    <span className="listLabel">Email</span>

                    <br />
                    <span className="settingMenuContainerHeader">
                      {profileData.altEmailId}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <span className="listLabel">City</span>

                    <br />
                    <span className="settingMenuContainerHeader">
                      {profileData.city ? profileData.city : "Nil"}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "25px" }}>
                  <div>
                    <span className="listLabel">Mobile Number</span>

                    <br />
                    <span className="settingMenuContainerHeader">
                      {userData.mobile}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "25px" }}>
                  <div>
                    <span className="listLabel">Alternative Number</span>

                    <br />
                    <span className="settingMenuContainerHeader">
                      {profileData.altMobileNo}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sm={12}
            className="settingsCardContaianer subSettingsOptionsContainer"
          >
            <div className="classUpgradeFooter" style={{ padding: 25 }}>
              <Grid xs={12}>
                <span className="settingMenuContainerHeader">
                  Class Upgrade
                </span>
              </Grid>
              <Grid xs={12}>
                <p className="listLabel a">
                  Level up your learning experience with our class upgrading
                  option.
                  <br /> Upgrade now and take your learning experience to the
                  next level!
                </p>
                <p className="listLabel a">
                  <span style={{ fontWeight: "bold" }}>Note-</span> Upgrading
                  class is an irriversible process.
                </p>
              </Grid>
              {classIds.includes(parseInt(profileData.standard)) ? (
                <Grid xs={12}>
                  <Button
                    variant="text"
                    className="settingsCancelButton"
                    onClick={() => navigateTo("/settings")}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    className="settingsSaveButton"
                    onClick={upgradeClass}
                  >
                    UPGRADE TO CLASS {profileData.standard + 1}
                  </Button>
                </Grid>
              ) : (
                <Grid style={{ color: "red" }}>
                  Your class does not support upgrading! Please reach us at this
                  number if you have any concerns
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </div>

      {/* <ConfirmClassUpgrade
        setOpen={setOpen}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        classId={profileData.standard}
        submit={upgradeConfirmed}
      /> */}

      <OtpValidate  
        setOpen={setOpen}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        classId={profileData.standard}
        otpErr={otpErr}
        // submit={upgradeConfirmed}
        handleOnChange={handleOnChange}
        />
    </div>
  );
}
