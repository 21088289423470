/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 */
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { NavIconProvider } from "../components/navIconComponents/navIconComponents";
import { FreeTrial } from "../components/navbarPromoComponents/navbarPromos";
import { constants } from "../constants/constants";
import { ReactComponent as DoubtboxLogo } from "../assets/logo/doubtbox_logo.svg";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Navbar = (props: { path: string }) => {
  const [modifiedNavBarItems, setModifiedNavBarItems] = useState(
    constants.NAVIGATION_ITEMS
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const link = props.path;
  const navigate = useNavigate();

  // Hiding Worksheets for classess 11 and 12 Starts
  // Can comment this part to disable the logic
  const [profileData, setProfileData] = useState({
    standard: 0,
    syllabus: "",
    name: "",
    userName: "",
    hasTrialSession: false,
  });

  useEffect(() => {
    if (!profileData.name) {
      var prof = localStorage.getItem("profileData");

      if (typeof prof !== "undefined" && prof !== null)
        setProfileData(JSON.parse(prof));
    }
  }, []);
  useEffect(() => {
    if (profileData.standard === 11 || profileData.standard === 12) {
      const itemToRemove = "Worksheets";
      // Filter out the object with display "Worksheets"
      let items = constants.NAVIGATION_ITEMS.filter(
        (item) => item.display !== itemToRemove
      );
      setModifiedNavBarItems(items);
    }
  }, [profileData]);
  // Hiding Worksheets for classess 11 and 12 Ends

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // hides the navbar when screen size is smaller than defined breakpoints
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? true : false;

  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  let openClose = () => {
    // if (window === false) {
    //   setWindow(true);
    // } else {
    //   setWindow(false);
    // }
    console.log("open close disabled");
  };
  let item = {
    display: "Assignment",
    icon: "reports",
    to: "/reports",
    section: "started",
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  if (hideNavBar === false) {
    return (
      <nav
        className="navbar-menu"
        style={{ width: windowFlag === false ? 207 : 60 }}
      >
        <div className="burger" onClick={() => openClose()}>
          {!windowFlag ? (
            <DoubtboxLogo
              className="dbLogo"
              height={50}
              onClick={() => navigateTo("/dashboard")}
            />
          ) : (
            <DoubtboxLogo onClick={() => navigateTo("/dashboard")} />
          )}
        </div>
        <ul className="navbar__list">
          {modifiedNavBarItems.map((item, i) => (
            <Link style={{ textDecoration: "none" }} to={item.to} key={i}>
              <div className="navbar__li-box" key={i}>
                <NavIconProvider
                  item={item}
                  window={windowFlag}
                  selected={link}
                />
                <li
                  className="navbar__li"
                  style={{
                    display: windowFlag === false ? "inline-block" : "none",
                  }}
                >
                  {/* {item[0]} */}
                </li>
              </div>
            </Link>
          ))}
          <div
            className="navbar__li-box navbar__list"
            style={{ marginLeft: "0.5px", marginTop: "20px" }}
          >
            <AssignmentMenu item={item} window={windowFlag} selected={link} />
          </div>
        </ul>

        <FreeTrial windowFlag={windowFlag} />
        {/* <Upgrade  windowFlag={windowFlag}/> */}
      </nav>
    );
  } else {
    return null;
  }
};

export default Navbar;

function AssignmentMenu(props: any) {
  function navigateTo() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("android") !== -1) {
      window.location.replace(constants.CLASSPLUSS_APP_URL);
    } else {
      window.location.replace(constants.LEARN_URL);
    }
  }
  return (
    <div className="iconDisabled" onClick={navigateTo}>
      {/* <AssignmentTurnedInIcon style={{  width:"20px",
        height:"24px"}}/> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg"  width="20"
            height="16"
            viewBox="0 0 20 16"
            ><path fill="currentColor" d="M12 2.5a1.5 1.5 0 0 0-1.376.9l-.262.6H4.5v16h15V4h-5.862l-.262-.6A1.5 1.5 0 0 0 12 2.5ZM9.128 2A3.496 3.496 0 0 1 12 .5c1.19 0 2.24.594 2.872 1.5H21.5v20h-19V2h6.628Zm8.53 7.586l-7.072 7.07l-4.242-4.242L7.758 11l2.828 2.829l5.657-5.657l1.414 1.414Z" /></svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        width="15"
        height="15"
      >
        <path d="M9.828,20H4V14.172l.586-.586L18.172,0H3A3,3,0,0,0,0,3V24H17V17h7V5.828Z" />
        <polygon points="19 23.414 23.414 19 19 19 19 23.414" />
        <path d="M6,18H9L23.379,3.621a2.121,2.121,0,0,0-3-3L6,15Z" />
      </svg>

      {!props.window ? (
        <span className="nameSpan" style={{ paddingLeft: 6 }}>
          {props.item.display}
        </span>
      ) : null}
    </div>
  );
}
