/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Assess me overall reports page.
 */
import { Grid, styled } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import { constants } from "../../../constants/constants";
import LoaderComponent from "../../../shared/loader/loader";
import Navbar from "../../../shared/Navbar";
import {
  getDate,
  getFromLocalStorage,
} from "../../../shared/utils/utilityFunctions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { GridColDef } from "@mui/x-data-grid";
import ResponsiveList from "../../../shared/responsiveList/responsiveListComponent";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} size="116px" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={30}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#0E2744" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  width: "100%",
  textAlign: "left",
  color: theme.palette.mode === "dark" ? "#fff" : "#3E5269",
  border: "2px solid #D8DCE1",
  margin: "10px",
  borderRadius: "8px",
}));

const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function AssessMeOverallReportPage() {
  const navigate = useNavigate();
  const reportData = getFromLocalStorage("assessMeResult");
  const summary = reportData.Summary ? reportData.Summary : {};
  const topicReport = reportData.TopicReport ? reportData.TopicReport : [];
  const result = reportData.Result ? reportData.Result : [];
  const subject = getFromLocalStorage("selectedSubject");
  const chapter = getFromLocalStorage("selectedChapterArr");
  console.log(summary, topicReport, result);
  const [profileData, setProfileData] = useState({
    class: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
  });
  const [loader, setLoader] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
      if (reportData.length === 0) navigateTo("/dashboard");
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  console.log(reportData);
  function backToReports() {
    navigateTo("/reports/assess-me-report");
  }

  // const columns: GridColDef[] = [
  //   { field: 'id', headerName: 'No', width: 90 },
  //   {
  //     field: 'TopicName',
  //     headerName: 'Topics ',
  //     width: 150,
  //   },
  //   {
  //     field: 'Total',
  //     type: 'number',
  //     headerName: 'Questions',
  //     width: 120,
  //   },
  //   {
  //     field: 'RightAnswer',
  //     headerName: 'Correct',
  //     type: 'number',
  //     width: 390,
  //   }, {
  //     field: 'WrongAnswer',
  //     headerName: 'Incorrect',
  //     type: 'number',
  //     width: 190,
  //   }, {
  //     field: 'Skipped',
  //     headerName: 'Not Attempted',
  //     type: 'number',
  //     width: 210,
  //   }, {
  //     field: 'Percentage',
  //     headerName: 'Percentage',
  //     type: 'number',
  //     width: 210,
  //   },
  // ];
  console.log(topicReport)

  return (
    <Grid container>
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"assess-me"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <TopBarComponent
          logo={false}
          search={false}
          profileData={profileData}
        />
        <span
          className="nextButton"
          style={{ padding: "11px", marginLeft: "25px" }}
          onClick={() => backToReports()}
        >
          Back to Reports
        </span>
        <Grid
          container
          spacing={2}
          item
          xs={11}
          // className="reportsMainContainer"
          style={{ marginTop: "20px" }}
        >
           {/* {topicReport && <ResponsiveList rows={topicReport} columns={columns}/>} */}
          <Grid container item>
            <Grid container item xs={6.5}>
              <span
                style={{
                  color: "#0E2744",
                  opacity: 0.8,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Topics
              </span>
            </Grid>
            <Grid container item xs={1}>
              <span
                style={{
                  color: "#0E2744",
                  opacity: 0.8,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Questions
              </span>
            </Grid>
            <Grid container item xs={1}>
              <span
                style={{
                  color: "#0E2744",
                  opacity: 0.8,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Correct
              </span>
            </Grid>
            <Grid container item xs={1}>
              <span
                style={{
                  color: "#0E2744",
                  opacity: 0.8,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Incorrect
              </span>
            </Grid>
            <Grid container item xs={1.5}>
              <span
                style={{
                  color: "#0E2744",
                  opacity: 0.8,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Not Attempted
              </span>
            </Grid>
            <Grid container item xs={1}>
              <span
                style={{
                  color: "#0E2744",
                  opacity: 0.8,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Percentage
              </span>
            </Grid>
          </Grid>
          {topicReport.map((one: any) => (
            <Grid container item>
              <Grid container item xs={6.5}>
                {one.TopicName}
              </Grid>
              <Grid container item xs={1}>
                {one.Total ? one.Total : 0}
              </Grid>
              <Grid container item xs={1}>
                {one.RightAnswer ? one.RightAnswer : 0}
              </Grid>
              <Grid container item xs={1}>
                {one.WrongAnswer ? one.WrongAnswer : 0}
              </Grid>
              <Grid container item xs={1.5}>
                {one.skipped ? one.skipped : 0}
              </Grid>
              <Grid container item xs={1}>
                {one.Percentage ? one.Percentage : 0}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
