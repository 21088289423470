/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Cart page.
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Button, Grid, Typography } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import "./CartPage.scss";
import { services } from "../../services/api_service";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import LoaderComponent from "../../shared/loader/loader";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../shared/utils/utilityFunctions";
import swal from "sweetalert";
import cartEmpty from "../../assets/icons/cartEmpty.png";
import { userData } from "../../constants/tempDatas";

const getxsValue = (isfor: string, windowFlag: boolean) => {
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Search page function starts here */
export function CartPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stateProps, setTree] = useState(location.state);
  console.log(stateProps);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
    profile_image: "",
    name: "",
    zohoCustomerId: "",
  });

  const [loader, setLoader] = useState(false);
  const [purchaseUrl, setPurchaseUrl] = useState("");
  const [showPaymentOption, setShowPaymentOption] = useState(false);

  useEffect(() => {
    validateLoggedIn();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: any, state?: any) {
    navigate(path, { state: state });
  }

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;

  const [cartDetails, setCartDetails] = React.useState(
    getFromLocalStorage("cartDetails")
  );
  console.log(cartDetails);
  useEffect(() => {
    function fetchReport() {}
    if (profileData.userName) fetchReport();
  }, [profileData.userName, cartDetails]);

  function removeFromCart(course: any) {
    let tempCart = [];
    for (let i = 0; i < cartDetails.length; i++) {
      let elem = cartDetails[i];
      if (elem.content_uuid !== course.content_uuid) {
        tempCart.push(elem);
      }
    }
    setCartDetails(tempCart);
    setToLocalStorage("cartDetails", tempCart);
  }

  function startPurchase(prop: any) {
    console.log(cartDetails);
    let crseIds = "";
    // for(let i=0; i<cartDetails.length;i++){
    // let elem = cartDetails[i]

    // }
    const crseIdsArr = cartDetails.map(
      (obj: { course_id: any }) => obj.course_id
    );
    crseIds = crseIdsArr.join(",");
    console.log(crseIds);
    function fetchTotal() {
      let total = 0;
      for (let i = 0; i < cartDetails.length; i++) {
        let one = cartDetails[i];
        total = total + parseInt(one.price);
      }
      return total;
    }
    let apiData = {
      zohoCustomerId: profileData.zohoCustomerId,
      courseId: crseIds,
      batchId: cartDetails[0].batchDetails.BatchId,
      price: fetchTotal(),
      subscriptionType:
        prop === constants.MONTHLY ? constants.MONTHLY : constants.YEARLY,
      user: profileData.userName,
      courseName: cartDetails[0].course_name,
      mobile: userData.mobile,
      userName: profileData.name,
    };
    console.log(apiData);
    services.getPaymentUrl(apiData).then((res) => {
      setLoader(false);
      if (res.failed) {
        swal("error", res.message);
      } else {
        console.log(res.data.data.hostedpage.url);
        setPurchaseUrl(res.data.data.hostedpage.url);
      }
      setShowPaymentOption(true);
    });
  }
  return (
    <Grid container style={{ width: "100vw" }}>
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag)}>
        <Navbar path={"search"} />
      </Grid>

      <Grid item xs={getxsValue("outlet", windowFlag)}>
        <TopBarComponent
          logo={false}
          search={true}
          profileData={profileData}
          header={"Cart"}
        />
        {cartDetails.length > 0 ? (
          <Grid
            container
            spacing={2}
            item
            xs={12}
            className=""
            style={{
              marginTop: "20px",
              minHeight: "85vh",
              backgroundColor: "#EBECEE",
            }}
          >
            <Grid container item xs={12} md={8}>
              {cartDetails.length > 0
                ? cartDetails?.map((props: any) => (
                    <CourseListComponent
                      data={props}
                      removeFromCart={removeFromCart}
                    />
                  ))
                : "No Courses in your cart"}
            </Grid>
            <Grid container item xs={8} md={4} lg={3.5}>
              <CartValueComponent
                cartDetails={cartDetails}
                startPurchase={startPurchase}
                purchaseUrl={purchaseUrl}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} className="displayCenter">
            <img src={cartEmpty} alt="logo" style={{ height: "50vh" }} />
            <Grid xs={12} className="displayCenter">
              <h2 className="m-t0">Your cart is empty!</h2>
            </Grid>
            <Grid xs={12} className="displayCenter">
              <h3 className="m-t0">Go for shopping!</h3>
            </Grid>
            <Grid xs={12} className="displayCenter">
              <div
                className="removeFromCart buynow"
                onClick={() => navigateTo("/dashboard")}
              >
                Dashboard
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function CourseListComponent(headProps: any) {
  // let titleArr = props.title?.split("~");
  // let modTitle = props.title;
  // if (titleArr && titleArr.length > 1) {
  //   modTitle = titleArr[0];
  // }
  let props = headProps.data;
  console.log(props);
  const teacherListWithCommas = props.teacherArray.map(
    (name: string, index: number) => {
      return index === props.teacherArray.length - 1 ? name : name + ", ";
    }
  );
  const [isRemoved, setIsRemoved] = useState(false);
  // Function to handle the removal of the component
  function handleRemove() {
    setIsRemoved(true); // Set the state to trigger the animation
    setTimeout(() => {
      headProps.removeFromCart(props); // Remove the component from the parent component's state
    }, 500); // Wait for the animation duration before actually removing the component
  }

  // Join the elements of the array into a single string
  const teacherListString = teacherListWithCommas.join("");
  function askConfirmation() {
    swal({
      title: "Remove from cart?",
      text: `Sure to remove course ${props.course_name} from cart?`,
      icon: "info",
      buttons: {
        cancel: {
          text: "No",
          visible: true,
          value: false,
        },
        confirm: {
          text: "Yes",
          visible: true,
          value: true,
        },
      },
    }).then((confirmed) => {
      if (confirmed) {
        console.log("confirm remove");
        handleRemove();
      } else {
        console.log("no");
      }
    });
  }
  return (
    <Grid
      className={`recommendedCard m-14 hideSrollBar ${
        isRemoved ? "slide-out" : ""
      }`}
      container
      item
      xs={12}
    >
      <Grid container item xs={12} className="recContents">
        <Grid container xs={12}>
          <Grid container item xs={4}>
            <div>
              <div className="courseDetailsLabel">Course Days</div>
              <div className="courseDetailsValue">
                {props.batchDetails.MstDaysName?props.batchDetails.MstDaysName.replace(",", ", "):""}
              </div>
            </div>
          </Grid>

          {props.subject_name && (
            <Grid container item xs={6.5}>
              <div className="subjectLabelInCart">{props.subject_name}</div>
            </Grid>
          )}
          <Grid container item xs={1.5}>
            <div className="removeFromCart m-t0" onClick={askConfirmation}>
              Remove ❌
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          xs={10}
          className="courseName hideScrollbar"
          style={{ paddingTop: "0px" }}
        >
          {props.course_name}
        </Grid>
        <Grid
          container
          xs={2}
          className="courseName hideScrollbar"
          style={{ paddingTop: "0px" }}
        >
          ₹ {props.price}
          {/* <div className="removeFromCart" onClick={askConfirmation}>Remove ❌</div> */}
        </Grid>
        <Grid container>
          <Grid container item xs={7.5}>
            <div>
              <div className="courseDetailsLabel">Instructor</div>
              <div
                className="courseDetailsValue"
                style={{ cursor: "pointer" }}
                onClick={props.handleOpenTeacher}
              >
                {teacherListString}
              </div>
            </div>
          </Grid>
          <Grid container item xs={2.5}>
            <div>
              <div className="courseDetailsLabel">Date From</div>
              <div className="courseDetailsValue">
                {props.batchDetails.start_date} &nbsp; - &nbsp;
              </div>
            </div>

            <div>
              <div className="courseDetailsLabel">Date To</div>
              <div className="courseDetailsValue">
                {props.batchDetails.end_date}
              </div>
            </div>
          </Grid>
          <Grid container item xs={2}>
            <div>
              <div className="courseDetailsLabel">Time</div>
              <div className="courseDetailsValue">
                {props.batchDetails.FromTime} - {props.batchDetails.ToTime}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function CartValueComponent(props: any) {
  console.log(props.cartDetails);
  const [totalAmount, setTotal] = React.useState(0);

  useEffect(() => {
    let total = 0;
    function fetchTotal() {
      for (let i = 0; i < props.cartDetails.length; i++) {
        let one = props.cartDetails[i];
        total = total + parseInt(one.price);
      }
      setTotal(total);
    }

    if (props.cartDetails.length > 0) fetchTotal();
    else setTotal(0);
  }, [props.cartDetails]);

  return (
    <Grid className="cart-card  m-14" spacing={2}>
      {props.showPaymentOption ? (
   
        <iframe
          title="none"
          width="100%"
          height="100%"
          src={props.purchaseUrl}
          style={{ border: "none" }}
        ></iframe>

      
      ) : (
        <>
        <h3>Order Summary</h3>
        {props.cartDetails.length > 0 ? (
          <p>You have {props.cartDetails.length} Items in the cart</p>
        ) : (
          <p>You have no Items in the cart</p>
        )}
        <Grid container item xs={12}>
          {props.cartDetails.map((one: any, index: number) => {
            return (
              <Grid>
                <div className="cartname">
                  {index + 1}. {one.course_name}
                </div>
                <div className="cartPrice">₹ {one.price}</div>
              </Grid>
            );
          })}
        </Grid>
        <Grid className="seperator"></Grid>
        <Grid
          container
          item
          xs={12}
          className="cartAmountContainer displayCenter"
        >
          <Grid container item xs={12}>
            <Grid item container>
              <h3> Buy for 1 year</h3>
              &nbsp;
              <span className="buyPrice displayCenter">₹{totalAmount}</span>
            </Grid>

            <div
              className="removeFromCart buynow"
              onClick={() => props.startPurchase(constants.YEARLY)}
            >
              Buy Now
            </div>
          </Grid>
          <Grid container item xs={12}>
            <Grid item container>
              <h3> Subscribe for</h3>
              &nbsp;
              <span className="buyPrice displayCenter">
                ₹{totalAmount / constants.ACADEMIC_DURATION}/Month
              </span>
            </Grid>

            <div
              className="removeFromCart buynow"
              onClick={() => props.startPurchase(constants.MONTHLY)}
            >
              Subscribe
            </div>
          </Grid>
        </Grid>
      </>
      )}
    </Grid>
    //   :    <Grid className="cart-card  m-14" spacing={2}>
    //   <iframe
    //     title="none"
    //     width="100%"
    //     height="100%"
    //     src={props.purchaseUrl}
    //     style={{ border: "none" }}
    //   ></iframe>
    // </Grid>
  );
}

// props.showPaymentOption
