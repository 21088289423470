/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Login page.
 */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, IconButton } from "@mui/material";
import "../signup/Signup.scss";
import "./Login.scss";
import Divider from "@mui/material/Divider";
import LoaderComponent from "../../shared/loader/loader";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import { COUNTRY_CODES_LIST } from "../../constants/countryCodes";
import { ReactComponent as DoubtboxLogo } from "../../assets/logo/doubtbox_logo.svg";
import AuthCode from "react-auth-code-input";

import { constants } from "../../constants/constants";
import { services } from "../../services/api_service";
import { Md5 } from "ts-md5";
import { messageUtil } from "../../shared/utils/messages";
import AlertService from "../../shared/alert/alertComponent";
import {
  formatSecTommss,
  getFromLocalStorage,
} from "../../shared/utils/utilityFunctions";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import swal from "sweetalert";
interface State {
  country: string;
  password: string;
  class: string;
  phone: string;
  name: string;
}
interface StateErrors {
  password: boolean;
  class: boolean;
  phone: boolean;
  name: boolean;
}

export function LoginPage() {
  const [stage, setStage] = React.useState("Get OTP");
  const [otpPwdToggle, setToggle] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const [values, setValues] = React.useState<State>({
    country: "+91",
    password: "",
    class: "",
    phone: "",
    name: "",
  });
  const [errors, setErrors] = React.useState<StateErrors>({
    password: false,
    class: false,
    phone: false,
    name: false,
  });
  var [resendTries, setResendTries] = React.useState(0);
  const navigate = useNavigate(); //Initializing navigation handler from react dom
  var [resendCounter, setResendCounter] = React.useState("00:00");

  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }

  function timerRun(seconds: number) {
    let counter = seconds;

    const interval = setInterval(() => {
      counter--;
      setResendCounter(formatSecTommss(counter));
      if (counter <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
  function resendCode() {
    console.log("resend code", resendTries);
    if (resendTries < 3) {
      setLoader(true);

      services.resendOtp(values.phone).then((res) => {
        setLoader(false);
        console.log(res);
        if (
          res &&
          res.data &&
          res.data.message === "Resend OTP Successfully!"
        ) {
          setResendTries(resendTries + 1);
          showAlert("success", messageUtil.OTP_SENT);
          timerRun(constants.RESEND_OTP_IN_SECONDS);
        } else {
          showAlert("error", res.data.message);
        }
      });
    } else {
      swal("error", messageUtil.OTP_LIMIT_EXCEEDED);
    }
  }

  // Used to navigate to provided path from any components
  function navigateTo(path: string) {
    navigate(path);
  }

  function loginToggle(value: boolean) {
    if (value === true) {
      // for toggling UI
      setToggle(false);
      // for toggling Login button
      setStage("Get OTP");
    } else {
      // for toggling UI
      setStage("Log In");
      // for toggling Login button
      setToggle(true);
    }
  }

  useEffect(() => {
    let profileData = getFromLocalStorage("profileData");
    if (profileData && profileData.syllabus) {
      navigateTo("/dashboard");
    }
  }, []);

  return loader ? (
    <LoaderComponent />
  ) : (
    <>
      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />

      <div className="signupLayout fade-in-animate">
        {otpPwdToggle ? (
          <LoginWithPwdComponent
            stage={stage}
            resendCode={resendCode}
            resendCounter={resendCounter}
            otpPwdToggle={otpPwdToggle}
            loginToggle={loginToggle}
            navigateTo={navigateTo}
            setLoader={setLoader}
            showAlert={showAlert}
          />
        ) : (
          <LoginWithOtpComponent
            stage={stage}
            setStage={setStage}
            resendCode={resendCode}
            resendCounter={resendCounter}
            otpPwdToggle={otpPwdToggle}
            loginToggle={loginToggle}
            navigateTo={navigateTo}
            setLoader={setLoader}
            showAlert={showAlert}
            setValues={setValues}
            values={values}
            setErrors={setErrors}
            errors={errors}
            timerRun={timerRun}
          />
        )}
      </div>
    </>
  );
}

function LoginWithOtpComponent(props: {
  stage: string;
  resendCode: any;
  resendCounter: string;
  otpPwdToggle: any;
  loginToggle: any;
  navigateTo: any;
  setStage: any;
  setLoader: any;
  showAlert: any;
  setValues: any;
  values: any;
  setErrors: any;
  errors: any;
  timerRun: any;
}) {
  let [otp, setOtp] = React.useState("");
  let [otpErr, setOtpErr] = React.useState(false);

  useEffect(() => {
    if (otp && otp.length === constants.AUTH_CODE_LENGTH) {
      stageButtonTrigger("Log In")
    }
  }, [otp]);
  // var [countrySelected, setcountrySelected] = React.useState(false);
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;
      console.log(event.target.value.length);
      // if value is not blank, then test the regex
      // console.log(values.phone);
      // let validateLength = 10;
      // if (values.country.length === 4) validateLength = 9;
      if (
        prop === "phone" &&
        event.target.value.length < 11 &&
        (event.target.value === "" || re.test(event.target.value))
      ) {
        props.setErrors({ ...props.errors, phone: false });
        return props.setValues({ ...props.values, [prop]: event.target.value });
      } else if (prop === "password") {
        if (event.target.value.length <= constants.PASSWORD_LENGTH)
          props.setValues({ ...props.values, [prop]: event.target.value });
      } else if (prop === "country") {
        props.setValues({ ...props.values, [prop]: event.target.value });
      }
    };

  async function stageButtonTrigger(prop: string) {
    console.log("stageButtonTrigger");
    switch (prop) {
      case "Get OTP":
        if (props.values.phone === "" || props.values.phone.length < 10) {
          return props.setErrors({ ...props.errors, phone: true });
        }

        props.setLoader(true);
        await services
          .generateLoginOtp({
            country: props.values.country,
            mobile: props.values.phone,
          })
          .then((res) => {
            props.setLoader(false);
            console.log(res.failed);
            if (res.failed) return props.showAlert("error", res.message);
            else if (
              res &&
              res.data &&
              res.data.message === "OTP send successfully !"
            ) {
              props.showAlert("success", messageUtil.OTP_SENT);
              props.timerRun(constants.RESEND_OTP_IN_SECONDS);
              props.setStage("Log In");
            } else {
              props.showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
            }
          })
          .catch((e) => {
            props.setLoader(false);
            console.log(e);
            props.showAlert("error", e);
          });
        break;
      case "Log In":
        if (!props.otpPwdToggle) {
          if (!otp || otp === "" || otp.length < constants.AUTH_CODE_LENGTH) {
            return setOtpErr(true);
          } else {
            setOtpErr(false);
          }
          props.setLoader(true);

          var apiData = {
            mobile: props.values.phone,
            otp,
            partner: constants.PARTNERS.DOUBTBOX,
          };
          services
            .verifyLoginOtp(apiData)
            .then((data) => {
              props.setLoader(false);
              console.log(data);
              if (data.failed) return props.showAlert("error", data.message);

              let resultData = data.data;
              // console.log(resultData);
              // console.log(resultData.data.profile[0].syllabus);
              if (
                resultData &&
                resultData.message === constants.LOGIN_SUC_FROM_SERVER
              ) {
                console.log(resultData);
                resultData.data.hassPass = resultData.data.hasPass
                  ? true
                  : false;
                resultData.data.hasPassString = JSON.stringify(resultData.data.hasPass)
                localStorage.setItem(
                  "userData",
                  JSON.stringify(resultData.data)
                );
                localStorage.setItem(
                  "profileData",
                  JSON.stringify(resultData.data.profile[0])
                );
                console.log(resultData.data.profile[0]);
                console.log(resultData.data.profile[0].syllabus);

                if (!resultData.data.profile[0].hasOwnProperty("syllabus")) {
                  localStorage.setItem("incompleteSignup", "true");
                  props.navigateTo("/signup");
                  return;
                }

                if (
                  resultData.data.profile &&
                  resultData.data.profile.length > 1
                )
                  props.navigateTo("/select-learner");
                else if (
                  resultData.data.profile &&
                  resultData.data.profile.length === 1
                )
                  props.navigateTo("/dashboard");
                // if the user did not complete signup completely, route to signup page and set stage to 2
              } else {
                props.showAlert("error", resultData.message);
                setOtpErr(true);
              }
            })
            .catch((e) => {
              console.log(e);
              props.setLoader(false);
              props.showAlert("error", e);
            });
        }

        break;
    }
    function decideLogin(data: any) {
      console.log(data);
      // if login success
      props.navigateTo("/dashboard");
    }
  }
  return (
    <>
      {props.stage !== "stage3" ? (
        <Grid className="signupCard" container item xs={12} sm={8} md={4}>
          <Grid className="SignUpdbLogo" item>
            <DoubtboxLogo />
          </Grid>
          <Grid className="inputsLayout" item xs={12}>
            <div className="signupHeadText">Log In</div>
            <div className="inputsLayout label">
              Start learning at your own pace
            </div>
          </Grid>
          {/* stage 1 */}
          {props.stage === "Get OTP" ? (
            <LoginStage1
              handleChange={handleChange}
              values={props.values}
              errors={props.errors}
              stageButtonTrigger={stageButtonTrigger}
            />
          ) : null}
          {/* stage 2 */}
          {props.stage === "Log In" ? (
            <SignupStage2
              resendCode={props.resendCode}
              counterValue={props.resendCounter}
              setOtp={setOtp}
              otpErr={otpErr}
            />
          ) : null}
          {/* stage 3 */}
          {props.stage === "Log In with OTP"}

          <Grid className="tandcGrid" item>
            <div className="tandc">
              Don’t have an account ? &nbsp;
              <span
                className="signupLink"
                onClick={() => props.navigateTo("/signup")}
              >
                Sign Up
              </span>
            </div>
          </Grid>
          <SignupStageButton
            buttonValue={props.stage}
            stageButtonTrigger={stageButtonTrigger}
          />
          <Divider style={{ marginTop: "20px" }}>Or</Divider>
          <LoginToggleButton
            buttonValue={props.otpPwdToggle}
            loginToggle={props.loginToggle}
          />
        </Grid>
      ) : null}
    </>
  );
}

function LoginWithPwdComponent(props: {
  stage: string;
  resendCode: any;
  resendCounter: string;
  otpPwdToggle: any;
  loginToggle: any;
  navigateTo: any;
  setLoader: any;
  showAlert: any;
}) {
  const [values, setValues] = React.useState<State>({
    country: "+91",
    password: "",
    class: "",
    phone: "",
    name: "",
  });
  var [errors, setError] = useState<StateErrors>({
    password: false,
    class: false,
    phone: false,
    name: false,
  });
  // var [countrySelected, setcountrySelected] = React.useState(false);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex
      console.log(prop);
      if (
        prop === "phone" &&
        event.target.value.length < 11 &&
        (event.target.value === "" || re.test(event.target.value))
      ) {
        return setValues({ ...values, [prop]: event.target.value });
      } else if (prop !== "phone") {
        setValues({ ...values, [prop]: event.target.value });
      }
    };

  function stageButtonTriggerLogin(func: string) {
    errors.phone = false;
    errors.password = false;
    setError(errors);
    if (values.phone === "") {
      return setError({ ...errors, phone: true });
    }
    if (values.password === "") {
      return setError({ ...errors, password: true });
    } else if (values.password.length > constants.PASSWORD_LENGTH) {
      setError({ ...errors, password: true });
      props.showAlert(
        "error",
        "Password length cannot exceed " +
          constants.PASSWORD_LENGTH +
          " characters"
      );
      return;
    } else if (values.password.length < constants.MIN_PASSWORD_LENGTH) {
      setError({ ...errors, password: true });
      props.showAlert(
        "error",
        "Password requires min  " +
          constants.MIN_PASSWORD_LENGTH +
          " characters"
      );
      return;
    }
    var apiData = {
      mobile: values.phone,
      password: Md5.hashStr(values.password),
      partner: constants.PARTNERS.DOUBTBOX,
    };

    props.setLoader(true);
    services
      .login(apiData)
      .then((data) => {
        console.log(data);
        props.setLoader(false);
        if (data && data.message === constants.LOGIN_SUC_FROM_SERVER) {
          let profiles = [];
          for (let each in data.data.profile) {
            let one = data.data.profile[each];
            if (one.syllabus) {
              profiles.push(one);
            }
          }
          data.data.profile = profiles;
          localStorage.setItem("userData", JSON.stringify(data.data));
          localStorage.setItem(
            "profileData",
            JSON.stringify(data.data.profile[0])
          );
          if (data.data.profile && data.data.profile.length > 1)
            props.navigateTo("/select-learner");
          else props.navigateTo("/dashboard");
        } else {
          props.showAlert("error", data.message);
          // // for development. after development remove this code
          // localStorage.setItem("userData", JSON.stringify(userData));
          // if (userData.profile && userData.profile.length > 0)
          //   props.navigateTo("/select-learner");
          // else props.navigateTo("/dashboard");
          //
        }
      })
      .catch((e) => {
        console.log(e);
        props.setLoader(false);
        props.showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
      });
  }
  return (
    <>
      {props.stage !== "stage3" ? (
        <Grid className="signupCard" container item xs={12} sm={8} md={4}>
          <Grid className="SignUpdbLogo" item>
            <DoubtboxLogo />
            {/* <DoubtboxLogo /> */}
          </Grid>

          <Grid className="inputsLayout" item>
            <div className="signupHeadText">Log In</div>
            <div className="inputsLayout label">
              Start learning at your own pace
            </div>
          </Grid>
          <LoginWithPwdStage
            setValues={setValues}
            handleChange={handleChange}
            values={values}
            errors={errors}
            stageButtonTriggerLogin={stageButtonTriggerLogin}
          />

          <Grid className="tandcGrid" item>
            <div className="tandc">
              Don’t have an account ?
              <span
                className="signupLink"
                onClick={() => props.navigateTo("/signup")}
              >
                Sign Up
              </span>
            </div>
          </Grid>
          <SignupStageButton
            buttonValue={props.stage}
            stageButtonTrigger={stageButtonTriggerLogin}
          />
          <Divider style={{ marginTop: "20px" }}>Or</Divider>
          <LoginToggleButton
            buttonValue={props.otpPwdToggle}
            loginToggle={props.loginToggle}
          />
        </Grid>
      ) : null}
    </>
  );
}

// stage 1
function LoginStage1(props: {
  handleChange: any;
  values: any;
  errors: any;
  stageButtonTrigger: any;
}) {
  return (
    <Grid container>
      <Grid item xs={12} className="inputContainer">
        <OutlinedInput
          id="outlined-adornment-phone"
          value={props.values.phone}
          onChange={props.handleChange("phone")}
          error={props.errors.phone}
          style={{ paddingLeft: "0px", width: "300px" }}
          onKeyDown={(e) =>
            e.keyCode === 13 && props.stageButtonTrigger("Get OTP")
          } //Enter key triggers submit
          startAdornment={
            <TextField
              id="outlined-select-country"
              select
              type="number"
              value={props.values.country}
              onChange={props.handleChange("country")}
              // onClick={togglePhoneCode}
              className="phonePrefix"
              style={{ paddingRight: "0px" }}
            >
              {COUNTRY_CODES_LIST.map((option) => (
                <MenuItem key={option.name} value={option.dial_code}>
                  {option.dial_code}
                </MenuItem>
              ))}
            </TextField>
          }
          className="phoneNumberClass"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </Grid>
      {props.errors.phone === true ? (
        <Grid item xs={12} className="inputContainer">
          <span style={{ color: "red" }}>
            Please enter a valid phone number
          </span>
        </Grid>
      ) : null}
    </Grid>
  );
}

// stage 2
function SignupStage2(props: {
  resendCode: any;
  counterValue: string;
  otpErr: boolean;
  setOtp: any;
}) {
  const handleOnChange = (res: string) => {
    props.setOtp(res);
  };
  return (
    <div className="OTPContainer">
      <AuthCode
        allowedCharacters="numeric"
        onChange={handleOnChange}
        inputClassName={props.otpErr ? "authCodeClassErr" : "authCodeClass"}
        length={constants.AUTH_CODE_LENGTH}
      />
      {props.counterValue === "00:00" ? (
        <div className="resendCodeText" onClick={props.resendCode}>
          Resend Code
        </div>
      ) : (
        <div className="resendCodeText">
          Resend Code in {props.counterValue}
          {/* {props.counterValue < 10
            ? "0" + props.counterValue
            : props.counterValue} */}
        </div>
      )}
    </div>
  );
}

// Login with Password stage
function LoginWithPwdStage(props: {
  setValues: any;
  handleChange: any;
  values: State;
  errors: any;
  stageButtonTriggerLogin: any;
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Grid container>
      <Grid item xs={12} className="inputContainer">
        <OutlinedInput
          id="outlined-adornment-phone"
          value={props.values.phone}
          onChange={props.handleChange("phone")}
          error={props.errors.phone}
          style={{ paddingLeft: "0px", width: "300px" }}
          onKeyDown={(e) => e.keyCode === 13 && props.stageButtonTriggerLogin()}
          startAdornment={
            <TextField
              id="outlined-select-currency"
              select
              type="number"
              value={props.values.country}
              onChange={props.handleChange("country")}
              // onClick={togglePhoneCode}
              className="phonePrefix"
            >
              {COUNTRY_CODES_LIST.map((option) => (
                <MenuItem key={option.name} value={option.dial_code}>
                  {option.dial_code}
                </MenuItem>
              ))}
            </TextField>
          }
          className="phoneNumberClass"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </Grid>

      <Grid item xs={12} className="inputContainer">
        {/* <TextField
          className="nameInput"
          id="outlined-adornment-password"
          label="Password"
          value={props.values.password}
          type="password"
          onChange={props.handleChange("password")}
          required
          error={props.errors.password}
          onKeyDown={(e) => e.keyCode === 13 && props.stageButtonTriggerLogin()}
        ></TextField> */}
        <TextField
          className="pwdInput"
          id="outlined-adornment-password"
          label="Password"
          value={props.values.password}
          type={showPassword ? "text" : "password"}
          onChange={props.handleChange("password")}
          required
          error={props.errors.password}
          onKeyDown={(e) => e.keyCode === 13 && props.stageButtonTriggerLogin()}
        ></TextField>
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
        {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          /> */}
      </Grid>
    </Grid>
  );
}

function SignupStageButton(props: {
  buttonValue: string;
  stageButtonTrigger: any;
}) {
  return (
    <Grid className="tandcGrid">
      {/* need to add dynamic value in get Otp */}
      <div
        className="stageButton"
        onClick={() => props.stageButtonTrigger(props.buttonValue)}
      >
        {props.buttonValue === "stage3" ? "Submit" : props.buttonValue}
      </div>
    </Grid>
  );
}

function LoginToggleButton(props: { buttonValue: boolean; loginToggle: any }) {
  return (
    <Grid className="tandcGrid toggle">
      <div
        className="stageButton outlined"
        onClick={() => props.loginToggle(props.buttonValue)}
      >
        {props.buttonValue === true
          ? "Log In with OTP"
          : "Log In with Password"}
      </div>
    </Grid>
  );
}
