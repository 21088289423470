/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import { Grid } from "@mui/material";
import Navbar from "../../../shared/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import "../liveTuition.scss";
import LoaderComponent from "../../../shared/loader/loader";
import ReactPlayer from "react-player";
import "../liveTuition.scss";
import { getWindowDimensions, getxsValue } from "../../../shared/utils/utilityFunctions";



/** Actual Live LiveTuitionPlayback page function starts here **/
export function LiveTuitionPlayback() {
  const navigate = useNavigate();
  const location = useLocation();
  let stateData = location.state;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;

  const [urlArray, setUrlArray] = useState<string[]>([]);
  const [profileData, setProfileData] = useState({
    userName: "",
    standard: "",
    syllabus: "",
    profile_image:""
  });

  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [isPlaying, setPlaying] = useState(true);

  useEffect(() => {
    let urlData = [];
    for (let i = 0; i < stateData.length; i++) {
      let one = stateData[i];
      if (hostname === constants.LOCALHOST)
        urlData.push(constants.DEV_LIVE_URL+ one);
      else urlData.push(protocol + "//" + hostname + one);
    }
    setUrlArray(urlData);
    setselectedUrl(urlData[0]);
  }, [profileData.syllabus]);

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  function navigateTo(path: string) {
    navigate(path);
  }
  const [selectedUrl, setselectedUrl] = React.useState("");
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };

  function selectVideo(url: any) {
    setselectedUrl(url);
  }

  return (
    <Grid container style={{ width: "98vw" }}>
      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"live-tuition"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        {/* <TopBarComponent search={true} logo={false} profileData={profileData} /> */}
        <div style={{ marginLeft: "1vw", paddingTop: 25 }}>
          {/* completed sessions */}
          <Grid xs={12} container>
            {/* {urlArray && urlArray.length > 1 && ( */}
            <Grid xs={10.5} container item>
              {urlArray.map((url: string, index: number) => {
                return (
                  <>
                    {url === selectedUrl ? (
                      <Grid
                        onClick={() => selectVideo(url)}
                        className="videoChangeButton selected"
                      >
                        Video {index + 1}
                      </Grid>
                    ) : (
                      <Grid
                        onClick={() => selectVideo(url)}
                        className="videoChangeButton "
                      >
                        Video {index + 1}
                      </Grid>
                    )}
                  </>
                  // <Grid onClick={() => selectVideo(url)} className="">
                  //   Video {index + 1}
                  // </Grid>
                );
              })}
            </Grid>
            <Grid xs={1} container item>
              <Grid
                onClick={() => navigateTo("/live-tuition/completed")}
                className="videoChangeButton back"
              >
                Back
              </Grid>
            </Grid>
            {/* )} */}

            <ReactPlayer
              className="react-player"
              url={selectedUrl}
              width="98%"
              height="auto"
              controls
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
              playing={isPlaying}
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
