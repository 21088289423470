/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna <rahul.krishna@doubtbox.com>, November 2022\
 * Signup page
 * */
import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import "./Signup.scss";

import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { COUNTRY_CODES_LIST } from "../../constants/countryCodes";
import { ReactComponent as DoubtboxLogo } from "../../assets/logo/doubtbox_logo.svg";
import AuthCode from "react-auth-code-input";

import { constants } from "../../constants/constants";
import { services } from "../../services/api_service";
import LoaderComponent from "../../shared/loader/loader";
import AlertService from "../../shared/alert/alertComponent";
import { messageUtil } from "../../shared/utils/messages";
import { formatSecTommss } from "../../shared/utils/utilityFunctions";
import { State, StateError } from "../../constants/interfaces";

// Get OTP = stage 1
// Submit = stage 2
// stage3 = stage 3
// in stage3, user profile is saved and redirected to dashboard

export function SignupPage() {
  const [stage, setStage] = useState("Get OTP");
  const [loader, setLoader] = useState(false);
  var [resendCounter, setResendCounter] = useState("00:00");
  const [classList, setClassList] = useState([{ id: 0, name: "No Data!" }]);
  const location = useLocation();
  const [addKid, setTree] = useState(location.state?.addKid);
  const [values, setValues] = useState<State>({
    country: "+91",
    altCountry: "+91",
    password: "",
    class: "",
    phone: "",
    name: "",
    email: "",
    altEmail: "",
    city: "",
    finalExam: "",
    syllabus: "",
    altPhone: "",
  });
  var [errors, setErrors] = useState<StateError>({
    password: false,
    class: false,
    phone: false,
    altPhone: false,
    name: false,
    email: false,
    syllabus: false,
    finalExam: false,
    city: false,
    otp: false,
  });
  const [otp, setOtp] = useState("");
  let [otpErr, setOtpErr] = React.useState(false);
  var [resendTries, setResendTries] = React.useState(0);

  const [syllabusList, setSyllabusList] = useState([]);
  const [accountExist, setAccountExist] = useState(true);
  const [userData, setUserData] = useState({
    mobile: "",
    name: "",
    email: "",
    profile: [],
  });
  useEffect(() => {
    if (otp && otp.length === constants.AUTH_CODE_LENGTH) {
      stageButtonTrigger("Submit")
    }
  }, [otp]);
  useEffect(() => {
    const fetchData = () => {
      services
        .getSyllabus()
        .then((result: any) => {
          if (result.status === constants.SUCCESS_CODE) {
            setSyllabusList(result.data);
          } else {
            console.log("error", messageUtil.SOMETHING_WENT_WRONG_OS);
          }
          // console.log(result);
          // setSyllabusList(result);
        })
        .catch((e) => {
          showAlert("error", messageUtil.FETCH_SYLLABUS_FAILED);
        });
    };
    function isIncompleted() {
      let incompleteSignup = localStorage.getItem("incompleteSignup");
      if (
        typeof incompleteSignup !== "undefined" &&
        incompleteSignup !== null
      ) {
        console.log(incompleteSignup);
        if (incompleteSignup === "true") {
          var userData = localStorage.getItem("userData");

          setStage("stage3");
          if (typeof userData !== "undefined" && userData !== null)
            setUserData(JSON.parse(userData));
        }
      }
    }

    fetchData();
    isIncompleted();
  }, [stage]);
  useEffect(() => {
    console.log(addKid, userData.mobile);
    if (addKid && userData.mobile) {
      setValues({ ...values, phone: userData.mobile });

      // setValues({ ...values, email: userData.email });
    }
  }, [userData]);
  useEffect(() => {
    if (addKid) {
      var userDt = localStorage.getItem("userData");
      if (typeof userDt !== "undefined" && userDt !== null)
        setUserData(JSON.parse(userDt));

      console.log(userData);
    }
  }, [addKid]);

  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }

  function stageButtonTrigger(value: string) {
    errors.phone = false;
    errors.email = false;
    errors.name = false;
    errors.class = false;
    let name = values.name.trim();
    console.log(!/^[a-zA-Z ]+$/.test(values.name) || name.length < 3);

    setValues({ ...values, name: name });
    // console.log(
    //   values.email === "" ||
    //     !values.email.includes("@") ||
    //     !values.email.includes(".com")
    // );
    setErrors(errors);

    switch (value) {
      case "Get OTP":
        // sendOtp().then
        let validateLength = 10;
        if (values.country.length === 4) validateLength = 8;
        if (values.phone === "" || values.phone.length < validateLength) {
          return setErrors({ ...errors, phone: true });
        } else {
          setErrors({ ...errors, phone: false });
        }
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
        ) {
          return setErrors({ ...errors, email: true });
        } else if (!/^[a-zA-Z ]+$/.test(name) || name.length < 3) {
          return setErrors({ ...errors, name: true });
        }
        setLoader(true);
        let apiData = {
          mobile: values.phone.replace(values.country, ""),
          countryCode: values.country,
          email: values.email ? values.email.toLowerCase() : "",
          name: name,
          partner: constants.PARTNERS.DOUBTBOX,
        };
        console.log(apiData);
        services
          .generateSignupOtp(apiData)
          .then((res) => {
            console.log(res);
            setLoader(false);
            if (res && res.failed) {
              if (res.message === "User already Exist!") setAccountExist(true);
              showAlert("error", res.message);
            } else {
              showAlert("success", messageUtil.OTP_SENT);
              timerRun(constants.RESEND_OTP_IN_SECONDS);
              setStage("Submit");
            }
          })
          .catch((e) => {
            console.log(e);
            showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
          });
        break;
      case "Submit":
        errors.otp = false;
        setErrors(errors);
        if (otp === "") {
          return setErrors({ ...errors, otp: true });
        }
        let otpData = {
          mobile: values.phone,
          otp: otp,
        };
        setLoader(true);
        services
          .verifySignupOtp(otpData)
          .then((res) => {
            console.log(res);
            setLoader(false);

            let resultData: any = res.data ? res.data : res;
            console.log(resultData);

            if (res && res.status === 200) {
              console.log(resultData);
              localStorage.setItem("userData", JSON.stringify(resultData.data));
              localStorage.setItem(
                "profileData",
                JSON.stringify(resultData.data.profile[0])
              );
              showAlert("success", messageUtil.SIGNUP_SUCCESFULL);
              localStorage.removeItem("incompleteSignup");
              setStage("stage3");
            } else {
              let message = resultData.message.replace("login", "Sign Up");
              showAlert("error", message);
              setOtpErr(true);
            }
          })
          .catch((e) => {
            console.log(e);
            showAlert("error", messageUtil.WRONG_OTP);
          });
        // setStage("stage3");
        break;
      case "stage3":
        // checkOtp().then
        setStage("stage3");
        break;
    }
  }

  // Counter for enabling resending otp.
  function timerRun(seconds: number) {
    let counter = seconds;

    const interval = setInterval(() => {
      counter--;
      setResendCounter(formatSecTommss(counter));
      if (counter <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
  function resendCode() {
    console.log("resend code", resendTries);
    if (resendTries < 3) {
      setLoader(true);
      console.log(values.phone);
      services.resendOtp(values.phone).then((res) => {
        setLoader(false);
        console.log(res);
        if (
          res &&
          res.data &&
          res.data.message === "Resend OTP Successfully!"
        ) {
          setResendTries(resendTries + 1);
          showAlert("success", messageUtil.OTP_SENT);
          timerRun(constants.RESEND_OTP_IN_SECONDS);
        } else {
          showAlert("error", res.data.message);
        }
      });
    } else {
      swal("error", messageUtil.OTP_LIMIT_EXCEEDED);
    }
  }

  var [askEmail, setAskEmail] = React.useState(false);
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;
      if (
        prop === "phone" &&
        event.target.value.length < 11 &&
        (event.target.value === "" || re.test(event.target.value))
      ) {
        setErrors({ ...errors, phone: false });
        return setValues({ ...values, [prop]: event.target.value });
      }

      // if value is not blank, then test the regex
      else if (prop === "country") {
        // If country selected is Not India, set askEmail to true, Becaus OTP is sent to email for users outside india
        if (event.target.value !== "+91") setAskEmail(true);
        else if (event.target.value === "+91") setAskEmail(false);
      }
      if (prop !== "phone") {
        setValues({ ...values, [prop]: event.target.value });
        console.log(prop, event.target.value);
      }
    };
  const navigate = useNavigate();
  return (
    <>
      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />

      {loader && <LoaderComponent />}
      <div className="signupLayout hideScrollBar fade-in-animate">
        {stage === "stage3" ? (
          <SignupStage3
            setValues={setValues}
            values={values}
            syllabusList={syllabusList}
            setLoader={setLoader}
            setClassList={setClassList}
            classList={classList}
            errors={errors}
            showAlert={showAlert}
            setErrors={setErrors}
            userData={userData}
          />
        ) : null}

        {stage !== "stage3" ? (
          <Grid
            className="signupCard hideScrollBar"
            container
            item
            xs={12}
            sm={8}
            md={4}
          >
            <Grid className="SignUpdbLogo" item>
              <DoubtboxLogo />
            </Grid>
            <Grid className="inputsLayout" item>
              <div className="signupHeadText">Sign Up</div>
              <div className="inputsLayout label">
                Start learning at your own pace
              </div>
            </Grid>
            {/* stage 1 */}
            {stage === "Get OTP" ? (
              <SignupStage1
                handleChange={handleChange}
                values={values}
                askEmail={askEmail}
                errors={errors}
                stageButtonTrigger={stageButtonTrigger}
                addKid={addKid}
              />
            ) : null}
            {/* stage 2 */}
            {stage === "Submit" ? (
              <SignupStage2
                resendCode={resendCode}
                counterValue={resendCounter}
                setOtp={setOtp}
                otp={otp}
                errors={errors}
                otpErr={otpErr}
              />
            ) : null}
            {/* stage 3 */}
            {accountExist === true ? (
              <Grid className="tandcGrid" item>
                <div className="tandc">
                  Already have an account ?
                  <span
                    className="signupLink"
                    onClick={() => navigate("/login")}
                  >
                    {" "}
                    &nbsp;Log In
                  </span>
                </div>
              </Grid>
            ) : null}
            <Grid className="tandcGrid" item>
              <div className="tandc">
                By signing in, I agree to Doubtbox &nbsp;
                <span
                  className="tandc tandcLinks"
                  onClick={() =>
                    window.open(
                      constants.PRIVACY_POLICY_URL,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Privacy Policy
                </span>
                &nbsp; and &nbsp;
                <span
                  className="tandc tandcLinks"
                  onClick={() =>
                    window.open(
                      constants.TERMS_OF_USE_URL,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Terms of use
                </span>
              </div>
            </Grid>

            <SignupStageButton
              buttonValue={stage}
              stageButtonTrigger={stageButtonTrigger}
            />
          </Grid>
        ) : null}
      </div>
    </>
  );
}
export default SignupPage;

// component for stage 1
function SignupStage1(props: {
  handleChange: any;
  values: any;
  askEmail: boolean;
  errors: any;
  stageButtonTrigger: any;
  addKid: boolean;
}) {
  return (
    <Grid container>
      <Grid item xs={12} className="inputContainer">
        <OutlinedInput
          id="outlined-adornment-phone"
          value={props.values.phone}
          onChange={props.handleChange("phone")}
          error={props.errors.phone}
          style={{ paddingLeft: "0px", width: "300px" }}
          onKeyDown={(e) =>
            e.keyCode === 13 && props.stageButtonTrigger("Get OTP")
          } //Enter key triggers submit
          disabled={props.addKid}
          startAdornment={
            <TextField
              id="outlined-select-currency"
              select
              type="number"
              value={props.values.country}
              onChange={props.handleChange("country")}
              className="phonePrefix"
            >
              {COUNTRY_CODES_LIST.map((option) => (
                <MenuItem key={option.name} value={option.dial_code}>
                  {option.dial_code}
                </MenuItem>
              ))}
            </TextField>
          }
          className="phoneNumberClass"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </Grid>
      {props.errors.phone === true ? (
        <Grid item xs={12} className="inputContainer">
          <span style={{ color: "red" }}>{messageUtil.VALID_PHONE_NUMBER}</span>
        </Grid>
      ) : null}

      <Grid className="inputContainer" item sm={12}>
        <TextField
          className="nameInput"
          id="outlined-adornment-email"
          label="Email"
          value={props.values.email}
          onChange={props.handleChange("email")}
          error={props.errors.email}
          onKeyDown={(e) =>
            e.keyCode === 13 && props.stageButtonTrigger("Get OTP")
          } //Enter key triggers submit
        ></TextField>
      </Grid>

      {props.errors.email === true ? (
        <Grid item xs={12} className="inputContainer">
          <span style={{ color: "red" }}>{messageUtil.VALID_EMAIL}</span>
        </Grid>
      ) : null}
      <Grid className="inputContainer" item sm={12}>
        <TextField
          className="nameInput"
          id="outlined-adornment-name"
          label="Name"
          value={props.values.name}
          onChange={props.handleChange("name")}
          error={props.errors.name}
          onKeyDown={(e) =>
            e.keyCode === 13 && props.stageButtonTrigger("Get OTP")
          } //Enter key triggers submit
        ></TextField>
      </Grid>
      {props.errors.name === true ? (
        <Grid item xs={12} className="inputContainer">
          <span style={{ color: "red" }}>{messageUtil.VALID_NAME}</span>
        </Grid>
      ) : null}
    </Grid>
  );
}

// component for stage2
function SignupStage2(props: {
  resendCode: any;
  counterValue: string;
  setOtp: any;
  otp: string;
  errors: any;
  otpErr: boolean;
}) {
  const handleOnChange = (res: string) => {
    props.setOtp(res);
  };
  return (
    <div className="OTPContainer">
      <AuthCode
        allowedCharacters="numeric"
        onChange={handleOnChange}
        inputClassName={props.otpErr ? "authCodeClassErr" : "authCodeClass"}
        length={constants.AUTH_CODE_LENGTH}
      />
      {props.counterValue === "00:00" ? (
        <div className="resendCodeText" onClick={props.resendCode}>
          Resend Code
        </div>
      ) : (
        <div className="resendCodeText">
          Resend Code in {props.counterValue}
        </div>
      )}
    </div>
  );
}

function SignupStage3(props: {
  setValues: any;
  values: any;
  syllabusList: any;
  setLoader: any;
  setClassList: any;
  classList: any;
  errors: any;
  setErrors: any;
  showAlert: any;
  userData: any;
}) {
  return (
    <>
      <div className="signupLayout stage3">
        <Grid
          className="signupCard stage3 hideScrollBar"
          container
          item
          xs={12}
          sm={8}
          md={4}
        >
          <div className="inputsLayout" style={{ marginTop: "40px" }}>
            <DoubtboxLogo /> <br />
            Complete your
            <br /> Learner profile
          </div>

          <Stage3Components
            setValues={props.setValues}
            values={props.values}
            syllabusList={props.syllabusList}
            setLoader={props.setLoader}
            setClassList={props.setClassList}
            classList={props.classList}
            errors={props.errors}
            showAlert={props.showAlert}
            setErrors={props.setErrors}
            userData={props.userData}
          />
        </Grid>
      </div>
    </>
  );
}

// contains components fo stage 3
function Stage3Components(props: {
  setValues: any;
  values: any;
  syllabusList: any;
  setLoader: any;
  setClassList: any;
  classList: any;
  errors: any;
  setErrors: any;
  showAlert: any;
  userData: any;
}) {
  const navigate = useNavigate();

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;
      // props is the porperities inside the component.
      // where as prop is the properity inside this handleChange function. Both are not same

      // if value is not blank, then test the regex
      console.log(prop === "altPhone" && event.target.value.length <= 10);
      if (
        prop === "altPhone" &&
        event.target.value.length <= 10 &&
        (event.target.value === "" || re.test(event.target.value))
      ) {
        return props.setValues({ ...props.values, [prop]: event.target.value });
      } else if (prop === "syllabus") {
        props.setErrors({ ...props.errors, syllabus: false });
        props.setLoader(true);
        services
          .getClass(event.target.value)
          .then((res: any) => {
            var classData = res.data;
            console.log(classData.length);
            if (classData && classData.length > 0) {
              props.setClassList(classData);
              console.log(props.classList);
            } else {
              props.setClassList([{ id: 0, name: "No Data!!" }]);
            }
            props.setValues({ ...props.values, [prop]: event.target.value });

            props.setLoader(false);
          })
          .catch((e) => {
            console.log(e);
            props.showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
          });
        console.log(prop);
      } else {
        if (props.errors[prop] === true)
          props.setErrors({ ...props.values, [prop]: false });
        if (prop !== "altPhone")
          props.setValues({ ...props.values, [prop]: event.target.value });
      }
    };

  function validateSignup() {
    if (props.values.syllabus === "") {
      props.setErrors({ ...props.errors, syllabus: true });
      return false;
    }
    if (props.values.class === "") {
      props.setErrors({ ...props.errors, class: true });
      return false;
    }
    // if (props.values.city === "") {
    //   props.setErrors({ ...props.errors, city: true });
    //   return false;
    // }
    // if (props.values.altPhone === "") {
    //   props.setErrors({ ...props.errors, altPhone: true });
    //   return false;
    // }
    // if (props.values.altEmail === "") {
    //   props.setErrors({ ...props.errors, altEmail: true });
    //   return false;
    // }
    return true;
  }
  // signup page logic ends here with this api
  async function stageButtonTrigger() {
    props.setLoader(false);
    let proceed = validateSignup();
    if (!proceed) return false;
    props.setLoader(true);
    function getName(param: string, paramId: string) {
      let elemName = "";
      switch (param) {
        case "syllabus":
          for (let i = 0; i < props.syllabusList.length; i++) {
            let elem = props.syllabusList[i];
            if (elem.id === paramId) elemName = elem.name;
          }
          return elemName;
        case "class":
          for (let i = 0; i < props.classList.length; i++) {
            let elem = props.classList[i];
            if (elem.id === paramId) elemName = elem.name;
          }
          return elemName;
      }
    }

    /** 
     * static syllabus passed for every student without considering their actual syllabus for rectifying an issue in
       Recorded Class,Animated video, Assess Me and Worksheets features.
       
      * Based on Task 
       Task 1232: Recorded Class, Animated video, Assess Me and Worksheets - Listed chapters only on CBSE syllabus
       task URL: https://dev.azure.com/doubtbox/DoubtBox/_workitems/edit/1232/ 
       
       This may cause unexpected behaviours from the app down the line. Risks of completing this task has been
       notified to the team.

       This is done because there are no contents for ICSE, state syllabusses and contents are sold to students in ICSE and state syllabus.

       Customers who buy the scratch card over the counter will register with ICSE or State syllabus and use the card to activate.
       Which causes conflict as there are no scratch card for ICSE and state syllabusses (Coupons are created only for CBSE).

       To avoid this and reduce the risk of the app being unstable, This is being done.

       While signing up, syllabusId is provided as 1 (ID for CBSE).
       */

    let profileData = {
      mobile: props.values.phone ? props.values.phone : props.userData.mobile,
      // syllabusId: props.values.syllabus,
      syllabusId: 1,
      syllabusName: getName("syllabus", props.values.syllabus),
      finalExam: "",
      city: props.values.city,
      altMobileNo: props.values.altPhone,
      altMobileCode: props.values.altCountry,
      altEmailId: props.values.altEmail
        ? props.values.altEmail.toLowerCase()
        : "",
      classId: props.values.class,
      className: getName("class", props.values.class),
      name: props.values.name
        ? props.values.name
        : props.userData.profile[0].name,
    };
    console.log(profileData);
    services
      .saveLearnerProfile(profileData)
      .then((res) => {
        console.log(res);
        props.setLoader(false);
        if (res && res.message === "User signup Successfully") {
          swal({
            title: messageUtil.SIGNUP_SUCCESFULL,
            text: messageUtil.LOGIN_AGAIN,
            icon: "success",
          }).then((value) => {
            localStorage.clear();
            navigate("/login");
          });
        } else {
          props.showAlert(
            "error",
            res.data.message + " " + res.data.err.message
          );
        }
      })
      .catch((e) => {
        console.log(e);
        props.showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
      });
  }
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="inputContainer"
        style={{ marginTop: "36px" }}
      >
        <TextField
          className="nameInput"
          id="outlined-select-currency"
          select
          label="Choose your syllabus"
          value={props.values.syllabus}
          onChange={handleChange("syllabus")}
        >
          {props.syllabusList.map(
            ({ id, name }: { id: number; name: string }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            )
          )}
        </TextField>
      </Grid>
      {props.errors.syllabus === true ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ color: "red" }}>{messageUtil.ENTER_SYLLABUS}</span>
        </div>
      ) : null}
      <Grid
        item
        xs={12}
        className="inputContainer"
        style={{ marginTop: "36px" }}
      >
        <TextField
          className="nameInput"
          id="outlined-select-currency"
          select
          label="Class"
          value={props.values.class}
          onChange={handleChange("class")}
        >
          {props.classList.map(({ id, name }: { id: number; name: string }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {props.errors.class === true ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ width: "10vw", paddingLeft: "25px", color: "red" }}>
            {messageUtil.ENTER_STANDARD}
          </span>
        </div>
      ) : null}
      <Grid
        item
        xs={12}
        className="inputContainer"
        style={{ marginTop: "36px" }}
      >
        <TextField
          className="nameInput"
          id="outlined-adornment-city"
          label="City"
          value={props.values.city}
          onChange={handleChange("city")}
        ></TextField>
      </Grid>
      {props.errors.city === true ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ width: "10vw", paddingLeft: "25px", color: "red" }}>
            {messageUtil.ENTER_CITY}
          </span>
        </div>
      ) : null}
      <Grid
        item
        xs={12}
        className="inputContainer"
        style={{ marginTop: "36px" }}
      >
        <OutlinedInput
          id="outlined-adornment-altphone"
          value={props.values.altPhone}
          onChange={handleChange("altPhone")}
          style={{ paddingLeft: "0px", width: "300px" }}
          placeholder="Parent’s Phone"
          startAdornment={
            <TextField
              id="outlined-select-currency"
              select
              value={props.values.altCountry}
              onChange={handleChange("altCountry")}
              className="phonePrefix"
            >
              {COUNTRY_CODES_LIST.map((option) => (
                <MenuItem key={option.name} value={option.dial_code}>
                  {option.dial_code}
                </MenuItem>
              ))}
            </TextField>
          }
          className="phoneNumberClass"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </Grid>
      {props.errors.altPhone === true ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ paddingLeft: "25px", color: "red" }}>
            {messageUtil.VALID_PHONE_NUMBER}
          </span>
        </div>
      ) : null}
      <Grid
        item
        xs={12}
        className="inputContainer"
        style={{ marginTop: "36px" }}
      >
        <TextField
          className="nameInput"
          type="email"
          id="outlined-adornment-email"
          label="Parent’s  Email"
          value={props.values.altEmail}
          onChange={handleChange("altEmail")}
        ></TextField>
      </Grid>
      {props.errors.altEmail === true ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ paddingLeft: "25px", color: "red" }}>
            {messageUtil.VALID_EMAIL}
          </span>
        </div>
      ) : null}
      <SignupStageButton
        buttonValue={"Submit"}
        stageButtonTrigger={stageButtonTrigger}
      />
    </Grid>
  );
}
function SignupStageButton(props: {
  buttonValue: string;
  stageButtonTrigger: any;
}) {
  return (
    <Grid className="tandcGrid">
      <div
        className="stageButton"
        onClick={() => props.stageButtonTrigger(props.buttonValue)}
      >
        {props.buttonValue === "stage3" ? "Submit" : props.buttonValue}
      </div>
    </Grid>
  );
}
