/*
* constants.ts
* 
* @author Rahul krishna.

* @date 01/11/2022.

=> All the constants that are used in the app should be declared here and used from here.
* Do not store any keys or token data here 😐😑.
*/
interface ErrorCodes {
  [key: string]: string;
  // AgoraRTCErrorPERMISSION_DENIED: string;
}

export const constants = {
  /* * @version  */
  VERSION: "2.6",
  VERSION_TYPE: "stable",
  RELEASE_DATE: "02-11-2023",

  // NAVIGATION_ITEMS: [
  //   {
  //     display: "Dashboard",
  //     icon: "dashboard",
  //     to: "/dashboard",
  //     section: "",
  //   },
  //   {
  //     display: "Live Tuition",
  //     icon: "live-tuition",
  //     to: "/live-tuition",
  //     section: "started",
  //   },
  //   {
  //     display: "Learn On",
  //     icon: "learn-on",
  //     to: "/learn-on",
  //     section: "started",
  //   },
  //   {
  //     display: "Assess Me",
  //     icon: "assess-me",
  //     to: "/assess-me",
  //     section: "started",
  //   },
  //   {
  //     display: "Recorded Videos",
  //     icon: "recorded-videos",
  //     to: "/recorded-videos",
  //     section: "started",
  //   },
  //   {
  //     display: "Worksheets",
  //     icon: "worksheets",
  //     to: "/worksheets",
  //     section: "started",
  //   },
  //   {
  //     display: "Mock Tests",
  //     icon: "mocktest",
  //     to: "/mocktest",
  //     section: "started",
  //   },
  //   {
  //     display: "Reports",
  //     icon: "reports",
  //     to: "/reports",
  //     section: "started",
  //   },
  //   // {
  //   //   display: "Settings",
  //   //   icon: "settings",
  //   //   to: "/settings",
  //   //   section: "started",
  //   // },
  // ],

  // New Side nav order 14/04/2023
  NAVIGATION_ITEMS: [
    {
      display: "Dashboard",
      icon: "dashboard",
      to: "/dashboard",
      section: "",
    },
    {
      display: "Live Tuition",
      icon: "live-tuition",
      to: "/live-tuition",
      section: "started",
    },
    {
      display: "Recorded Classes",
      icon: "recorded-videos",
      to: "/recorded-videos",
      section: "started",
    },
    {
      display: "Animated Videos",
      icon: "learn-on",
      to: "/learn-on",
      section: "started",
    },
    {
      display: "Assess Me",
      icon: "assess-me",
      to: "/assess-me",
      section: "started",
    },

    {
      display: "Worksheets",
      icon: "worksheets",
      to: "/worksheets",
      section: "started",
    },
    {
      display: "Reports",
      icon: "reports",
      to: "/reports",
      section: "started",
    },
    // {
    //   display: ASSIGNMENTS,
    //   icon: "reports",
    //   to: "/reports",
    //   section: "started",
    // },
  ],

  // List of partners for user identification
  PARTNERS: {
    DOUBTBOX: "doubtbox",
    GO_SCORE: "goscore",
  },
  // Returns defined xs values, window  for screen responsiveness
  XS_VALUES: {
    WINOW_FLAG_TRUE_NAVBAR: 1.8,
    WINOW_FLAG_FALSE_NAVBAR: 1,
    WINDOW_FLAG_TRUE_OUTLET: 10.2,
    WINDOW_FLAG_FALSE_OUTLET: 11,
  },
  WINDOW_THRESHOLD: 1250,
  HIDE_NAVBAR_THRESHOLD: 550,

  // TIME VALUES
  RESEND_OTP_IN_SECONDS: 30,
  // RESEND_OTP_IN_SECONDS: 3,
  // ASSESSMENT_DURATION: 7200,

  AUTH_CODE_LENGTH: 6,
  PASSWORD_LENGTH: 15,
  MIN_PASSWORD_LENGTH: 6,
  ACADEMIC_DURATION: 10, //In months

  DISCOUNT_PERCENTAGE: 0,

  PRIVACY_POLICY_URL: "https://www.doubtbox.com/privacy-policy.html",
  TERMS_OF_USE_URL: "https://www.doubtbox.com/terms-of-use.html",

  APLPHABETS: ["a.", "b.", "c.", "d.", "e.", "f."],

  MONTH_NAMES_MIN: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  MONTH_NAMES: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  LEARN_ON_STATUS: "learnOnStatus",
  ASSESS_ME_STATUS: "accmeStatus",
  UPCOMING: "upcoming",
  YEARLY: "Annual",
  MONTHLY: "Monthly",
  SUBSCRIPTION: "Subscription",
  TUITION_ROOM: "tuitionRoom",
  CHAT: "chat",
  CHAT_LENGTH: 100,
  MUTE_ALL: "mute_all",
  REQUEST_MEDIA_ACTIVATION: "requestMediaActivation",
  CAMERA: "camera",
  MIC: "microphone",
  FROM_SESSION: "fromSession",
  RECORDINGS: "Recordings",
  VIDEOS: "Videos",
  NCERT: "hasNCERT",
  ASSIGNMENTS : "Assignments",

  // API HTTP CODES AND MESSAGES
  SUCCESS_CODE: 200,
  HTTP_UNAUTHORIZED: 401,
  HTTP_FORBIDDEN: 403,
  LOGIN_SUC_FROM_SERVER: "Login Successfull !",

  //Local storage/cookies keys
  DISCONNECT_SESSION: "disconnectSession",
  ASSESSMENT: "assessment",
  ASSESSMENT_CLOSE: "assessment_close",
  ASSESSMENT_RESULT: "assessment_result",

  // Token key values
  X_ACCESS_TOKEN: "x-access-token",
  X_SECRET_TOKEN: "x-secret-token",
  AUTH_TOKEN_TIME: "auth-token-saved-timestamp",
  AUTH_TIME_BUFFER: 600000, //10 Minutes

  // Used in assessment page for showing Custom question type name.
  QUESTION_TYPES: {
    MCQ: "Multiple Choice Questions",
    TrueORFalse: "True or False",
    OneWordQuestion: "One Word Question",
    FillInTheBlanks: "Fill In the Blanks",
    MatchTheFollowing: "Match the Following",
  },
  QUESTION_TYPES_REFER: {
    MCQ: "MCQ",
    TrueORFalse: "TrueORFalse",
    OneWordQuestion: "OneWordQuestion",
    FillInTheBlanks: "FillInTheBlanks",
    MatchTheFollowing: "MatchTheFollowing",
  },

  MORE_DETAILS: "More Details",
  JOIN_NOW: "Join Now",
  FREE_SESSION: "freeSession",
  HR_ACCEPTED: "hrRequest_accepted",

  // Hardcoded subject ids required to resolve EVS issues
  // EVS_ID: 98,
  // EVIRONMENTAL_STUDIES: 3,

  LOCALHOST: "http://localhost/",
  DEV_LIVE_URL: "https://devlive.doubtbox.com/",
  LEARN_URL: "https://learn.doubtbox.com/",
  CLASSPLUSS_APP_URL:"https://tarly.page.link/axgh",
  LIVE_HOSTNAME: "live.doubtbox.com",
  GOSCORE_HOSTNAME: "goscore.doubtbox.com",

  PORDUCTION_BASE_URL: "https://5bo29rrvuf.execute-api.ap-south-1.amazonaws.com/prod/api/",
  DEV_BASE_URL: "https://6piaqr032a.execute-api.eu-west-1.amazonaws.com/dev/api/",
};
// taken dynamically from responses
export const ERROR_CODES: ErrorCodes = {
  AgoraRTCException: "AG100",
  NotAllowedError: "AG101",
  UID_CONFLICT: "AG102",
};

export const getBaseUrl = () => {
  let url;
  let hostName = window.location.hostname;
  console.log(hostName);
  switch (process.env.NODE_ENV) {
    case "production":
      if (
        hostName === constants.LIVE_HOSTNAME ||
        hostName === constants.GOSCORE_HOSTNAME
      )
        url = constants.PORDUCTION_BASE_URL; //production server
      else
        url = constants.DEV_BASE_URL; // dev server

      break;
    case "development":
    default:
      url = constants.DEV_BASE_URL; // dev server
    //⚠️ For connecting to production environment from local machine. use with caution ⚠️
    // url =  constants.PORDUCTION_BASE_URL;
  }
  return url;
};
