/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import { Grid, Typography } from "@mui/material";
import Navbar from "../../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import "../liveTuition.scss";
import LoaderComponent from "../../../shared/loader/loader";
import {
  getWindowDimensions,
  getxsValue,
  imageReplace,
} from "../../../shared/utils/utilityFunctions";
import thankyou from "../../../assets/images/thankyou.png";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";

/** Actual LiveTuitionThankyou function starts here **/
export function LiveTuitionThankyou() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stateData, setStateData] = useState(location?.state);
  const [purchased, setPurchased] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    userName: "",
    name: "",
    profile_image:""
  });
  const [loader, setLoader] = useState(true);

  //   setTimeout(() => {
  //     navigateTo("/live-tuition");

  //   }, 5000);

  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  function navigateTo(path: string) {
    navigate(path);
  }

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };

  return (
    <Grid container style={{ maxWidth: "98vw" }}>
      {loader && <LoaderComponent />}

      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"live-tuition"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <TopBarComponent search={true} logo={false} profileData={profileData} />
        <Grid container xs={12}>
          <div className="settingsContainer">
            <img src={thankyou} alt="Thankyou" width={450} />
          </div>
          <div className="settingsContainer">
            <div>
              <h1 className="confirmation-header">
                Thank you for your purchase!
              </h1>
              <p className="confirmation-text">
                Your order has been confirmed.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
