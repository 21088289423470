/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna <rahul.krishna@doubtbox.com>, November 2022\
 * Api Services page
 * */

/**
* This file contains the service functions for the API endpoints.
  These functions handle the business logic and data manipulation
  required for each API route. They are responsible for processing
  requests, interacting with the database or other external services,
  and returning the appropriate responses.

* It is recommended to keep each service function focused on a specific
  endpoint or a related set of functionalities. This promotes modularity,
  reusability, and easier maintenance of the codebase.
  
*/
import { callApi } from "../middleware/api.middleware";
import { constants, getBaseUrl } from "../constants/constants";
import { routes } from "../services/routes";
import axios from "axios";
import { configurations } from "../shared/utils/configurations";

export const services = {
  getSyllabus() {
    // fetching syllabus from admin portal api

    return callApi("get", getBaseUrl() + routes.GET_SYLLABUS, null);
  },
  getClass(syllabusId: string) {
    // changed from api to hardcoded data.
    // This Hardcoded data returns the original list of classess from getClass api for the
    // syllabus id 1 (CBSE) This may cause some unexpected problems down the line and
    // The management is aware of this and are willing to take the risk.

    // To revert uncomment the commented code and comment the promise

    // fetching class from admin portal api
    // return callApi(
    //   "get",
    //   getBaseUrl() + routes.GET_CLASSES + "?id=" + syllabusId,
    //   null
    // );

    return new Promise((resolve: Function) => {
      resolve({ data: configurations.CLASS_LIST });
    });
  },
  getSubjects(syllabusId: string, standardId: string) {
    // fetching subjects from admin portal api
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_SUBJECTS +
        "?id=" +
        syllabusId +
        "&standardId=" +
        standardId,
      null
    );
  },
  getChapter(apiData: any) {
    let url =
      getBaseUrl() +
      routes.GET_CHAPTERS +
      "?id=" +
      apiData.syllabusId +
      "&user=" +
      apiData.userName +
      "&standardId=" +
      apiData.standardId +
      "&mobile=" +
      apiData.mobile +
      "&subject=" +
      apiData.subject;

    if (apiData.source) url = url + `&op=${apiData.source}`;

    return callApi("get", url, null);
  },

  // Authentication

  login(user: any) {
    return callApi("post", getBaseUrl() + routes.LOGIN, user);
  },
  generateLoginOtp(apiData: any) {
    return callApi(
      "put",
      getBaseUrl() + routes.GENERATE_LOGIN_OTP + "?mobile=" + apiData.mobile
    );
  },
  verifyLoginOtp(apiData: any) {
    return callApi(
      "put",
      getBaseUrl() +
        routes.VERIFY_LOGIN_OTP +
        "?mobile=" +
        apiData.mobile +
        "&otp=" +
        apiData.otp
    );
  },
  generateSignupOtp(apiData: any) {
    return callApi("post", getBaseUrl() + routes.GENERATE_SIGNUP_OTP, apiData);
  },
  verifySignupOtp(apiData: any) {
    return callApi(
      "put",
      getBaseUrl() +
        routes.VERIFY_LOGIN_OTP +
        "?mobile=" +
        apiData.mobile +
        "&otp=" +
        apiData.otp
    );
  },
  resendOtp(apiData: any) {
    return callApi(
      "put",
      getBaseUrl() + routes.RESEND_OTP + "?mobile=" + apiData
    );
  },
  setPassword(apiData: any) {
    return callApi("post", getBaseUrl() + routes.SET_PASSWORD, apiData);
  },
  updatePassword(apiData: any) {
    return callApi("post", getBaseUrl() + routes.UPDATE_PASSWORD, apiData);
  },

  // Userdata and profile

  saveLearnerProfile(profile: any) {
    return callApi("post", getBaseUrl() + routes.USER_SIGNUP, profile);
  },
  getLearnerProfile(mobile: any) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_PROFILE + "?mobile=" + mobile
    );
  },

  // Learn on
  getVideoTree(chapterId: any) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_VIDEO_TREE + "?chapter=" + chapterId
    );
  },
  getSignedUrl(media_uuid: string) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_SIGNED_URL + "?media_uuid=" + media_uuid
    );
  },
  getContentListing(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_CONTENT_LISTING +
        "?chapter=" +
        apiData.chapter +
        "&content_type=" +
        apiData.contentType +
        "&pgtype=1&" +
        "standard=" +
        apiData.classId +
        "&subject=" +
        apiData.subject +
        "&syllabus=" +
        apiData.syllabusId +
        "&textbook=" +
        apiData.textbook
    );
  },

  updateProfile(apiData: any) {
    return callApi(
      "post",
      getBaseUrl() + routes.UPDATE_STUDENT_PROFILE,
      apiData
    );
  },
  // Assess Me

  getPracticeTest(apiData: any) {
    return callApi("post", getBaseUrl() + routes.GET_PRACTICE_TEST, apiData);
  },
  submitAnswer(apiData: any) {
    return callApi("put", getBaseUrl() + routes.SUBMIT_ANSWER, apiData);
  },
  finishExam(apiData: any) {
    return callApi("put", getBaseUrl() + routes.FINISH_EXAM, apiData);
  },

  // Dashboard
  getStudentDashboard(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_DASHBOARD +
        "?id=" +
        apiData.syllabusId +
        "&standardId=" +
        apiData.standardId +
        "&user=" +
        apiData.user
    );
  },
  getDashAssessMe(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_DASHBOARD_ASSESSME +
        "?id=" +
        apiData.syllabusId +
        "&standardId=" +
        apiData.standardId +
        "&user=" +
        apiData.user
    );
  },
  // Scratch Card
  activateScratchcard(apiData: any) {
    return callApi("post", getBaseUrl() + routes.VALIDATE_COUPON, apiData);
  },

  // LIVE TUITION
  getCourses(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_COURSES +
        "?user=" +
        apiData.user +
        "&standardId=" +
        apiData.standardId +
        "&syllabusId=" +
        apiData.syllabusId
    );
  },
  getMyLiveTuitions(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.MY_LIVE_TUITIONS +
        "?userName=" +
        apiData.user +
        "&standardId=" +
        apiData.standardId +
        "&id=" +
        apiData.syllabusId
    );
  },

  setFreetrialFLag(apiData: any) {
    return callApi(
      "put",
      getBaseUrl() +
        routes.UPDATE_FLAG +
        "?mobile=" +
        apiData.mobile +
        "&userName=" +
        apiData.userName
    );
  },

  getCourseById(courseId: number, userName:string,name:string) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_COURSE_BY_ID + `?courseId=${courseId}&user=${userName}&Name=${name}`
    );
  },
  getBatchesByCourseId(courseId: number) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_BATCHES + "?courseId=" + courseId
    );
  },
  getSessionByBatchId(batchId: string) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_SESSIONS + "?batchId=" + batchId
    );
  },
  buyCourse(apiData: any) {
    return callApi("post", getBaseUrl() + routes.BUY_COURSE, apiData);
  },
  getUpcommingSessions(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_UPCOMING_SESSIONS +
        "?userName=" +
        apiData.user +
        "&standardId=" +
        apiData.standardId +
        "&id=" +
        apiData.syllabusId
    );
  },
  getCompletedSessions(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_COMPLETED_SESSIONS +
        "?userName=" +
        apiData.user +
        "&standardId=" +
        apiData.standardId +
        "&id=" +
        apiData.syllabusId
    );
  },
  getNonPurchasedSessions(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_NON_PURCHASED_SESSIONS +
        "?userName=" +
        apiData.user +
        "&standardId=" +
        apiData.standardId +
        "&id=" +
        apiData.syllabusId
    );
  },
  getPaymentUrl(apiData: any) {
    return callApi(
      "get",
      getBaseUrl() +
        routes.GET_PAYMENT_URL +
        "?zohoCustomerId=" +
        apiData.zohoCustomerId +
        "&courseId=Course~" +
        apiData.courseId +
        "&batchId=Batch~" +
        apiData.batchId +
        "&SubscriptionType=" +
        apiData.subscriptionType +
        "&name=" +
        apiData.name +
        "&courseName=" +
        apiData.courseName+
        "&price=" +
        apiData.price
    );
  },

  getReport(userName: string) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_REPORT + "?user=" + userName
    );
  },

  getOrders(userName: string) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_ORDERS + "?user=" + userName
    );
  },

  // RECORDINGS
  getRecordingsTree(chapterId: any) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_RECORDINGS_TREE + "?chapter=" + chapterId
    );
  },
  // WORKSHEETS

  getWorkSheetsList(chapterId: any) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_WORKSHEETS_LIST + "?chapter=" + chapterId
    );
  },

  //LIVE TUITION ROOM
  fetchAgoraAppId() {
    return callApi("get", getBaseUrl() + routes.GET_VIDEO_APPID);
  },
  getSessionById(sessionId: string) {
    return callApi(
      "get",
      getBaseUrl() + routes.GET_SESSION_BY_ID + "?sessionID=" + sessionId
    );
  },

  getChatData(sessionId: string) {
    return callApi("get", getBaseUrl() + routes.GET_CHATS + sessionId);
  },
  getLastAsmntQnWithSessionId(sessionId: string) {
    return callApi("get", getBaseUrl() + routes.GET_POLLS + sessionId);
  },
  submitLiveAssessment(apiData: any) {
    return callApi("post", getBaseUrl() + routes.SUBMIT_ANSWER_QUIZ, apiData);
  },
  getLiveAssessmentResult(sessionId: string) {
    return callApi("get", getBaseUrl() + routes.FETCH_POLL_RESULT + sessionId);
  },

  async tmporarilyCreateAgoraRoom() {
    var options = {
      method: "POST",
      url: "https://api.netless.link/v5/rooms",
      headers: {
        token:
          "NETLESSSDK_YWs9cVpTaElNX1BzdjNqNnFGUCZub25jZT1lODIzNTk5MC1jNDk3LTExZWQtOTkzZC1jYmRkZjk2MmI2YWQmcm9sZT0wJnNpZz03NDgyM2Q2MWE2NmQ2MTVmMzc5ODAyODMzYTlhMWM5ZjU3YzgzNGVlYjIxYzhjMGFiZmFlMTIxNGRmZTc5OGZh",
        "Content-Type": "application/json",
        region: "us-sv",
      },
      body: JSON.stringify({
        isRecord: false,
      }),
    };
    var request = {
      cache: false,
      headers: {
        token:
          "NETLESSSDK_YWs9cVpTaElNX1BzdjNqNnFGUCZub25jZT1lODIzNTk5MC1jNDk3LTExZWQtOTkzZC1jYmRkZjk2MmI2YWQmcm9sZT0wJnNpZz03NDgyM2Q2MWE2NmQ2MTVmMzc5ODAyODMzYTlhMWM5ZjU3YzgzNGVlYjIxYzhjMGFiZmFlMTIxNGRmZTc5OGZh",
        "Content-Type": "application/json",
        region: "us-sv",
      },
    };
    let returnResult = {
      roomDetails: { appUUID: "", uuid: "" },
      roomToken: "",
    };
    await axios
      .post("https://api.netless.link/v5/rooms", options.body, request)
      .then(async (res) => {
        console.log(res);
        let body = JSON.stringify({ lifespan: 3600000, role: "admin" });
        returnResult.roomDetails = res.data;
        await axios
          .post(
            "https://api.netless.link/v5/tokens/rooms/" + res.data.uuid,
            body,
            request
          )
          .then((finalRes) => {
            console.log(finalRes);
            returnResult.roomToken = finalRes.data;
          });
      });
    if (returnResult.roomToken && returnResult.roomDetails) {
      console.log(returnResult);
      return returnResult;
    }
  },
};
