import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";

export function ReportsFIB(props: any) {
  var count = props.question.q.match(/\[SPACE]/g).length;
  const [questionWithBlanks, setQuestion] = useState(props.question.q);
  var newQuestion = props.question.q;

  function createMarkup(value: string, qn?: any) {
    // replacing [SPACE] with custom html input
    for (let i = 0; i < count; i++) {
      newQuestion = newQuestion.replace(
        "[SPACE]",
        '&nbsp;&nbsp;<input type="text" id="fibInput' +
          i +
          '" style="outline: 0;border-width: 0 0 2px"/>&nbsp;&nbsp;'
      );
    }
    if (qn) {
      return {
        __html: qn + ") <br />" + newQuestion,
      };
    } else
      return {
        __html: newQuestion,
      };
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(
            questionWithBlanks,
            props.question.qn
          )}
        ></Grid>
        {props.rightAnswer ? (
          <Grid xs={10.4} className="question">
            {props.question.a.map((one: any) => (
              <span>{one}</span>
            ))}{" "}
            &nbsp; ✔
          </Grid>
        ) : props.question.a.length === 1 ? (
          <Grid xs={10.4} className="question">
            <span style={{ color: "red", fontSize: "28px" }}>
              {props.selectedanswer} &nbsp; ✘
            </span>
            <br />
            Correct Answer is : &nbsp;
            {props.question.a.map((one: any) => (
              <span>{one}</span>
            ))}
          </Grid>
        ) : (
          <Grid xs={10.4} className="question">
            <span style={{ color: "red", fontSize: "28px" }}>✘</span>
            <br />
            Correct Answers are : &nbsp;
            {props.question.a.map((one: any) => (
              <span>{one},</span>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
          <span
            className="nextButton"
            style={{ padding: "11px" }}
            onClick={() => props.backToReports()}
          >
            Back to Reports
          </span>
        </Grid>

        <Grid container item xs={1.5}>
          {props.qnIndex > 1 ? (
            <span
              className="nextButton previous"
              onClick={() => props.changeQuestion(false)}
            >
              Previous
            </span>
          ) : (
            <span className="nextButton disabled">Previous</span>
          )}
        </Grid>
        <Grid container item xs={2}>
          {props.qnIndex + 1 < props.total ? (
            <span
              className="nextButton"
              onClick={() => props.changeQuestion(true)}
            >
              Next
            </span>
          ) : (
            <span className="nextButton disabled">Next</span>
          )}
        </Grid>
      </Grid>
    </>
  );
}
