import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import "../../learnOn/LearnOn.scss";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import { getFromLocalStorage } from "../../../shared/utils/utilityFunctions";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import swal from "sweetalert";
import { constants } from "../../../constants/constants";

export function RecordedVideoContents() {
  const location = useLocation();
  console.log(location.state);
  const [tree, setTree] = useState(location.state.Tree);
  const [contentData, setContentData] = useState(
    location.state.proprietaryData
  );
  const [contentType, setContentType] = useState("Recordings");
  const [isPlaying, setPlaying] = useState(true);

  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
  });
  const [listData, setListData] = useState([
    { content_orginalname: "", content_uuid: "" },
  ]);

  // for alert messaging

  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const selectedChapter = getFromLocalStorage("selectedChapter");
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    // setAlertShow(false);
  };
  const [loader, setLoader] = useState(true);

  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }
  const [dataUrl, setDataUrl] = useState("");

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  console.log(hideNavBar);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    validateLoggedIn();
  }, []);
  useEffect(() => {
    setListByLooping();
  }, []);

  useEffect(() => {
    selectContent(listData[0].content_uuid);
  }, [listData]);

  useEffect(() => {
    setPlaying(true);
    console.log("dataUrl", dataUrl);
  }, [dataUrl]);

  function setListByLooping() {
    let tempArr = [];
    for (let i = 0; i < tree.length; i++) {
      let branch = tree[i];
      console.log(branch);
      for (let j = 0; j < branch[contentType].length; j++) {
        let leaf = branch[contentType][j];

        tempArr.push(leaf);
      }
    }
    console.log(tempArr);
    if (tempArr.length === 0)
      return swal({
        title: "No Videos Found!",
        icon: "info",
      }).then(() => {
        navigateTo("/recorded-videos"); // this should execute now
      });
    tempArr.sort(function (a, b) {
      let nameArrayforA = a.content_orginalname.split('-')
      a.timestamp = nameArrayforA[0]
      let nameArrayforB = b.content_orginalname.split('-')
      b.timestamp = nameArrayforB[0]
      return parseInt(a.timestamp) - parseInt(b.timestamp);
    });
    setListData(tempArr);

  }
  const navigate = useNavigate();

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }

  function selectContent(selectedData: string) {
    setLoader(true);
    console.log(selectedData);
    if (selectedData) {
      services.getSignedUrl(selectedData).then((res) => {
        setLoader(false);
        if (res.failed) return showAlert("error", res.message);
        else {
          console.log(res.data.data.url);
          setDataUrl(res.data.data.url);
        }
      });
    }
  }
  function goBack() {
    console.log(contentData)
    let state = { sub: contentData.subject, name: contentData.subjectName };

    navigate("/recorded-videos", { state: state });
  }

  const noContext = document.getElementById("noContextMenu");
  if (noContext && noContext != null)
    noContext.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  return (
    <div className="SettingsProfileContainer">
      {loader && <LoaderComponent />}
      <TopBarComponent search={false} logo={true} profileData={profileData} />
      <Grid container className="contentsPageLayout">
        {hideNavBar && (
          <Grid xs={12}>
            <ReactPlayer
              className="react-player"
              url={dataUrl}
              width="100%"
              height="auto"
              controls
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
              playing={isPlaying}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4} className="p-14">
          <Grid className="learnOnLayout1  p-14">
            <Grid container style={{ width: "25vw" }}>
              <Grid
                item
                xs={12}
                md={3}
                onClick={goBack}
                className="learnOnChapter"
              >
                Chapter :
              </Grid>
              <Grid item xs={12} md={9} className="learnOnChapterName">
                {selectedChapter}
              </Grid>
            </Grid>
            <Grid className="contentList hideScrollBar">
              {listData.map((content: any) => (
                <ContentItem
                  key={content.content_uuid}
                  value={content}
                  selectContent={selectContent}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={11} sm={7.5} className="contentContainer">
          {!hideNavBar && (
            <Grid xs={12}>
              <ReactPlayer
                className="react-player"
                id="noContextMenu"
                url={dataUrl}
                width="100%"
                height="auto"
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                playing={isPlaying}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function ContentItem(props: {
  key: string;
  value: {
    content_uuid: string;
    content_orginalname: string;
    TopicID: number;
    topicName: string;
    TopicNumber: number;
  };
  selectContent: any;
}) {
  //Content name from backend is like "1677484498-Decomposition Reaction.mp4" and cannot be renamed,
  //so trimming unwanted characters.
  // let contentName = props.value.content_orginalname.split("-")[1];
  let contentName = props.value.content_orginalname.substring(11);
  return (
    <Grid
      container
      className="eachContentCard"
      onClick={() => props.selectContent(props.value.content_uuid)}
    >
      <Grid item xs={1} md={1} className="eachContentL1">
        <OndemandVideoIcon />
      </Grid>
      <Grid item xs={11} md={11} className="eachContentL2">
        <div className="eachContentL2TopicName">
          {contentName?.replace(".mp4", "")}
        </div>
        {/* <div className="eachContentL2Duration">03:15 min</div> */}
      </Grid>
    </Grid>
  );
}
