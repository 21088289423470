/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Navbar from "../../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import "../liveTuition.scss";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import swal from "sweetalert";
import { messageUtil } from "../../../shared/utils/messages";
import icon from "../../../assets/images/books.jpg";
import {
  checkOrientation,
  getFromLocalStorage,
  getWindowDimensions,
  getxsValue,
  imageReplace,
  setToLocalStorage,
} from "../../../shared/utils/utilityFunctions";
import { TeacherDetailsModal } from "../../../components/uiComponents/modals";
import { userData } from "../../../constants/tempDatas";
function createMarkup(value: string) {
  return {
    __html: value,
  };
}

/** Actual LiveTuitionDetailsPage function starts here **/
export function LiveTuitionDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stateData, setStateData] = useState(location?.state);
  const [purchased, setPurchased] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [purchaseUrl, setPurchaseUrl] = useState("");
  const [itemInCart, setItemInCart] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    userName: "",
    name: "",
    zohoCustomerId: "",
    profile_image: ""
  });
  const [courseData, setCourseData] = useState({
    course_name: "",
    subject_name: "",
    room_size: 0,
    course_name_trimmed: "",
    aliasSubject: "",
    package_name: "",
    price: "0",
    course_description: "",
    image_file_path: "",
    course_id: "",
    content_uuid: ""
  });
  const [batchData, setBatchData] = useState([
    {
      start_date: "",
      end_date: "",
      sessionData: [],
      BatchId: "",
    },
  ]);
  const [loader, setLoader] = useState(true);
  const [sessions, setSessions] = useState([{ mst_session_batch_id: "", teacher_name: "" }]);

  function handleClickBc(prop: string) {
    navigateTo("/live-tuition");
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => handleClickBc("stage1")}
    >
      Live Tuition
    </Link>,
    <Typography key="3" color="text.primary">
      {courseData.aliasSubject
        ? courseData.course_name_trimmed
        : courseData.course_name}
    </Typography>,
  ];
  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [sessions]);

  useEffect(() => {
    let cartDetails = getFromLocalStorage('cartDetails')
    for (let i = 0; i < cartDetails.length; i++) {
      let elem = cartDetails[i]
      if (elem.content_uuid === courseData.content_uuid)
        setItemInCart(true)
    }
  }, [courseData]);
  useEffect(() => {
    console.log(location.state)
    setStateData(location.state)
  }, [location.state]);
  useEffect(() => {
    function fetchCourseDetails() {
      setLoader(true);

      Promise.all([
        services.getCourseById(courseId, profileData.userName, profileData.name),
        services.getBatchesByCourseId(courseId),
      ])
        .then(function (responses) {
          let responseOfgetCourseById = responses[0];
          let responseOfgetBatchesByCourseId = responses[1];
          console.log(responseOfgetCourseById);
          if (
            responseOfgetCourseById.data.hasOwnProperty("message") &&
            responseOfgetCourseById.data.message === "Course got successfull !"
          ) {
            let crseData = responseOfgetCourseById.data.data;
            let crseNameArr 
            if(crseData && crseData.course_name !== undefined &&crseData.hasOwnProperty("course_name")){
              crseNameArr = crseData.course_name
              ? crseData.course_name?.split("~")
              : "course";
            }
          
            if (crseNameArr && crseNameArr.length > 1) {
              crseData["course_name_trimmed"] = crseNameArr[0];
              crseData["aliasSubject"] = crseNameArr[1];
            }
            setCourseData(crseData);
            setPurchased(responseOfgetCourseById.data.isPurchased)
            if (
              responseOfgetCourseById.data.hasOwnProperty("message") &&
              responseOfgetCourseById.data.message ===
              "Course got successfull !"
            ) {
              setBatchData(responseOfgetBatchesByCourseId.data.data);
            }
          }
          // setSubjectList(responseOfgetCourseById.data);
        })
        .catch((e) => {
          console.error(e);
          swal("error", messageUtil.SOMETHING_WENT_WRONG);
        });
    }
    let courseId = stateData;
    console.log(courseId)
    if (courseId && profileData.userName && profileData.name) fetchCourseDetails();
  }, [profileData.userName]);

  useEffect(() => {
    console.log("fetching session...");
    console.log(batchData);
    setSessions([{ mst_session_batch_id: "", teacher_name: "" }]);
    if (batchData && batchData[0] && batchData[0].BatchId) {
      services.getSessionByBatchId(batchData[0].BatchId).then((res: any) => {
        setLoader(false);
        console.log(res.data.data);
        let sessionsUnSorted = res.data.data;
        let sessionSorted = sessionsUnSorted.sort(function (x: any, y: any) {
          return x.SessionStartTime - y.SessionStartTime;
        });
        batchData[0].sessionData = sessionSorted;
        setSessions(sessionSorted);
      });
    }
  }, [batchData]);
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  function navigateTo(path: string) {
    navigate(path);
  }

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };

  function getSessions(BatchId: string, index: number) {
    if (!batchData[index].sessionData) {
      setLoader(true);
      services
        .getSessionByBatchId(BatchId)
        .then((res: any) => {
          setLoader(false);
          console.log(res.data.data);
          let sessionsUnSorted = res.data.data;
          let sessionSorted = sessionsUnSorted.sort(function (x: any, y: any) {
            return x.SessionStartTime - y.SessionStartTime;
          });
          console.log(sessionSorted);
          batchData[index].sessionData = sessionSorted;
          setBatchData(batchData);
          setSessions(sessionSorted);
        })
        .catch((e) => {
          console.error(e);
          swal("error", messageUtil.SOMETHING_WENT_WRONG);
        });
    } else {
      setSessions(batchData[index].sessionData);
    }
  }
  function buyNow(param: string) {
    setLoader(true);
    let apiData = {
      courseId: courseData.course_id,
      batchId: batchData[0].BatchId,
      userName: profileData.userName,
      name: profileData.name,
    };
    if (param === "add") {
      services.buyCourse(apiData).then((res) => {
        setLoader(false);

        if (res.failed) {
          swal("error", res.message);
        } else {
          swal("success", res.message);
          setPurchased(true);
        }
      });
    } else {
      setLoader(false);

      // Buy now api should be implemented here
    }
  }
  const [open, setOpen] = React.useState(false);
  const [openTeacherDetails, setTeacherDetails] = React.useState(false);
  const handleOpenTeacher = () => setTeacherDetails(true);
  const handleClose = () => {
    setOpen(false);
    setTeacherDetails(false);
  };

  function startPurchase(prop: string) {
    console.log(prop);
    setLoader(true);
    let price = parseInt(courseData.price);

    let apiData = {
      zohoCustomerId: profileData.zohoCustomerId,
      user: profileData.userName,
      courseId: courseData.course_id,
      batchId: sessions[0].mst_session_batch_id,
      // price: prop === constants.MONTHLY?  price/10 : price,
      price: price,
      subscriptionType:
        prop === constants.MONTHLY ? constants.MONTHLY : constants.YEARLY,
      name: profileData.name,
      courseName: courseData.course_name,
    };
    console.log(apiData);
    services.getPaymentUrl(apiData).then((res) => {
      setLoader(false);
      if (res.failed) {
        swal("error", res.message);
      } else {
        console.log(res.data.data.hostedpage.url);
        setPurchaseUrl(res.data.data.hostedpage.url);
      }
    });
    setShowPaymentOption(true);
  }
  function cancel() {
    setShowPaymentOption(false);
  }
  function addToCart() {
    console.log('Item added to cart')
    let cartDetails = getFromLocalStorage('cartDetails');
    // cartDetails.push({
    //   courseId: courseData.course_id,
    //   batchId: batchData[0].BatchId,
    //   userName: profileData.userName,
    //   name: profileData.name,
    // })

    const uniqueTeachers = new Set();
    console.log(sessions)
    // Iterate through the sessions array
    for (const oneSession of sessions) {
      // Add each teacher name to the Set (duplicates will be ignored by the Set)
      uniqueTeachers.add(oneSession.teacher_name);
    }
    // Convert the Set back to an array (if needed)
    const uniqueTeacherNames = Array.from(uniqueTeachers);
    // const uniqueTeacherNames = ['Akhil Sir', 'Jeeja Mohan', 'Mukul Shaji Das']
    let copyObj = JSON.parse(JSON.stringify(courseData))
    copyObj.teacherArray = uniqueTeacherNames
    copyObj.batchDetails = batchData[0]
    cartDetails.push(copyObj)
    setToLocalStorage('cartDetails', cartDetails)
    console.log(cartDetails)
    setItemInCart(true)
  }
console.log(courseData.room_size)
  return (
    loader ? <LoaderComponent /> :
      <Grid container style={{ maxWidth: "100vw" }} className="fade-in-animate">
        <TeacherDetailsModal
          open={openTeacherDetails}
          setOpen={setOpen}
          // handleOpen={handleOpen}
          handleClose={handleClose}
        />
        <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
          <Navbar path={"live-tuition"} />
        </Grid>
        {!showPaymentOption ? (
          <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
            <TopBarComponent
              search={true}
              logo={false}
              profileData={profileData}
            />
            <Grid container>
              <Grid item sm={12}>
                <Breadcrumbs
                  separator="›"
                  aria-label="breadcrumb"
                  style={{ marginTop: "28px", marginLeft: "25px" }}
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Grid>
              <Grid container item xs={12}>
                <Grid sm={12} item container>
                  <Grid
                    item
                    container
                    md={8.5}
                    xs={12}
                    className="courseNameContainer"
                    style={{ marginTop: "15px" }}
                    >
                    <Grid item md={8} xs={12}>
                      <div className="labels sub">
                        {courseData.subject_name === "Science"
                          ? courseData.subject_name +
                          " (" +
                          courseData.aliasSubject +
                          ") "
                          : courseData.subject_name}{" "}
                      </div>
                      <div className="labels course">
                        {courseData.course_name_trimmed
                          ? courseData.course_name_trimmed
                          : courseData.course_name}{" "}
                      </div>
                      <div className="labels date">
                        {batchData[0]?.start_date ? batchData[0]?.start_date : 'Nil'} -{" "}
                        {batchData[batchData.length - 1]?.end_date}
                      </div>
                    </Grid>
                    <Grid item md={4}xs={12}>
                      <img
                        src={
                          courseData.image_file_path
                            ? imageReplace(courseData.image_file_path)
                            : icon
                        }
                        alt="Logo"
                        height={120}
                        width={250}
                        style={{ marginTop: 10, borderRadius: 8 }}
                      />
                    </Grid>
                  </Grid>
                 
                  {(typeof courseData.room_size === "number") && courseData.room_size===0 && !purchased?
                  <Grid
                  container
                  item
                  md={3}
                  xs={12}
                  className="priceContainer center"
                  style={{ marginLeft: "15px", marginTop: "15px" }}
                  spacing={1}
                 >
                  Selected batch is full!
                </Grid>:
                  <Grid
                    container
                    item
                    md={3}
                    xs={12}
                    className="priceContainer"
                    style={{ marginLeft: "15px", marginTop: "15px" }}
                    spacing={1}
                   >
                    {/* change price to 0 */}
                    {!purchased && courseData.price === "0" ? (
                      // <Grid
                      //   className="buyNow"
                      //   item
                      //   xs={12}
                      //   onClick={() => buyNow("add")}
                      // >
                      //   Add to my account
                      // </Grid>
                      <Grid item xs={12}>
                        {" "}

                        <div
                          className="style_vsc-courses-buy-now addCartButton"
                          onClick={() => buyNow("add")}
                        >
                          Add to my account
                        </div>
                      </Grid>
                    ) : !purchased ? (
                      <>
                        <Grid container item xs={12}>
                          <Grid item>
                            {" "}
                           { <span className="buyPrice">
                              &nbsp;₹
                              {parseInt(courseData.price) -
                                (parseInt(courseData.price) *
                                  constants.DISCOUNT_PERCENTAGE) /
                                100}
                            </span>}
                          </Grid>
                          {constants.DISCOUNT_PERCENTAGE ===0?<div></div>:
 <Grid item xs={6}>
 {" "}
 <div className="style_discount-detail-section">
   <span className="style_off-perc">
     &nbsp;{constants.DISCOUNT_PERCENTAGE}% Off
   </span>
   <span className="style_cut-price">
     ₹{courseData.price}
   </span>
 </div>
</Grid>
                          }
                         
                          <Grid item xs={12}>
                            {" "}
                            <div
                              className="style_vsc-courses-buy-now"
                              onClick={() => startPurchase(constants.YEARLY)}
                            >
                              Buy Now
                            </div>
                          </Grid>
                        </Grid>
                        {/* For resolving some UI issues in mobile and desktop screens */}

                        {!checkOrientation() && (
                          <Grid container item xs={12}>
                            <Grid item>
                              {" "}
                              &nbsp;
                              <span className="buyPrice">
                                ₹
                                {parseInt(courseData.price) /
                                  constants.ACADEMIC_DURATION}
                              </span>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: "10px" }}>
                              {" "}
                              <span className="labels">&nbsp;/ Month</span>
                            </Grid>

                            <Grid item xs={12}>
                              {" "}
                              <div
                                className="style_vsc-courses-buy-now"
                                onClick={() => startPurchase(constants.MONTHLY)}
                              >
                                Buy Now
                              </div>
                            </Grid>
                          </Grid>
                        )} 
                      </>
                    ) : (
                                  <div
                        className="style_vsc-courses-buy-now existInCartButton"
                      >
                        Purchased ✔
                      </div>
                    )}
                  </Grid>
                  }
                </Grid>

                <Grid sm={12} item container>
                  <Grid item xs={12} sm={8}  style={{ marginTop: "15px", marginLeft:"25px" }}>
                    {sessions.map((session: any, index: number) => (
                      <Accordion
                        onChange={(e, expanded) => {
                          if (expanded) {
                            // accordionOpened(session.BatchId, index);
                            // can implement a logic if reqquired.
                          }
                        }}
                        className="sessionAccordian"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="labels sessionName">
                            {session.chapter_name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} container>
                            <Grid item xs={12}>
                              <Typography className="labels sessionDate">
                                {session.session_date} {session.session_day}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Divider variant="middle" />
                          <Grid item xs={12} container style={{ padding: "8px" }}>
                            <Grid container item xs={10}>
                              <Grid item xs={12}>
                                <Typography className="labels sessionTime">
                                  {session.from_time} - {session.to_time}{" "}
                                  {session.topic_name
                                    ? "(" + session.topic_name + ")"
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                className="labels TeacherName"
                              // onClick={handleOpenTeacher}
                              >
                                {session.teacher_name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    {!purchased && courseData.price !== "0" && (
                      <Grid
                        container
                        item
                        md={12}
                        xs={12}
                        className="priceContainer"
                        style={{ marginLeft: "15px", marginTop: "15px" }}
                        spacing={1}
                      >
                        {/* For resolving some UI issues in mobile and desktop screens */}
                        {checkOrientation() && (
                          <Grid container item xs={12}>
                            <Grid item>
                              {" "}
                              &nbsp;
                              <span className="buyPrice">
                                ₹
                                {parseInt(courseData.price) /
                                  constants.ACADEMIC_DURATION}
                              </span>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: "10px" }}>
                              {" "}
                              <span className="labels">&nbsp;/ Month</span>
                            </Grid>

                            <Grid item xs={12}>
                              {" "}
                              <div
                                className="style_vsc-courses-buy-now"
                                onClick={() => startPurchase(constants.MONTHLY)}
                              >
                                Buy Now
                              </div>
                            </Grid>
                          </Grid>
                        )}

                        {/* <Grid
                      className="buyNow"
                      item
                      xs={12}
                      onClick={() => startPurchase(constants.MONTHLY)}
                    >
                      Buy for {parseInt(courseData.price)/constants.ACADEMIC_DURATION} /Month
                    </Grid> */}
                      </Grid>
                    )}
                    {courseData.price !== '0' && <Grid
                      xs={12}
                      className="detailsContainer"
                      style={{ marginLeft: "15px", marginTop: "15px" }}
                    >
                      <Grid item xs={12}>
                        {" "}
                        {itemInCart ?
                          <div
                            className="style_vsc-courses-buy-now existInCartButton"
                          >
                            Course added to cart ✓
                          </div> : <div
                            className="style_vsc-courses-buy-now addCartButton"
                            onClick={() => addToCart()}
                          >
                            Add to Cart
                          </div>}
                      </Grid>
                    </Grid>}
                    {/* <Grid
                    xs={12}
                    className="detailsContainer"
                    style={{ marginLeft: "15px", marginTop: "15px" }}
                  >
                    <div className="labels extraDetails">
                      Package : {courseData.package_name}
                    </div>
                    <div className="labels extraDetails">Description </div>
                    <div
                      className="labels descriptionCntr"
                      dangerouslySetInnerHTML={createMarkup(
                        courseData.course_description
                      )}
                    ></div>
                  </Grid> */}
                    <Grid
                      item
                      xs={12}
                      className="detailsContainer"
                      style={{ marginLeft: "40px", marginTop: "15px" }}
                    >
                      <BatchList
                        batchData={batchData}
                        sessions={sessions}
                        getSessions={getSessions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "15px", minHeight: "90vh", marginBottom: 35 }}
            className="hideScrollbar"
          >
            {purchaseUrl && (
              <iframe
                title="none"
                width="100%"
                height="100%"
                src={purchaseUrl}
                style={{ border: "none" }}
              ></iframe>
            )}
            <Grid
              className="buyNow"
              item
              style={{
                marginLeft: "60%",
                marginBottom: 10,
              }}
              onClick={cancel}
            >
              Cancel
            </Grid>
          </Grid>
        )}
      </Grid>
  );
}

function BatchList(props: any) {
  useEffect(() => { }, []);
  return (
    <>
      <div className="labels extraDetails">Batches </div>
      {props.batchData.map((batch: any, index: number) => {
        if (batch.BatchId === props.sessions[0]?.mst_session_batch_id) {
          return (
            <div
              className="batchListSelected"
              onClick={() => props.getSessions(batch.BatchId, index)} key={batch.BatchId}
            >
              {batch.batch_name}
            </div>
          );
        } else {
          return (
            <div
              className="batchList"
              onClick={() => props.getSessions(batch.BatchId, index) }key={batch.BatchId}
            >
              {batch.batch_name}
            </div>
          );
        }
      })}
    </>
  );
}
