/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 * Things to note:
 *
 * Scheduled Live Tuitions: Sessions that are purchased and the students can join when time is up.
 *
 * Available Live Tuitions: Like Recommended session, The user can purchase these sessions and only then they can join to the sessions.
 *
 * Revise : Replay of old sessions are available here.
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Grid } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useNavigate } from "react-router-dom";
import {
  CompletedLiveTuitionCard,
  LiveTuitionCard,
  LiveTuitionCommonCard,
} from "../../components/uiComponents/uiComponents";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import "./liveTuition.scss";
// import LiveTuitionMoreDetailsModal, {
//   TeacherDetailsModal,
// } from "../../components/uiComponents/modals";
import { services } from "../../services/api_service";
import LoaderComponent from "../../shared/loader/loader";
import {
  getButtonValue,
  getFromLocalStorage,
  getWindowDimensions,
  getxsValue,
  setFlagForPurchased,
  setToLocalStorage,
} from "../../shared/utils/utilityFunctions";
import { TuitionRoomInterface } from "../../constants/interfaces";
import swal from "sweetalert";

/** Actual Live Tuition page function starts here **/
export function LiveTuitionPage() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [items] = React.useState([{ id: "1" }, { id: "2" }, { id: "3" }]);
  const [profileData, setProfileData] = useState({
    userName: "",
    standard: "",
    syllabus: "",
    syllabusName: "",
    profile_image:"",
    name:"",
    hasTrialSession:false,
  });
  const [userData, setUserData] = useState({
    mobile: "",
    hasPass: false,
    hasPassString: 'loading',
  });
  const [upcommingSession, setUpcommingSessionData] = useState([
    {
      TransactionId: "",
      topic_name: "",
      teacher_name: "",
      subject_name: "",
      mst_session_course_id: "",
      from_time: "",
      to_time: "",
      session_date: "",
    },
  ]);
  const [nonPurchasedSession, setNonPurchasedSessionData] = useState([
    {
      TransactionId: "",
      topic_name: "",
      teacher_name: "",
      subject_name: "",
      mst_session_course_id: "",
    },
  ]);
  const [myPurchasedSession, setMyPurchasedSessionsSessionData] = useState([
    {
      TransactionId: "",
      topic_name: "",
      teacher_name: "",
      subject_name: "",
      mst_session_course_id: "",
    },
  ]);
  useEffect(() => {

    function fetchUserData() {
      var usr = localStorage.getItem("userData");
      if (usr !== undefined && usr !== null) {
        let parsedUser: any = JSON.parse(usr)
        console.log(parsedUser);
        setUserData(JSON.parse(usr));
      }
    }
    fetchUserData();
  }, []);
  function joinNow(props: any) {
    try {
      let apiData = {
        mobile: userData.mobile,
        userName: profileData.userName,
      };
      if (profileData.hasTrialSession) {
        services.setFreetrialFLag(apiData)
      }

      console.log(props);

      services.getCourseById(props.mst_session_course_id, profileData.userName, profileData.name).then((res) => {
        console.log(res)
        if (res.failed)
          console.error(res)
        else {
          console.log(res.data)
          const text = res.data.data.course_description;
          const pattern = /(meet\.google\.com\/[a-zA-Z0-9\-]+)/g;

          const matches = text.match(pattern);
          console.log(matches)
          if (matches) {
            const meetLink = "https://" + matches[0]; // Assuming the first match is the link you want to redirect to
            window.location.href = meetLink;
          }else{
            swal({
              title: "Oops! something went wrong",
              text: "Could'nt join the the session! Session does not have a valid joining link",
              icon: "error",
            });
          }
        }
      })
      // var TuitionRoom: TuitionRoomInterface = {
      //   ActivityID: props.itemId,
      //   ActivityType: "TuitionRoom",
      //   User: profileData.userName,
      //   TimeConsumed: "50",
      //   UserJoined: "0",
      //   syllabus: profileData.syllabus,
      //   syllabusName: profileData.syllabusName,
      //   standard: profileData.standard,
      //   standardName: "Class " + profileData.standard,
      //   Subject: props.mst_session_subject_id,
      //   SubjectName: props.subject_name,
      //   TextBook: props.mst_session_textbook_id,
      //   TextbookName: props.textbook_name,
      //   Chapter: props.mst_session_chapter_id,
      //   ChapterName: props.chapter_name,
      //   Teacher: props.teacher_id,
      //   TeacherName: props.teacher_name,
      //   startTime: props.SessionStartTime,
      //   SessionStatus: "Creating",
      //   SessionType: "Tuition",
      //   teacherVideoURL: "null",
      // };
      // console.log(TuitionRoom);
      // setToLocalStorage(constants.TUITION_ROOM, TuitionRoom);
      // console.log("Setting required data to localstroage...");
      // navigateTo("/live-tuition-room");
    } catch (e) {
      console.log("Failed to Join");
      console.log("error: " + e);
    }
  }
  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    function fetchData() {
      setLoader(true);
      let apiData = {
        user: profileData.userName,
        standardId: profileData.standard,
        syllabusId: profileData.syllabus,
      };
      console.log(apiData);
      services.getMyLiveTuitions(apiData).then((res) => {
        setLoader(false);
        console.log(res);
        if (res.failed) console.log("failed");
        else {
          console.log(res.data.data);
          let myPurchasedUpcommingSession = setFlagForPurchased(
            res.data.data.myPurchasedUpcommingSession
          );
          let myPurchasedSessions = setFlagForPurchased(
            res.data.data.myPurchasedSessions
          );
          setUpcommingSessionData(myPurchasedUpcommingSession);
          setNonPurchasedSessionData(res.data.data.nonPurchasedSessions);
          setMyPurchasedSessionsSessionData(myPurchasedSessions);
        }
      });
    }
    if (profileData.syllabus) fetchData();
  }, [profileData.syllabus]);
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  function navigateTo(path: string, props?: any) {
    navigate(path, { state: props });
  }

  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [selected, setSelected] = React.useState([]);

  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };

  const viewAll = (value: string) => {
    navigateTo("/live-tuition/" + value);
  };
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  const [open, setOpen] = React.useState(false);
  const [openTeacherDetails, setTeacherDetails] = React.useState(false);

  const handleOpen = (childProps: any, button?: any) => {
    console.log(button);
    let sessionButtonStatus = button;
    if (sessionButtonStatus === constants.MORE_DETAILS) {
      console.log(childProps);
      navigate("/live-tuition/details", {
        state: childProps.mst_session_course_id,
      });
    } else if (sessionButtonStatus === constants.JOIN_NOW) {
      console.log("joining to tuition app...");
      joinNow(childProps);
    }
  };
  const handleOpenTeacher = () => setTeacherDetails(true);
  const handleClose = () => {
    setOpen(false);
    setTeacherDetails(false);
  };
  function showReplay(replayUrl: string) {
    console.log(replayUrl);
    navigateTo("/live-tuition/completed/playback", replayUrl);
  }
  return (
    loader ? <LoaderComponent />:
    <>

      {/* <LiveTuitionMoreDetailsModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
      /> */}
      {/* <TeacherDetailsModal
        open={openTeacherDetails}
        setOpen={setOpen}
        // handleOpen={handleOpen}
        handleClose={handleClose}
      /> */}
      <Grid container className="fade-in-animate">
        <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
          <Navbar path={"live-tuition"} />
        </Grid>
        <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
          <TopBarComponent
            search={true}
            logo={false}
            profileData={profileData}
          />
          {/* purchased upcoming sessions */}
          {upcommingSession.length > 0 && (
            <div style={{ marginLeft: "3vw" }}>
              <Grid style={{ marginTop: "48px" }}>
                <span className="recommendedLabelText1">
                  Scheduled Live Tuitions
                </span>
                <br />
                <span className="recommendedLabelText2">
                  Test your subject knowledge.
                </span>
              </Grid>
              <Grid container spacing={1} style={{ paddingLeft: "24px" }}>
                <Grid item xs={12}>
                  <span
                    className="viewAllLablel"
                    style={{ marginLeft: "78%" }}
                    onClick={() => viewAll("purchased")}
                  >
                    View all
                  </span>
                </Grid>

                {upcommingSession.length > 0 ? (
                  upcommingSession.map((obj: any) => (
                    <LiveTuitionCommonCard
                      itemId={obj.mst_session_course_id} // NOTE: itemId is required to track items
                      title={obj.course_name}
                      key={obj.ActivityID}
                      onClick={handleClick(obj.mst_session_course_id)}
                      selected={isItemSelected(obj.mst_session_course_id)}
                      button={getButtonValue(
                        obj?.from_time,
                        obj?.to_time,
                        obj?.session_date
                      )}
                      handleOpen={handleOpen}
                      handleOpenTeacher={handleOpenTeacher}
                      teacherName={obj.teacher_name}
                      date={obj.session_date}
                      time={obj.from_time}
                      seats={"4/5"}
                      subject_name={
                        obj.session_subject_alias_name
                          ? obj.session_subject_alias_name
                          : obj.subject_name
                      }
                      mst_session_course_id={obj.mst_session_course_id}
                    />
                  ))
                ) : (
                  <div className="noSessions">
                    {" "}
                    <p
                      style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}
                    >
                      No sessions for you now.
                    </p>
                  </div>
                )}
              </Grid>
            </div>
          )}
          {/* scheduled sessions */}
          <div style={{ marginLeft: "3vw" }}>
            <Grid style={{ marginTop: "48px" }}>
              <span className="recommendedLabelText1">
                Available Live Tuitions
              </span>
              <br />
              <span className="recommendedLabelText2">
                Test your subject knowledge.
              </span>
            </Grid>
            <Grid container spacing={1} style={{ paddingLeft: "24px" }}>
              {nonPurchasedSession.length > 0 && (
                <Grid item xs={12}>
                  <span
                    className="viewAllLablel"
                    style={{ marginLeft: "78%" }}
                    onClick={() => viewAll("recommended")}
                  >
                    View all
                  </span>
                </Grid>
              )}

              {nonPurchasedSession.length > 0 ? (
                nonPurchasedSession.map((obj: any) => (
                  <LiveTuitionCommonCard
                    itemId={obj.mst_session_course_id} // NOTE: itemId is required to track items
                    title={obj.course_name}
                    key={obj.ActivityID}
                    onClick={handleClick(obj.mst_session_course_id)}
                    selected={isItemSelected(obj.mst_session_course_id)}
                    button={"More Details"}
                    handleOpen={handleOpen}
                    handleOpenTeacher={handleOpenTeacher}
                    teacherName={obj.teacher_name}
                    date={obj.session_date}
                    time={obj.from_time}
                    seats={"4/5"}
                    subject_name={
                      obj.session_subject_alias_name
                        ? obj.session_subject_alias_name
                        : obj.subject_name
                    }
                    mst_session_course_id={obj.mst_session_course_id}
                  />
                ))
              ) : (
                <div className="noSessions">
                  {" "}
                  <p style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}>
                    No sessions for you now.
                  </p>
                </div>
              )}
            </Grid>
          </div>

          {/* completed sessions */}
          {myPurchasedSession.length > 0 && (
            <div style={{ marginLeft: "3vw" }}>
              <Grid style={{ marginTop: "48px" }}>
                <span className="recommendedLabelText1">
                  Revise the lessons
                </span>
                <br />
                <span className="recommendedLabelText2">
                  Test your subject knowledge.
                </span>
              </Grid>

              <Grid container spacing={1} style={{ paddingLeft: "24px" }}>
                {myPurchasedSession.length > 0 && (
                  <Grid item xs={12}>
                    <span
                      className="viewAllLablel"
                      style={{ marginLeft: "78%" }}
                      onClick={() => viewAll("completed")}
                    >
                      View all
                    </span>
                  </Grid>
                )}

                {myPurchasedSession.length > 0 ? (
                  myPurchasedSession.map((session: any) => (
                    <CompletedLiveTuitionCard
                      itemId={session.ActivityID} // NOTE: itemId is required for track items
                      title={session.chapter_name}
                      key={session.ActivityID}
                      onClick={handleClick(session.ActivityID)}
                      selected={isItemSelected(session.ActivityID)}
                      button={"Play"}
                      teacherName={session.teacher_name}
                      showReplay={showReplay}
                      replayUrl={session.ReplayURL}
                      fromTime={session.from_time}
                      sessionDate={session.session_date}
                      subjectName={session.subject_name}
                      scienceSubject={session.session_subject_alias_name}
                    />
                  ))
                ) : (
                  <div className="noSessions">
                    {" "}
                    <p
                      style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}
                    >
                      No replay available.
                    </p>
                  </div>
                )}
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
