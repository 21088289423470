import React, { useState, useEffect, useRef } from "react";

import "./TuitionRoomApp.scss";

// importing agora rtc sdk
import AgoraRTC from "agora-rtc-sdk-ng";

import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import { ReactComponent as DoubtboxLogo } from "../../assets/logo/doubtbox_logo.svg";
import { services } from "../../services/api_service";
import {
  checkOrientation,
  formatAMPM,
  getFromLocalStorage,
  setToLocalStorage,
  getWebsocketUrl,
  makeAgoraUid,
  getErrorCode,
} from "../../shared/utils/utilityFunctions";
import { useNavigate } from "react-router-dom";
import { constants } from "../../constants/constants";
import swal from "sweetalert";
import { LoaderWithMessageComponent } from "../../shared/loader/loader";
import AlertService from "../../shared/alert/alertComponent";
import LinearProgress from "@mui/material/LinearProgress";

import { createFastboard, createUI } from "@netless/fastboard";

import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { ReactComponent as HandRaise } from "../../assets/icons/handRaise.svg";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExit from "@mui/icons-material/FullscreenExit";
import SendIcon from "@mui/icons-material/Send";
import icon from "../../assets/icons/mutedAvatar.png";
import micMuted from "../../assets/icons/microphone.svg";
import { ReactComponent as UploadCloud } from "../../assets/icons/upload-cloud.svg";
import { ReactComponent as DownloadCloud } from "../../assets/icons/download-cloud.svg";
import {
  AssessmentModal,
  AssessmentReportModal,
} from "../../components/uiComponents/modals";
import { messageUtil } from "../../shared/utils/messages";
import ReactPlayer from "react-player";

// import notificationTone from '../../assets/audio/notification.mp3';
declare global {
  interface Window {
    app: any;
  }
}
// interface NavigatorWithWakeLock extends Navigator {
//   wakeLock?: {
//     request: (type: string) => Promise<any>;
//   };
// }

interface Message {
  id: string;
  name: string;
  timestamp: any;
  content: string;
}
const document: any = window.document;
let remotePlayerContainer: any = null;
let localPlayerContainer: any = null;
// Create an instance of the Agora Engine outside the component
// const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

export function LiveTuitionRoom() {
  const navigate = useNavigate();
  // for fetching userData
  const [userData, setUserData] = useState({ profile: [] });
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    name: "",
    userName: "",
    hasTrialSession: false
  });
  const [roomuid, setRoom] = useState("");
  const [roomtoken, setRoomToken] = useState("");
  // const [agoraAppId, setAppId] = useState("");
  const [tuitionRoom, setTuitionRoom] = useState({
    ActivityID: "",
  });
  const [timenow, setTime] = useState("");
  const [teacher, setTeacher] = useState("");
  const [waitingForTeacher, setWaitingForTeacher] = useState(false);

  const [isJoined, setClassJoined] = useState(false);
  const [triedJoining, setTriedJoining] = useState(false);
  const [fullScreenFlag, setFullScreen] = useState(false);

  // const [fastboardCreated, setfastboardCreated] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState("");

  const [ismicrophoneMuted, setMicrophoneMuted] = useState(true);
  const [isCameraMuted, setCameraMuted] = useState(false);

  // const [deviceTested, setDeviceTest] = useState(false);
  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const [message, setMessageInput] = useState("");
  const [userUidArr, setUserUidArr] = useState<any>([]);
  var [messageArr, setMessagesArr] = useState<any[]>([]);

  const [showAssessmentModal, setAssessmentModal] = useState(false);
  const [showAssessmentReportModal, setAssessmentReportModal] = useState(false);

  // const [assessmentDetails, setAsessmentDetails] = useState(false);

  const [whiteboardApp, setWhiteboardApp] = useState<any>({});
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoUrlForPlayback, setVideoUrlFopPlayback] = useState("");
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  const [orientation, setOrientation] = useState(checkOrientation());
  useEffect(() => {
    function handleResize() {
      setOrientation(checkOrientation());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [whiteboardApp]);

  // Try to avoid pinch in zoom
  useEffect(() => {
    // Check if the browser supports touch events
    var supportsTouch =
      "ontouchstart" in window || navigator.maxTouchPoints > 0;

    if (supportsTouch) {
      // Listen for touch events
      var handleTouch = function (event: any) {
        // If the user is trying to zoom, prevent the default behavior
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      };

      // Add the touch event listeners
      document.addEventListener("touchstart", handleTouch, { passive: false });
      document.addEventListener("touchmove", handleTouch, { passive: false });
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      document.removeEventListener("touchstart", handleTouch);
      document.removeEventListener("touchmove", handleTouch);
    };
  }, []);

  useEffect(() => {
    validateLoggedIn();
    if (getFromLocalStorage("video_trigger") === "true") {
      setToLocalStorage("video_trigger", "");
      setClassJoined(true);
      join();
    }
  }, []);

  const validateLoggedIn = () => {
    var val = localStorage.getItem("userData");
    var prof = localStorage.getItem("profileData");
    var sessData = getFromLocalStorage(constants.TUITION_ROOM);

    if (typeof val !== "undefined" && val !== null)
      setUserData(JSON.parse(val));
    if (typeof prof !== "undefined" && prof !== null)
      setProfileData(JSON.parse(prof));
    if (typeof sessData !== "undefined" && sessData !== null) {
      setTuitionRoom(sessData);
      console.log(sessData);
    }
    // else {
    //   navigateTo("/login");
    // }
  };

  useEffect(() => {
    console.log(userUidArr);
  }, [userUidArr]);
  // FOR AGORA VIDEOSCALL SDK
  // TRY TO KEEP ALL THE LOGICS FOR VIDEOCALL SDK HERE

  const [toggleConnect, setToggleConnect] = useState(false);

  // function playSound() {
  //   var audio = new Audio(notificationTone);
  //   audio.play();
  // }

  useEffect(() => {
    // Create WebSocket connection.
    if (tuitionRoom && tuitionRoom.ActivityID) {
      const socket = new WebSocket(
        getWebsocketUrl(tuitionRoom.ActivityID, profileData.name)
      );
      socket.onclose = function (event) {
        console.log("keeping socket alive");
        setToggleConnect(!toggleConnect);
      };
      socket.onmessage = async function (message: any) {
        var parsedMessage = JSON.parse(message.data);
        console.log(parsedMessage);
        if (parsedMessage.ChannelID !== tuitionRoom.ActivityID) {
          return;
        }
        if (parsedMessage.id === constants.CHAT) {
          if (parsedMessage.content) {
            let msg: Message = {
              name: parsedMessage.user,
              content: parsedMessage.content,
              timestamp: parsedMessage.messageTimeStamp,
              id: parsedMessage.messageTimeStamp + "_id",
            };
            console.log(msg);
            console.log(messageArr);
            let temp = messageArr;
            temp.push(msg);
            const unique = [
              ...new Map(temp.map((m) => [m.timestamp, m])).values(),
            ];

            // removeDuplicates(temp, 'timestamp')
            console.log(unique);
            setMessagesArr(unique);
            var chatDiv = document.getElementById("chatList");
            if (chatDiv) {
              chatDiv.scrollTop = chatDiv.scrollHeight;
            }
          }
        } else if (parsedMessage.id === "video") {
          // To play animated videos in the whiteboard,
          // we need to address the browser's blocking of the auto play feature due to an ongoing
          // live video stream on the webpage. Therefore, a manual trigger is implemented in
          // the whiteboard app. The trigger, created by the teacher through a websocket,
          // initiates a re-rendering of the whiteboard, enabling automatic video playback with a slight delay.

          console.log("video trigger recieved");
          const elem = document.getElementById("whiteboard");

          // createUI functions creates a new component with class name "fastboard-root" which causes duplication.
          // Therefore the logic below takes the first "fastboard-root" under the div with id whiteboard and removes it
          var firstFastboard = document.querySelector(
            "#whiteboard .fastboard-root"
          );
          if (firstFastboard) {
            firstFastboard.parentNode.removeChild(firstFastboard);
          }

          if (elem != null) return createUI(window.app, elem);
        } else if (parsedMessage.id === constants.HR_ACCEPTED) {
          console.log(parsedMessage);
          let thisprofileData = getFromLocalStorage("profileData");
          console.log(thisprofileData);
          if (parsedMessage.hrRequestBy === thisprofileData.userName) {
            swal("success", "Hand raise accepted");
            let hostStream = document.getElementById("host-stream");
            let localStream = document.getElementById("local-stream");
            if (hostStream && localStream) {
              // hostStream.remove()
              while (hostStream.firstChild) {
                hostStream.removeChild(hostStream.firstChild);
              }
              hostStream.append(localPlayerContainer);
              while (localStream.firstChild) {
                localStream.removeChild(hostStream.firstChild);
              }
              localStream.append(remotePlayerContainer);
            }
          } else {
            // agoraEngine.subscribe
            for (let i = 0; i < userUidArr.length; i++) {
              const elem = userUidArr[i];
              console.log(elem.uid);

              console.log(elem.uid, parsedMessage.requestedUserUid);
              if (elem.uid === parsedMessage.requestedUserUid) {
                console.log("yes");

                agoraEngine.current.subscribe(elem, "video");
                agoraEngine.current.subscribe(elem, "audio");
                //     let user = elem
                channelParameters.remoteVideoTrack = elem.videoTrack;
                //     // Retrieve the remote audio track.
                channelParameters.remoteAudioTrack = elem.audioTrack;
                //     // Save the remote user id for reuse.
                channelParameters.remoteUid = elem.uid;
                //     // Specify the ID of the DIV container. You can use the uid of the remote user.
                removeVideoDiv(teacher);
                remotePlayerContainer = document.createElement("div");
                // Set the remote video container size.
                remotePlayerContainer.style.width = "19vw";
                remotePlayerContainer.style.height = "18vh";
                remotePlayerContainer.style.paddingLeft = "5px";
                remotePlayerContainer.style.marginTop = "5px";

                remotePlayerContainer.id = elem.uid;
                channelParameters.remoteUid = elem.uid;
                // remotePlayerContainer.textContent = "Remote user " + user.uid;
                //     // Append the remote container to the page body.
                const remoteStream = document.getElementById("host-stream");
                if (remoteStream != null)
                  remoteStream.append(remotePlayerContainer);
                //     // Play the remote video track.
                if (channelParameters.remoteVideoTrack)
                  channelParameters.remoteVideoTrack.play(
                    remotePlayerContainer
                  );
                return;
              }
            }
            // let i=0;
            // while(userUidArr[i].uid === parsedMessage.hrRequestBy){
            // }
          }
        } else if (parsedMessage.id === "hrRequest_closed") {
          let thisprofileData = getFromLocalStorage("profileData");
          console.log(parsedMessage.hrRequestBy, thisprofileData.userName);
          if (parsedMessage.hrRequestBy === thisprofileData.userName) {
            showAlert("success", "Hand raise closed");
            let hostStream = document.getElementById("host-stream");
            let localStream = document.getElementById("local-stream");
            if (hostStream && localStream) {
              // hostStream.remove()
              while (hostStream.firstChild) {
                hostStream.removeChild(hostStream.firstChild);
              }
              hostStream.append(remotePlayerContainer);
              while (localStream.firstChild) {
                localStream.removeChild(hostStream.firstChild);
              }
              localStream.append(localPlayerContainer);
            }
          } else {
            console.log("hr_closed");
            // for (let i = 0; i < userUidArr.length; i++) {
            //   const elem = userUidArr[i];
            // if()
            // }
          }
        } else if (parsedMessage.id === constants.DISCONNECT_SESSION) {
          console.log("leaving session");
          swal({
            title: "Session Ended!",
            text: "The session was ended by the Teacher! you will be redirected to dashboard!",
            icon: "info",
            timer: 5000,
          })
            .then(() => {
              // Function to execute after the timeout
              leaveClass();
            })
            .catch(() => {
              // Handle error in the Swal promise chain
              console.log("Error occurred while displaying Swal dialog");
            });
        } else if (parsedMessage.id === constants.MUTE_ALL) {
          console.log(ismicrophoneMuted ? "mic muted true" : "mic muted false");
          // if (ismicrophoneMuted === false) {
          //   muteUnmuteAudio();
          //   // channelParameters.localAudioTrack.setEnabled(false);
          //   // setMicrophoneMuted(true);
          //   // let localMuteIcon = document.getElementById("localMuteIcon");
          //   // if (localMuteIcon) localMuteIcon.style.display = "block";
          //   showAlert("success", "Microphone muted by teacher");
          // }
          setMicrophoneMuted((prevMuted) => {
            if (prevMuted === false) {
              muteUnmuteAudio();
              showAlert("success", "Microphone muted by teacher");
              return true;
            } else return true;
          });
        } else if (parsedMessage.id === constants.ASSESSMENT) {
          // services.getLastAsmntQnWithSessionId(tuitionRoom.ActivityID).then((res)=>{
          //   console.log(res)
          //   if(res.failed)
          //   showAlert("error", messageUtil.SOMETHING_WENT_WRONG+' Assessment question failed to fetch. code: AS200')
          //   else{
          //     setAsessmentDetails(res.data)
          setAssessmentModal(true);

          //   }
          // })
          // fetch assessment details then
        } else if (parsedMessage.id === constants.ASSESSMENT_CLOSE) {
          console.log("assessment timed out");
          setAssessmentModal(false);
          setAssessmentReportModal(true);
        } else if (parsedMessage.id === constants.REQUEST_MEDIA_ACTIVATION) {
          console.log("recieved media activation request");
          let message = parsedMessage.content;
          // console.log(makeAgoraUid(profileData.name), message.user.name);

          if (makeAgoraUid(profileData.name) === message.user.name) {
            switch (message.param) {
              case constants.MIC:
                console.log(ismicrophoneMuted);
                muteUnmuteAudio();
                break;
              case constants.CAMERA:
                console.log(isCameraMuted);

                console.log(
                  "Teacher has requested you to Toggle your camera",
                  isCameraMuted === true
                    ? "isCameraMuted true"
                    : "isCameraMuted false"
                );
                swal({
                  title: "Camera toggled!",
                  text: "Camera toggle request recieved from teacher!",
                  icon: "info",
                  timer: 2000,
                });
                muteUnmuteVideo();
                break;
            }
          }

        }
      };
    }
  }, [tuitionRoom, toggleConnect]);

  useEffect(() => {
    var div = document.getElementById("chatList");
    if (div) div.scrollTop = div.scrollHeight;
  }, [messageArr]);

  var [options, setOptions] = useState({
    appId: "",
    channel: "",
    token: null,
    uid: "",
  });

  const [sessionData, setSessionData] = useState<any>({});
  let [channelParameters, setChannelParameters]: any = useState({
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null,
  });

  // Create an instance of the Agora Engine
  // const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  const agoraEngine = useRef<any>(
    AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
  );

  function navigateTo(path: string) {
    navigate(path);
  }

  useEffect(() => {
    if (userData && tuitionRoom.ActivityID) {
      createLayout();
      //Fetches agora app id and session details.
      fetchInitialDatas();
    }
    // join();
  }, [userData]);

  let a: boolean;
  useEffect(() => {
    if (!a) {
      checkDeviceStats();
      a = true;
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      // leaveClass();
      // Destroy the local audio and video tracks.
      await Promise.all([
        channelParameters.localAudioTrack.close(),
        channelParameters.localVideoTrack.close(),
      ]);

      // Remove the containers for local and remote videos.
      removeVideoDiv(remotePlayerContainer.id);
      removeVideoDiv(localPlayerContainer.id);

      // Leave the channel.
      await agoraEngine.current.leave();
      console.log("You left the channel");
      // window.location.reload();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (waitingForTeacher === false && triedJoining === true) {
      console.log("joining by useEffect");
      join();
    } else {
      console.log("trigger wasted");
    }
  }, [waitingForTeacher]);


  /**
   * Code related to initial testing of devices.
   */
  async function checkDeviceStats() {
    try {
      if (!isJoined) {
        removeVideoDiv("local-test-video");
        let testContainer = document.createElement("div");

        // Set the remote video container size.
        testContainer.style.width = "19vw";
        testContainer.style.height = "23vh";
        testContainer.style.paddingLeft = "5px";
        testContainer.style.marginTop = "5px";

        testContainer.id = "local-test-video";

        // creates a canvas and draws the graph for sounds from the user microphone
        const canvas: HTMLCanvasElement = document.getElementById(
          "visualizer"
        ) as HTMLCanvasElement;
        const canvasCtx: CanvasRenderingContext2D = canvas.getContext(
          "2d"
        ) as CanvasRenderingContext2D;

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream: MediaStream) => {
            const audioCtx: AudioContext = new AudioContext();
            const source: MediaStreamAudioSourceNode =
              audioCtx.createMediaStreamSource(stream);
            const analyser: AnalyserNode = audioCtx.createAnalyser();
            source.connect(analyser);
            analyser.fftSize = 2048;
            const bufferLength: number = analyser.fftSize;
            const dataArray: Uint8Array = new Uint8Array(bufferLength);

            function draw() {
              requestAnimationFrame(draw);
              analyser.getByteTimeDomainData(dataArray);

              canvasCtx.fillStyle = "rgb(200, 200, 200)";
              canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

              canvasCtx.lineWidth = 2;
              canvasCtx.strokeStyle = "rgb(0, 0, 0)";

              canvasCtx.beginPath();

              const sliceWidth: number = (canvas.width * 1.0) / bufferLength;
              let x: number = 0;

              for (let i = 0; i < bufferLength; i++) {
                const v: number = dataArray[i] / 128.0;
                const y: number = (v * canvas.height) / 2;

                if (i === 0) {
                  canvasCtx.moveTo(x, y);
                } else {
                  canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
              }

              canvasCtx.lineTo(canvas.width, canvas.height / 2);
              canvasCtx.stroke();
            }

            draw();
          })
          .catch((error: Error) => {
            let errorObj = getErrorCode(error);
            console.log(errorObj);
            swal({
              title: "Failed!",
              text:
                "Something went wrong ! Microphone failed to start... Please copy/screenshot this error for reporting the issue. Err:" +
                errorObj.message +
                "Code: " +
                errorObj.errCode,
              icon: "error",
            });
            console.log(error);
          });
        // Set a video profile.
        channelParameters.localVideoTrack =
          await AgoraRTC.createCameraVideoTrack({
            encoderConfig: {
              width: 128,
              // Specify a value range and an ideal value
              height: { ideal: 128, min: 64, max: 128 },
              frameRate: 8,
              bitrateMin: 300,
              bitrateMax: 1000,
            },
            // optimizationMode: "motion",
          });
        let testDiv = document.getElementById("video-test");
        testDiv.append(testContainer);
        testDiv.style.width = "20vw";
        testDiv.style.height = "25vh";
        testDiv.style.padding = "5px";
        if (testDiv != null)
          channelParameters.localVideoTrack.play(testContainer);
        // setDeviceTest(true);
      }
    } catch (e: any) {
      closeFullscreen();
      let errorObj = getErrorCode(e);
      console.log(errorObj);
      swal({
        title: "Failed!",
        text:
          "something went wrong ! Media device failed to start... Please copy/screenshot this error for reporting the issue. Err: " +
          errorObj.message +
          " Code: " +
          errorObj.errCode,

        icon: "error",
      });
      console.log(errorObj);
      setLoader(false);
    }
  }

  function fetchInitialDatas() {
    setLoader(true);
    let p1 = services.fetchAgoraAppId();
    let p2 = services.getSessionById(tuitionRoom.ActivityID);
    Promise.all([p1, p2]).then(function (responses) {
      setLoader(false);
      console.log(responses);
      let responseOffetchAgoraAppId = responses[0].data.data;
      let responseOfgetSessionById = responses[1].data.data;
      if (!responseOfgetSessionById.Item) {
        return swal({
          title: "Failed to get details",
          text:
            "Fetch session failed. No such session found!" +
            tuitionRoom.ActivityID,
          icon: "error",
        });
      } else {
        options.appId = responseOffetchAgoraAppId;
        options.channel = tuitionRoom.ActivityID;
        options.token = null;
        options.uid = makeAgoraUid(profileData.hasTrialSession ? profileData.name + ' (On Trial)' : profileData.name);
        console.log(options);
        setOptions(options);
        setTeacher(makeAgoraUid(responseOfgetSessionById.Item.teacher_name));
        setSessionData(responseOfgetSessionById.Item);
        if (
          responseOfgetSessionById.Item.wbRoomToken &&
          responseOfgetSessionById.Item.wbRoom.uuid
        ) {
          setRoomToken(responseOfgetSessionById.Item.wbRoomToken);
          setRoom(responseOfgetSessionById.Item.wbRoom.uuid);
        } else {
          checkForTeacher();
          console.log("error fetcing room token or room uid");
        }
      }
    });
  }

  function checkForTeacher() {
    setTimeout(() => {
      console.log("looping and checking for teacher ");
      services.getSessionById(tuitionRoom.ActivityID).then((res) => {
        console.log("checking for teacher");
        setTeacher(makeAgoraUid(res.data.data.Item.teacher_name));
        setSessionData(res.data.data.Item);
        if (res.data.data.Item.wbRoomToken && res.data.data.Item.wbRoom.uuid) {
          console.log("tokens found.. trying to join automatically...");
          setRoomToken(res.data.data.Item.wbRoomToken);
          setRoom(res.data.data.Item.wbRoom.uuid);
          setWaitingForTeacher(false);
          // join();
        } else {
          checkForTeacher();
          console.log(
            "Teacher not Joined...error fetcing room token or room uid"
          );
        }
      });
    }, 5000);
  }

  function createLayout() {
    // Dynamically create a container in the form of a DIV element to play the remote video track.
    remotePlayerContainer = document.createElement("div");

    // Set the remote video container size.
    remotePlayerContainer.style.width = "19vw";
    remotePlayerContainer.style.height = "18vh";
    remotePlayerContainer.style.paddingLeft = "5px";
    remotePlayerContainer.style.marginTop = "5px";

    // Dynamically create a container in the form of a DIV element to play the local video track.
    localPlayerContainer = document.createElement("div");

    // Specify the ID of the DIV container. You can use the uid of the local user.
    // localPlayerContainer.id = JSON.stringify(options.uid);
    localPlayerContainer.id = options.uid;

    // Set the textContent property of the local video container to the local user id.
    // localPlayerContainer.textContent = "Local user " + options.uid;
    console.log(localPlayerContainer);

    // Set the local video container size.
    localPlayerContainer.style.width = "19vw";
    localPlayerContainer.style.height = "17vh";
    localPlayerContainer.style.paddingLeft = "5px";
    console.log("Layout created");
  }
  // Subscribe to the remote user when the SDK triggers the "user-published" event.
  agoraEngine.current.on(
    "user-published",
    async (user: any, mediaType: any) => {

      console.log("user published");
      console.log("Doubtbox: New user publish detected...", user);

      // should match uid of teacher
      console.log(user.uid, makeAgoraUid(teacher));
      const is_teacher = user.uid === makeAgoraUid(teacher) ? true : false;
      await agoraEngine.current.subscribe(user, mediaType);

      if (mediaType === "video" && is_teacher) {
        await agoraEngine.current.subscribe(user, mediaType);
        console.log("subscribed to video", user.uid);
        // should match uid of teacher
        let teacherDiv = document.getElementById(teacher);
        if (teacherDiv) {
          removeVideoDiv(teacher);
        }
        // Retrieve the remote video track.
        channelParameters.remoteVideoTrack = user.videoTrack;
        // Retrieve the remote audio track.
        channelParameters.remoteAudioTrack = user.audioTrack;
        // Save the remote user id for reuse.
        channelParameters.remoteUid = user.uid;
        // Specify the ID of the DIV container. You can use the uid of the remote user.
        remotePlayerContainer = document.createElement("div");
        // Set the remote video container size.
        remotePlayerContainer.style.width = "19vw";
        remotePlayerContainer.style.height = "18vh";
        remotePlayerContainer.style.paddingLeft = "5px";
        remotePlayerContainer.style.marginTop = "5px";
        remotePlayerContainer.style.backgroundImage = `url(${icon})`;
        remotePlayerContainer.style.backgroundRepeat = "no-repeat";
        remotePlayerContainer.style.backgroundPosition = "center";
        remotePlayerContainer.style.backgroundSize = "contain";

        const teacherMuteImage = new Image();
        teacherMuteImage.id = "teacherMuteIcon";
        teacherMuteImage.src = micMuted;
        teacherMuteImage.style.position = "absolute";
        teacherMuteImage.style.zIndex = "9";
        teacherMuteImage.style.marginTop = "3vh";
        teacherMuteImage.style.marginLeft = "17vw";
        teacherMuteImage.style.backgroundColor = "red";
        teacherMuteImage.style.padding = "5px";
        teacherMuteImage.style.borderRadius = "7px";

        if (!user.audioTrack) {
          teacherMuteImage.style.display = "block";
        } else {
          teacherMuteImage.style.display = "none";
        }
        remotePlayerContainer.appendChild(teacherMuteImage);

        remotePlayerContainer.id = user.uid;
        channelParameters.remoteUid = user.uid;
        remotePlayerContainer.title = user.uid.split('"').join("");

        // Append the remote container to the page body.
        const remoteStream = document.getElementById("host-stream");
        if (remoteStream != null) remoteStream.append(remotePlayerContainer);

        // Play the remote video track.
        if (channelParameters.remoteVideoTrack)
          channelParameters.remoteVideoTrack.play(remotePlayerContainer);
      }

      // Subscribe and play the remote audio track If the remote user publishes the audio track only.
      if (mediaType === "audio") {
        await agoraEngine.current.subscribe(user, mediaType);
        console.log("subscribed to audio", user.uid);

        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        channelParameters.remoteAudioTrack = user.audioTrack;
        // Play the remote audio track. No need to pass any DOM element.
        channelParameters.remoteAudioTrack.play();
        console.log("audio muted");
        let teacherMuteImage = document.getElementById("teacherMuteIcon");
        if (teacherMuteImage) teacherMuteImage.style.display = "none";
      }
    }
  );

  // Listen for the "user-unpublished" event.
  agoraEngine.current.on("user-unpublished", (user: any, mediaType: any) => {
    console.log(mediaType);
    if (user.uid === teacher) {
      if (mediaType === "video") {
        // let teacherDiv = document.getElementById(teacher)
        // if(teacherDiv)
        // teacherDiv.style.background = "blue"
      } else if (mediaType === "audio") {
        console.log("teacher audio muted");
        let teacherMuteImage = document.getElementById("teacherMuteIcon");
        if (teacherMuteImage) teacherMuteImage.style.display = "block";
      }
    }
    console.log(user.uid + " has left the channel");
  });

  agoraEngine.current.on("user-left", (user: any) => {
    console.log(user.uid + "has left the channel");
    removeVideoDiv(user.uid);
  });


  async function leaveClass() {

    // Destroy the local audio and video tracks.
    await Promise.all([
      // channelParameters.localAudioTrack.close(),
      // channelParameters.localVideoTrack.close(),
      channelParameters.localAudioTrack.stop(),
      channelParameters.localVideoTrack.stop(),
    ]);

    setToLocalStorage(constants.FROM_SESSION, true);
    // Leave the channel.
    await agoraEngine.current.leave();

    // Remove the containers for local and remote videos.
    removeVideoDiv(remotePlayerContainer.id);
    removeVideoDiv(localPlayerContainer.id);

    // Delay before navigating to the dashboard (e.g., 500 milliseconds).
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Navigate to the dashboard.
    navigateTo("/dashboard");

    console.log("You left the channel");
  }

  async function join() {
    setTriedJoining(true);
    if (!roomuid || !roomtoken) {
      setWaitingForTeacher(true);
      return;
    }
    setClassJoined(true);
    setLoaderMessage("Initializing class room...");
    setLoader(true);
    keepTime();
    // Join a channel.
    try {
      await agoraEngine.current.join(
        options.appId,
        options.channel,
        options.token,
        JSON.stringify(options.uid)
      );
      // Create a local audio track from the audio sampled by a microphone.
      channelParameters.localAudioTrack =
        await AgoraRTC.createMicrophoneAudioTrack();

      // Set a video profile.
      channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack(
        {
          encoderConfig: {
            width: 320,
            // Specify a value range and an ideal value
            height: { ideal: 240, min: 200, max: 250 },
            frameRate: 8,
            bitrateMin: 600,
            bitrateMax: 1000,
          },
          optimizationMode: "motion",
        }
      );
      agoraEngine.current.setStreamFallbackOption(
        channelParameters.remoteUid,
        1
      );

      // Append the remote container to the page body.
      const localStream = document.getElementById("local-stream");
      if (localStream && localStream != null)
        localStream.append(localPlayerContainer);

      // document.body.append(localPlayerContainer);
      // Publish the local audio and video tracks in the channel.
      await agoraEngine.current.publish([
        channelParameters.localAudioTrack,
        channelParameters.localVideoTrack,
      ]);
      // Play the local video track.
      channelParameters.localVideoTrack.play(localPlayerContainer);
      setLoader(false);
      setLoader(true);
      setLoaderMessage("Initializing class success...");
      console.log(channelParameters.localAudioTrack);
      // setChannelParameters(channelParameters)
      fullScreen();
      // Mute the local audio.
      channelParameters.localAudioTrack.setEnabled(false);
      const muteImage = new Image();
      muteImage.id = "localMuteIcon";
      muteImage.src = micMuted;
      muteImage.style.position = "absolute";
      muteImage.style.zIndex = "9";
      muteImage.style.marginTop = "-14vh";
      muteImage.style.marginLeft = "17vw";
      muteImage.style.backgroundColor = "red";
      muteImage.style.padding = "5px";
      muteImage.style.borderRadius = "7px";
      localPlayerContainer.appendChild(muteImage);

      setMicrophoneMuted(true);
      setLoaderMessage("Loading whiteboard components...");
      setLoader(false);
      setLoader(true);

      showAlert("success", "your microphone muted");

      let fastboardElem = document.getElementById("whiteboard");
      if (fastboardElem) mountFastboard(fastboardElem);

      checkTeacher(teacher);

      setTimeout(() => {
        getAndSetMessages();
      }, 5000);
    } catch (e) {
      closeFullscreen();
      let errorObj = getErrorCode(e);
      console.log(errorObj);
      if (errorObj.errCode !== "AG102") {
        swal({
          title: "Failed!",
          text:
            "something went wrong! Camera could not be started... Please copy/screenshot this error for reporting the issue. Err:" +
            errorObj.message +
            " Code: " +
            errorObj.errCode,
          icon: "error",
        });
      }

      console.log(e);
      setLoader(false);
    }
  }
  function checkTeacher(teacherName: string) {
    setTimeout(() => {
      console.log("looping and checking for teacher" + teacherName);
      let teacherElem = document.getElementById(teacherName);
      console.log(teacherElem);
      if (teacherElem) {
        joinWhite();
        // setisTeacherInSession(true);
      } else {
        checkTeacher(teacherName);
      }
    }, 5000);
  }
  // Remove the video stream from the container.
  function removeVideoDiv(elementId: any) {
    console.log("Removing " + elementId + "Div");
    let Div = document.getElementById(elementId);
    if (Div) {
      Div.remove();
    }
  }

  // VIDEOCALL SDK LOGICS ENDS HERE

  // WHITEBOARD SDK LOGIC STARTS HERE

  // Fastboard

  async function mountFastboard(div: any) {
    console.log(roomtoken, roomtoken);

    if (roomtoken && roomuid) {
      console.log("fastboard mounting", {
        uuid: roomuid,
        uid: makeAgoraUid(profileData.name),
        roomToken: roomtoken,
      });
      let app = await createFastboard({
        sdkConfig: {
          appIdentifier: "4ZXMcMSXEe2ZPcvd-WK2rQ/5wYHOH9HylH6vA",
          region: "us-sv",
        },
        joinRoom: {
          uuid: roomuid,
          // The unique identifier of a user. If you use versions earlier than v2.15.0, do not add this line.
          uid: profileData.name,
          roomToken: roomtoken,
          disableDeviceInputs: true,
          isWritable: false,
        },
        managerConfig: {
          cursor: false,
        },
      });

      window.app = app;
      console.log(app);
      setWhiteboardApp(app);
      window.app.room.disableCameraTransform = true;
      // window.app.room.disableCamera = true;

      console.log(app);
      // setfastboardCreated(true);
      // return mount(app, div, mountprops);
      return createUI(app, div);
    }
  }

  function joinWhite() {
    // initializeWhiteRoom(true)
    const elem = document.getElementById("whiteboard");
    if (elem != null)
      mountFastboard(elem).then(() => {
        console.log("successfully mounted fastboard");
      });
  }
  // WHITEBOARD SDK LOGIC ENDS HERE
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(event.target.value);
  //   setRoom(event.target.value);
  // };

  // const handleChangert = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(event.target.value);

  //   setRoomToken(event.target.value);
  // };

  function fullScreen() {
    // console.log(makeAgoraUid(teacher))
    // return;
    const elem = document.documentElement; // Get the root element of the document
    if (elem) {
      try {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
        setFullScreen(true);
      } catch (e) {
        console.log(e);
      }
    }

    console.log("fullscreen");
  }
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }

  /* This function listens for  full screen escape event and set the full screen icon accordingly */
  useEffect(() => {
    const handleFullScreenChange = () => {
      const isInFullScreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
      setFullScreen(isInFullScreen);
    };

    const handleExitFullScreen = () => {
      setFullScreen(false);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    document.addEventListener('fullscreenerror', handleExitFullScreen);
    document.addEventListener('webkitfullscreenerror', handleExitFullScreen);
    document.addEventListener('mozfullscreenerror', handleExitFullScreen);
    document.addEventListener('MSFullscreenError', handleExitFullScreen);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);

      document.removeEventListener('fullscreenerror', handleExitFullScreen);
      document.removeEventListener('webkitfullscreenerror', handleExitFullScreen);
      document.removeEventListener('mozfullscreenerror', handleExitFullScreen);
      document.removeEventListener('MSFullscreenError', handleExitFullScreen);
    };
  }, []);

  
  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }

  function muteUnmuteAudio(controlFromTeacher?: boolean) {
    setMicrophoneMuted((prevMuted) => {
      if (prevMuted === true) {
        channelParameters.localAudioTrack.setEnabled(true);
        let localMuteIcon = document.getElementById("localMuteIcon");
        if (localMuteIcon) localMuteIcon.style.display = "none";
        showAlert("success", "Microphone Unmuted");
        if (controlFromTeacher) {
          swal({
            title: "Mic turned ON!",
            text: "Your microphone is unmuted by the teacher!",
            icon: "info",
            timer: 2000,
          });
        }
        return false; // Update the state to false (unmuted)
      } else {
        channelParameters.localAudioTrack.setEnabled(false);
        let localMuteIcon = document.getElementById("localMuteIcon");
        if (localMuteIcon) localMuteIcon.style.display = "block";
        showAlert("success", "Microphone Muted");
        if (controlFromTeacher) {
          swal({
            title: "Mic turned OFF!",
            text: "Your microphone is muted by the teacher!",
            icon: "info",
            timer: 2000,
          });
        }
        return true; // Update the state to true (muted)
      }
    });
  }

  function muteUnmuteVideo() {
    setCameraMuted((prevMuted) => {
      if (prevMuted === true) {
        channelParameters.localVideoTrack.setEnabled(true);
        showAlert("success", "Camera Unmuted");
        let localPlayer = document.getElementById("local-stream");
        localPlayer.style.display = "block";
        let localVideoContainer = document.getElementById(
          "studentVideoContainer"
        );
        if (localVideoContainer)
          localVideoContainer.style.backgroundImage = "none";
        return false;
      } else {
        channelParameters.localVideoTrack.setEnabled(false);
        let localPlayer = document.getElementById("local-stream");
        localPlayer.style.display = "none";
        let localVideoContainer = document.getElementById(
          "studentVideoContainer"
        );

        if (localVideoContainer) {
          localVideoContainer.style.backgroundImage = `url(${icon})`;
          localVideoContainer.style.backgroundRepeat = "no-repeat";
          localVideoContainer.style.backgroundPosition = "center";
          localVideoContainer.style.backgroundSize = "contain";
        }
        showAlert("success", "Camera Muted");
        return true;
      }
    });
  }

  function refresh() {
    window.location.reload();
  }

  function keepTime() {
    setTimeout(() => {
      setLoader(false);
      setTime(formatAMPM(new Date()));
      keepTime();
      // keepSocketAwake();
    }, 10000);
  }
  // function keepSocketAwake() {
  //     const socket = new WebSocket("wss://ennfpcenti.execute-api.eu-west-1.amazonaws.com/dev?channelid=Session~848&userName=rahulkrishna");
  // setSocket(socket)
  //     socket.onclose = function(){
  //         setTimeout(keepSocketAwake, 1000);
  //     };
  // }
  function sendHandRaiseRequest() {
    console.log("sending HR");
    const socket = new WebSocket(
      getWebsocketUrl(tuitionRoom.ActivityID, profileData.name)
    );

    console.log("sending HR request");
    var msg = {
      message: "sendMessage",
      data: JSON.stringify({
        user: profileData.name,
        userName: profileData.userName,
        messageTimeStamp: new Date().getTime(),
        id: "handRaise",
        ChannelID: tuitionRoom.ActivityID,
      }),
      type: "chat",
      ChannelID: tuitionRoom.ActivityID,
    };
    console.log(msg);
    var jsonMessage = JSON.stringify(msg);
    console.log(socket.readyState);

    socket.onopen = () => {
      socket.send(jsonMessage);
      swal({
        title: "Success!",
        text: "Hand raise request sent successfully!",

        icon: "success",
      });
    };
  }
  function sendMessage() {
    const socket = new WebSocket(
      getWebsocketUrl(tuitionRoom.ActivityID, profileData.name)
    );
    console.log(socket);
    console.log(message);
    if (message !== "") {
      console.log("sending message");
      var msg = {
        message: "sendMessage",
        data: JSON.stringify({
          user: profileData.name,
          userName: profileData.userName,
          messageTimeStamp: new Date().getTime(),
          id: "chat",
          content: message,
          ChannelID: tuitionRoom.ActivityID,
        }),
        type: "chat",
        ChannelID: tuitionRoom.ActivityID,
      };
      console.log(msg);
      var jsonMessage = JSON.stringify(msg);
      console.log(socket.readyState);

      socket.onopen = () => {
        console.log("message sent" + jsonMessage);
        socket.send(jsonMessage);
      };
      document.getElementById("message-input").value = "";
      setMessageInput("");
      if (messageArr.length !== 0) {
        let temp = messageArr;
        temp.push({
          name: profileData.name,
          timestamp: new Date().getTime(),
          id: new Date().getTime(),
          content: message,
        });
        messageArr = temp;
        setMessagesArr(messageArr);
        var div = document.getElementById("chatList");
        div.scrollTop = div.scrollHeight;
      }
    }
  }

  function handleMessageInput(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;
    console.log(inputValue.length, inputValue);
    if (inputValue.length <= constants.CHAT_LENGTH) {
      setMessageInput(inputValue);
    }
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const divElement1: HTMLElement = document.getElementById("fastboard-root");
  if (divElement1) {
    console.log("blocking scroll1");
    divElement1.addEventListener("scroll", function (event: Event) {
      event.preventDefault();
    });
  }

  function getAndSetMessages() {
    services.getChatData(sessionData.content_uuid).then((res: any) => {
      if (res && res.failed) {
        showAlert("error", "Oops... failed to fetch old chats err: CH100");
      } else {
        console.log(res);
        let temp = res.data.data;
        let messageData = [];
        if (temp && temp.length > 0) {
          for (let i = 0; i < temp.length; i++) {
            let elem = temp[i];
            messageData.push({
              name: elem.user,
              content: elem.content,
              id: "chat",
              timestamp: elem.messageTimeStamp,
            });
          }
          setMessagesArr(messageData);
          console.log(messageData);
        }
      }
    });
  }

  const handleOpen = () => setAssessmentModal(true);
  const handleOpenReport = () => setAssessmentReportModal(true);
  // const handleClose = () => {setAssessmentModal(false);}

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log(event);

    console.log(reason);
    if (reason === "backdropClick") return;
    else setAssessmentModal(false);
  };

  const handleCloseReport = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log(event);

    console.log(reason);
    if (reason === "backdropClick") return;
    else setAssessmentReportModal(false);
  };

  function submit(props: any) {
    console.log("submitted");
    let obj = {
      sessionId: tuitionRoom.ActivityID,
      selected_answer: props.selectedAnswer,
      username: profileData.userName,
      name: profileData.name,
      time: props.time,
    };
    console.log(obj);
    services.submitLiveAssessment(obj).then((res) => {
      console.log(res);
      // setTimeout(() => {
    });
  }

  function closeVideoModal() {
    setShowVideoPlayer(false);
    const elem = document.getElementById("whiteboard");
    console.log(elem);
    if (elem != null) return createUI(window.app, elem);
    else {
      setTimeout(() => {
        closeVideoModal();
      }, 1000);
    }
  }

  return (
    <div>
      <AssessmentModal
        open={showAssessmentModal}
        setOpen={() => setAssessmentModal(!showAssessmentModal)}
        handleOpen={handleOpen}
        handleClose={handleClose}
        submit={submit}
        activity={tuitionRoom.ActivityID}
      // assessmentDetails={assessmentDetails}
      />
      <AssessmentReportModal
        open={showAssessmentReportModal}
        setOpen={() => setAssessmentReportModal(true)}
        handleOpen={handleOpenReport}
        handleClose={handleCloseReport}
        activity={tuitionRoom.ActivityID}
      // assessmentDetails={assessmentDetails}
      />

      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />
      {loader && <LoaderWithMessageComponent message={loaderMessage} />}
      {!orientation && <AutoRotateComponent />}

      {isJoined === true ? (
        <>
          <Grid
            container
            className="tuitionMainContainer"
            id="tuitionMainContainer"
            style={{ width: "100vw" }}
          >
            <TopBarUi
              agoraEngine={agoraEngine.current}
              showAlert={showAlert}
              sessionData={sessionData}
            />
            <Grid container item xs={12}>
              <Grid container item xs={9.4} className="whiteboardContainer">
                {showVideoPlayer ? (
                  <Grid
                    item
                    xs={12}
                    container
                    style={{
                      width: "80%",
                      height: "75vh",
                      position: "absolute",
                    }}
                  >
                    <Grid item xs={11}>
                      <ReactPlayer
                        className="react-player"
                        url={videoUrlForPlayback}
                        width="100%"
                        height="auto"
                        controls
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                        playing={true}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <div
                        className="leaveButton closeVideo"
                        style={{ height: "33px" }}
                        onClick={closeVideoModal}
                      >
                        Close
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <div
                      id="whiteboard"
                      style={{
                        width: "100%",
                        height: "100%",
                        touchAction: "none",
                      }}
                    ></div>
                    {/* <!--Define the style of the toolbar and add it to the web page--> */}
                    <div
                      id="toolbar"
                      style={{ position: "relative", zIndex: "10" }}
                    ></div>
                  </>
                )}

                {/* </div> */}
              </Grid>
              <Grid container item xs={2.4} className="containerB">
                <Grid container item xs={12} className="userVideoContainer">
                  <div id="host-stream"></div>
                  {/* <div className="videoOverlay"></div> */}
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  className="studentVideoContainer"
                  id="studentVideoContainer"
                  style={{ marginBottom: 15 }}
                >
                  <div id="local-stream"></div>
                  {/* <Grid
                    container
                    item
                    xs={12}
                    className="hideScrollBar studentsVideoContainer"
                    id="local-stream"
                  ></Grid> */}
                </Grid>
                <Grid container item xs={12}>
                  <ChatContainer
                    messageArr={messageArr}
                    profileData={profileData}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} className="controlPannel">
                <Grid item className="timeLabel" xs={3}>
                  {timenow}
                </Grid>
                <Grid
                  item
                  className="actions"
                  xs={0.5}
                  title="Toggle full screen"
                >
                  {fullScreenFlag ? (
                    <FullscreenExit
                      sx={{ fontSize: "24px" }}
                      onClick={closeFullscreen}
                    />
                  ) : (
                    <FullscreenIcon
                      sx={{ fontSize: "24px" }}
                      onClick={fullScreen}
                    />
                  )}
                </Grid>

                {/* <Grid
                  item
                  xs={0.5}
                  className="actions"
                  onClick={sendHandRaiseRequest}
                  title="Hand Raise"
                >
                  <HandRaise />
                </Grid> */}

                <Grid
                  item
                  xs={0.5}
                  className="actions"
                  onClick={() => muteUnmuteAudio()}
                  title={
                    ismicrophoneMuted
                      ? "Click to turn on Mic"
                      : "Click to turn off Mic"
                  }
                >
                  {ismicrophoneMuted ? (
                    <MicOffIcon sx={{ fontSize: 28 }} />
                  ) : (
                    <MicIcon sx={{ fontSize: 28, color: "blue" }} />
                  )}
                </Grid>
                <Grid
                  item
                  xs={0.5}
                  className="actions"
                  onClick={muteUnmuteVideo}
                  title={
                    isCameraMuted
                      ? "Click to turn on Camera"
                      : "Click to turn off Camera"
                  }
                >
                  {isCameraMuted ? (
                    <VideocamOffIcon sx={{ fontSize: 28 }} />
                  ) : (
                    <VideocamIcon sx={{ fontSize: 28, color: "blue" }} />
                  )}
                </Grid>
                {/* <Grid item xs={0.5} className="actions">
              <HandRaise />
            </Grid> */}
                <Grid item xs={2} className="actions" onClick={leaveClass}>
                  <div className="leaveButton">Leave class</div>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={2} className="actions">
                  <TextField
                    id="message-input"
                    label="enter message"
                    autoComplete="off"
                    variant="standard"
                    inputProps={{ maxLength: constants.CHAT_LENGTH }}
                    style={{ width: "100%" }}
                    onChange={handleMessageInput}
                    onKeyPress={handleKeyPress}
                  />
                </Grid>
                <Grid
                  item
                  xs={0.3}
                  className="actions"
                  style={{ marginTop: "8px" }}
                  onClick={sendMessage}
                  id="send-message-button"
                >
                  <SendIcon style={{ marginTop: "24px" }} />
                </Grid>
                {/* <Grid container item xs={1} className="mesasageInputContainer">
                  <Grid item xs={11}>
                    <TextField
                      id="message-input"
                      label="enter message"
                      variant="standard"
                      // onChange={handleMessageInput}
                      // onKeyPress={handleKeyPress}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      className="sendButton"
                      id="send-message-button"
                    >
                      <SendIcon />
                    </div>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>{" "}
        </>
      ) : (
        <Grid className="tuitionLandingPageContainer">
          <Grid container item xs={6} className="centerCard">
            <Grid item xs={5}>
              <div id="video-test"></div>
              <div className="labels" style={{ paddingLeft: "8px" }}>
                Check if you can see your video
              </div>
            </Grid>
            <Grid item xs={5}>
              {" "}
              <canvas id="visualizer"></canvas>
              {/* <Meter /> */}
              <div className="labels">
                Make some noise and check if the graph responds
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              className="tuitionLandingPageContainer leavebuttonContainer"
            >
              {!waitingForTeacher ? (
                <>
                  <div
                    className="labels label2"
                    style={{ paddingTop: 14, marginRight: 5 }}
                  >
                    If both are working Continue to join class
                  </div>
                  <div className="leaveButton" onClick={join}>
                    Join class
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="labels label2"
                    style={{ paddingTop: 14, marginRight: 5 }}
                  >
                    We will let you in once the teacher joins
                  </div>
                  <div className="waitingButton">
                    <Box sx={{ display: "flex" }}>
                      {" "}
                      Waiting for teacher&nbsp;
                      <CircularProgress size="1rem" />
                    </Box>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
function TopBarUi(props: any) {
  type AllowedColors =
    | "primary"
    | "success"
    | "warning"
    | "info"
    | "secondary"
    | "error"
    | "inherit"
    | undefined;

  const [uplinkColor, setUplinkColor] = useState<AllowedColors>(undefined);
  const [downlinkColor, setDownlinkColor] = useState<AllowedColors>(undefined);
  const [uplink, setUplink] = useState(10);
  const [downlink, setDownlink] = useState(10);
  var showAlert = props.showAlert;
  // used for communicating the connection state with the user.
  props.agoraEngine.on(
    "connection-state-change",
    (currentState: any, reason: any, oldState: any) => {
      // console.log(currentState, oldState, reason);
      switch (currentState) {
        case "DISCONNECTED":
          showAlert(
            "error",
            "You are disconnected, Please check your internet connection."
          );
          break;
        case "CONNECTING":
          showAlert("error", "Waiting for server.");
          break;
        case "CONNECTED":
          showAlert("success", "You have joined the class room.");
          break;

        case "RECONNECTING":
          swal({
            title: "Network down!",
            text: "You are disconnected from the internet! please check your internet connection",
            timer: 3000,
            icon: "error",
          });
          showAlert(
            "error",
            "Experiencing network connectivity issues. Trying to reconnect..."
          );
          break;
        // case "FAILED":
        //   showAlert("error","The client has failed to connect or has lost its connection to the server.");
        //   break;
        default:
          showAlert("error", "Unknown connection state: " + currentState);
          break;
      }
    }
  );
  // for communicating uplink and downlink quality with user
  props.agoraEngine.on("network-quality", (quality: any) => {
    // console.log(quality);
    switch (quality.downlinkNetworkQuality) {
      case 0:
        setDownlinkColor("error");
        setDownlink(0);

        break;
      case 1:
        setDownlinkColor("success");
        setDownlink(100);
        break;
      case 2:
        setDownlinkColor("success");
        setDownlink(80);
        break;
      case 3:
        setDownlinkColor("warning");
        setDownlink(50);
        break;
      case 4:
        setDownlinkColor("warning");
        showAlert("error", "Unstable Internet connection");
        setDownlink(40);
        break;
      case 5:
        showAlert("error", "Experiencing network connectivity issues");
        setDownlinkColor("error");
        setDownlink(20);
        break;
      case 6:
        showAlert(
          "error",
          "Network Down! please check your internet connection"
        );
        setDownlinkColor("error");
        setDownlink(0);
        break;
    }
    switch (quality.uplinkNetworkQuality) {
      case 0:
        setUplinkColor("error");
        setUplink(0);
        break;
      case 1:
        setUplinkColor("success");
        setUplink(100);
        break;
      case 2:
        setUplinkColor("success");
        setUplink(80);
        break;
      case 3:
        setUplinkColor("warning");
        setUplink(50);
        break;
      case 4:
        setUplinkColor("warning");
        setUplink(40);
        break;
      case 5:
        setUplinkColor("error");
        setUplink(20);
        break;
      case 6:
        setUplinkColor("error");
        setUplink(0);
        break;
    }
  });

  return (
    <Grid container item xs={12} className="">
      <Grid container item xs={2} style={{ padding: "5px" }}>
        {" "}
        <DoubtboxLogo />
        {/* <div onClick={props.enablePointer}>Pointer</div> */}
      </Grid>
      {/* <Grid container item xs={7.5}> */}
      <Grid container item xs={7.5} className="chapternameContainer">
        <span style={{ color: "#0AA245" }}>
          {props.sessionData.chapter_name}
        </span>
      </Grid>

      {/* </Grid> */}
      <Grid container item xs={2.5}>
        {/* Shows the uplink and downlink */}
        <LinearProgress
          variant="determinate"
          color={uplinkColor}
          style={{ width: "12vw", marginTop: "15px", marginLeft: "12px" }}
          value={uplink}
        />
        <span
          className="labels"
          style={{ marginTop: "5px", marginLeft: "5px" }}
        >
          {" "}
        </span>{" "}
        <UploadCloud style={{ marginTop: "7px", marginLeft: "5px" }} />
        <LinearProgress
          variant="determinate"
          color={downlinkColor}
          style={{ width: "12vw", marginTop: "15px", marginLeft: "12px" }}
          value={downlink}
        />
        <span
          className="labels"
          style={{ marginTop: "5px", marginLeft: "5px" }}
        >
          {" "}
        </span>{" "}
        <DownloadCloud style={{ marginTop: "7px", marginLeft: "5px" }} />
      </Grid>
    </Grid>
  );
}

function ChatContainer(props: any) {
  useEffect(() => {
    console.log(props.messageArr);
  }, [props.messageArr]);
  return (
    <Grid container item xs={12} className="chatContainer">
      <Grid className="messageContainer" item xs={12}>
        <div className="chatWindow">
          <ul className="chat" id="chatList">
            {props.messageArr.length > 0 ? (
              props.messageArr.map((message: any) =>
                message.name === props.profileData.name ? (
                  <li className="self">
                    <div className="msg">
                      <p>{message.name}</p>
                      <div className="message"> {message.content}</div>
                    </div>
                  </li>
                ) : (
                  <li className="other">
                    <div className="msg">
                      <p>{message.name}</p>
                      <div className="message"> {message.content}</div>
                    </div>
                  </li>
                )
              )
            ) : (
              <li className="other">
                <div className="msg">
                  <div className="message"> Chat Here!</div>
                </div>
              </li>
            )}

            {/* {
              props.mesageArr.map(() => (
                <li className="self">
                  <div className="msg">
                    <p>sender</p>
                    <div className="message"> My message</div>
                  </div>
                </li>

              ))
              : <li className="other">
                <div className="msg">
                  <p>Sender Name</p>
                  <div className="message"> Sender Message</div>
                </div>
              </li> 
            } */}
          </ul>
        </div>
      </Grid>
    </Grid>
  );
}

function AutoRotateComponent() {
  return (
    <Grid
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        zIndex: 999,
      }}
      className="deviceRotate"
    >
      <div style={{ marginTop: "45vh" }}>
        <ScreenRotationIcon sx={{ fontSize: "60px" }} />
      </div>
      <div>
        {" "}
        Please rotate your device, Class room has the best experience in
        landscape mode
      </div>
    </Grid>
  );
}
