import { Checkbox, Grid, MenuItem, TextField } from "@mui/material";
import "./assessMeComponents.scss";
import { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import { QuestionAnswer } from "@mui/icons-material";
import { getFromLocalStorage,  findTimeSpentOnQuestion } from "../../../shared/utils/utilityFunctions";

export default function MTFComponent(props: {
  markAnswer: any;
  question: any;
  prevQuestion: any;
  nextQuestion: any;
  answeredData: any;
  currQnindex: any;
  qnStartTime:any;

}) {
  const [optionsArray, setOptionsArray] = useState([""]);
  var [skipped, setSkipped] = useState(false);
  var [marked, setMarkForReview] = useState(false);

  const [answerArr, setAnswerArr] = useState<any[]>([]);
  function createMarkup(value: string) {
    return {
      __html: value,
    };
  }
  function makeOptions() {
    var tempaArray: any[] = [];
    for (let i = 0; i < props.question.o.length; i++) {
      let elem = props.question.o[i].c2;
      tempaArray.push(elem);
    }
    setOptionsArray(tempaArray);
  }
  useEffect(() => {
    makeOptions();
  }, [answerArr]);

  useEffect(() => {
    // getData()
    // function getData(){
    // let data = getFromLocalStorage("submittedArray")
    setMarkForReview(props.answeredData[props.currQnindex].marked?true : false);
    console.log(props.answeredData);
    let currQn = props.answeredData[props.currQnindex];
    if (props.answeredData[props.currQnindex]) {
      console.log("ansered aan");
      if (currQn && currQn.answer && currQn.answer.match) {
        for (let i = 0; i < currQn.answer.match.length; i++) {
          (
            document.getElementById(constants.APLPHABETS[i]) as HTMLInputElement
          ).value = currQn.answer.match[i];
        }
        setAnswerArr(currQn.answer.match);
      }
    } else {
    }
    console.log(answerArr);
    //   let currQ = data[props.question.qn]
    //   console.log(currQ)
    // }
  });
  function selectAnswer(index: number) {}

  function showOptions(index: number) {}
  function handleInput(sino: number) {
    answerArr[sino - 1] = (
      document.getElementById(
        constants.APLPHABETS[sino - 1]
      ) as HTMLInputElement
    ).value;
  if(answerArr[sino - 1] === ("a"||"b"||"c"||"d"))
    setAnswerArr(answerArr);
    console.log(answerArr);
  }
  const markForReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(event.target.value, id);
    setMarkForReview(marked => !marked)
    console.log(marked)
    // let currQn:any = questions[currQnindex];
    // currQn.marked= true;
    // // console.log(questions)
    // setQuestions(questions)
  };

  function changeQuestion(param: boolean) {
    // param true = next question
    // param false = previous question
    if (param) {
      let packageId = getFromLocalStorage("packageId");

      let apiData = {
        qnNo: props.question.qn,
        answer: {
          question_id: props.question.content_uuid,
          totaltime: findTimeSpentOnQuestion(props.qnStartTime),
          package_id: packageId,
          question_type: "MatchTheFollowing",
          skipped: answerArr.length === 0 ? true : false,
          match: answerArr,
        },
      };

      props.nextQuestion(apiData, marked);
    } else {
      props.prevQuestion();
    }
  }
  function clear() {
    for (let i = 0; i < answerArr.length; i++) {
      answerArr[i] = "";

     (document.getElementById(constants.APLPHABETS[i]) as HTMLInputElement).value = "";
    }
    setAnswerArr(answerArr)
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          item
          xs={10.4}
          className=""
          dangerouslySetInnerHTML={createMarkup(props.question.q)}
        >
          {/* {props.question.q} */}
        </Grid>
        <Grid className="mtfOptionsContainer" xs={12}>
          {/* <Grid xs={0} md={1} className="" item></Grid> */}
          <Grid xs={6} md={4} item>
            {props.question.o.map((option: any) => {
              return (
                <Grid item container xs={12} key={option.c1}>
                  <Grid item xs={2} className="mtfQuestionOptions pt">
                    {constants.APLPHABETS[option.SiNo - 1]}
                  </Grid>
                  <Grid
                    className="p-l-14"
                    dangerouslySetInnerHTML={createMarkup(option.c1)}
                    item
                    xs={10}
                  ></Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid xs={0} md={2} className="" item>
            {props.question.o.map((option: any) => {
              return <Grid item xs={12} className="p-l-14" key={option.c1}></Grid>;
            })}
          </Grid>

          <Grid xs={6} md={4} item>
            {props.question.o.map((option: any, index: number) => {
              return (

                <Grid item container xs={12} key={option.c1}>
                <Grid item xs={2} className="mtfQuestionOptions a" style={{padding:'10px'}}>
                <input
                      type="text"
                      placeholder=""
                      className=""
                      id={constants.APLPHABETS[option.SiNo - 1]}
                      maxLength={1}
                      autoComplete="off"
                      style={{
                        width: "20px",
                        // border: "none",
                        height: "30px",
                      }}
                      // value={}
                      onChange={(e) => handleInput(option.SiNo)}
                    ></input>
                </Grid>
                <Grid
                  className="p-l-14"
                  dangerouslySetInnerHTML={createMarkup(option.c2)}
                  item
                  xs={10}
                ></Grid>
              </Grid>





                // <Grid
                //   item
                //   container
                //   xs={12}
                //   className="mtfQuestionOptions select"
                //   key={option.SiNo}
                // >
                //   <Grid item xs={2} className="mtfQuestionOptions pt a">
                //     <input
                //       type="text"
                //       placeholder=""
                //       className=""
                //       id={constants.APLPHABETS[option.SiNo - 1]}
                //       maxLength={1}
                //       autoComplete="off"
                //       style={{
                //         width: "20px",
                //         // border: "none",
                //         height: "30px",
                //       }}
                //       // value={}
                //       onChange={(e) => handleInput(option.SiNo)}
                //     ></input>
                //   </Grid>
                //   <Grid
                //     className="p-l-14"
                //     dangerouslySetInnerHTML={createMarkup(option.c2)}
                //     item
                //     xs={10}
                //   ></Grid>
                // </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
        <Checkbox
            onChange={(e) => {
              markForReview(e, props.question);
            }}
            checked={marked}
            defaultChecked={marked}
          />
          <span className="questionNo">
            {" "}
            Mark for review 
          </span>
          {/* <FormControlLabel
        value="end"
        control={<Checkbox />}
        label="End"
        labelPlacement="end"
      /> */}
        </Grid>
        <Grid container item xs={2.5}>
          <span className="clearButton" onClick={clear}>
            Clear
          </span>
        </Grid>
        <Grid container item xs={1.5}>
          <span
            className="nextButton previous"
            onClick={() => changeQuestion(false)}
          >
            Previous
          </span>
        </Grid>
        <Grid container item xs={2}>
          <span className="nextButton" onClick={() => changeQuestion(true)}>
            Next
          </span>
        </Grid>
      </Grid>
    </>
  );
}
