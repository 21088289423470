import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import "../LearnOn.scss";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import { getFromLocalStorage } from "../../../shared/utils/utilityFunctions";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { constants } from "../../../constants/constants";

export function LearnOnContentsPage() {
  const location = useLocation();
  const [tree, setTree] = useState(location.state.Tree);
  const [contentData, setContentData] = useState(
    location.state.proprietaryData
  );
  const [contentType, setContentType] = useState("Videos");
  const [isPlaying, setPlaying] = useState(true);

  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    profile_image:""
  });
  const [listData, setListData] = useState([
    { content_orginalname: "", content_uuid: "",content_order:0 },
  ]);
  const [nextContentType, setNextContentType] = useState("Textbook");
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  console.log(hideNavBar);
  // for alert messaging

  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const selectedChapter = getFromLocalStorage("selectedChapter");

  const [loader, setLoader] = useState(true);

  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }
  const [dataUrl, setDataUrl] = useState("");

  function toggleContentType() {
    setDataUrl("");
    if (nextContentType === "Textbook") {
      console.log("fetch text");
      setLoader(true);
      console.log(contentData);
      let apiData = {
        chapter: contentData.chapter,
        classId: profileData.standard,
        subject: contentData.subject,
        syllabusId: profileData.syllabus,
        textbook: contentData.textbook,
        contentType: "Textbook",
      };
      services.getContentListing(apiData).then((res) => {
        setLoader(false)
        console.log(res);
        let item = res.data.data.url;
        setDataUrl(item);
        console.log(item);
        // services.getSignedUrl(item.content_uuid);
      });
      setNextContentType("Videos");
    } else {
      setNextContentType("Textbook");
    }
  }
  useEffect(() => {
    validateLoggedIn();
  }, []);
  useEffect(() => {
    setListByLooping();
  }, []);

  useEffect(() => {
    selectContent(listData[0].content_uuid);
  }, [listData]);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setPlaying(true);
    console.log("dataUrl", dataUrl);
  }, [dataUrl]);

  function setListByLooping() {
    let tempArr = [];
    for (let i = 0; i < tree.length; i++) {
      let branch = tree[i];
      console.log(branch);
      for (let j = 0; j < branch[contentType].length; j++) {
        let leaf = branch[contentType][j];
        leaf["TopicID"] = branch.TopicID;
        leaf["TopicNumber"] = branch.TopicNumber;
        leaf["TopicName"] = branch.TopicName;
        leaf["ContentOrder"] = parseInt(branch.content_order);
        tempArr.push(leaf);
      }
    }
    console.log(tempArr);
    tempArr.sort(function(a, b) {
      return parseInt( b.content_order) + parseInt(a.content_order);
    });
    console.log(tempArr);

    setListData(tempArr);
  }
  const navigate = useNavigate();

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string, props?: any) {
    navigate(path, props);
  }

  function selectContent(selectedData: string) {
    setLoader(true);
    console.log(selectedData);
    if (selectedData) {
      services.getSignedUrl(selectedData).then((res) => {
        setLoader(false);
        if (res.failed) return showAlert("error", res.message);
        else {
          console.log(res.data.data.url);
          setDataUrl(res.data.data.url);
        }
      });
    }
  }
  function goBack() {
    let state = { sub: contentData.subject, name: contentData.subjectName };

    navigate("/learn-on", { state: state });
  }
  const noContext = document.getElementById("noContextMenu");
  if (noContext && noContext != null)
    noContext.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  return (
    loader ? <LoaderComponent /> :
    <div className="SettingsProfileContainer">
      <TopBarComponent search={false} logo={true} profileData={profileData} />

      {nextContentType === "Textbook" ? (
        <Grid container className="contentsPageLayout">
          {hideNavBar && (
            <Grid xs={12}>
              <ReactPlayer
                className="react-player"
                url={dataUrl}
                width="100%"
                height="auto"
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                playing={isPlaying}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4} className="p-14">
            <Grid className="learnOnLayout1  p-14">
              <Grid container style={{ width: "25vw" }}>
                <Grid xs={12}>
                  {hideNavBar && (
                    <Button
                      variant="contained"
                      sx={{
                        background: "#0E2744",
                        width: "112px",
                        marginLeft: "55vw",
                        marginBottom: "15px",
                      }}
                      onClick={toggleContentType}
                    >
                      {nextContentType}
                    </Button>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  className="learnOnChapter"
                  onClick={goBack}
                >
                  Chapter :
                </Grid>
                <Grid item xs={12} md={9} className="learnOnChapterName">
                  {selectedChapter}
                </Grid>
              </Grid>
              <Grid className="contentList hideScrollBar">
                {listData.map((content: any) => (
                  <ContentItem
                    key={content.content_uuid}
                    value={content}
                    selectContent={selectContent}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11} sm={7.5} className="contentContainer">
            {!hideNavBar && (
              <Button
                variant="contained"
                sx={{
                  background: "#0E2744",
                  width: "112px",
                  marginLeft: "55vw",
                  marginBottom: "15px",
                }}
                onClick={toggleContentType}
              >
                {nextContentType}
              </Button>
            )}

            {!hideNavBar && (
              <Grid xs={12}>
                <ReactPlayer
                  className="react-player"
                  id="noContextMenu"
                  url={dataUrl}
                  width="100%"
                  height="auto"
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                  playing={isPlaying}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={11} className="">
          <Button
            variant="contained"
            sx={{
              background: "#0E2744",
              width: "112px",
              marginLeft: "89vw",
              marginTop: "15px",
            }}
            onClick={toggleContentType}
          >
            {nextContentType}
          </Button>
          <Grid style={{ width: "99%", height: "99vh" }}>
            {dataUrl&& dataUrl.length>0?<iframe
              title="Textbook"
              style={{ width: "100%", height: "100%", margin: "5px" }}
              src={dataUrl}
            ></iframe>: <div className="noSessions">
            {" "}
            <p style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}>
              No textbooks uploaded
              .
            </p>
          </div>}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
{
  /* <div
style={{ width: "100%", height: "100%" }}
onMouseDown={(e) => e.preventDefault()}
onContextMenu={(e) => e.preventDefault()}
>
<iframe
  style={{ width: "100%", height: "100%" }}
  src={dataUrl}
></iframe>
</div> */
}
function ContentItem(props: {
  key: string;
  value: {
    content_uuid: string;
    content_orginalname: string;
    TopicID: number;
    topicName: string;
    TopicNumber: number;
  };
  selectContent: any;
}) {
  let contentNameT = props.value.content_orginalname.split("_")[1];
  let contentName = contentNameT?contentNameT:props.value.content_orginalname
  // if (!contentName) contentName = props.value.content_orginalname;
  return (
    <Grid
      container
      className="eachContentCard"
      onClick={() => props.selectContent(props.value.content_uuid)}
    >
      <Grid item xs={1} md={1} className="eachContentL1">
        <OndemandVideoIcon />
      </Grid>
      <Grid item xs={11} md={11} className="eachContentL2">
        <div className="eachContentL2TopicName">
          {contentName?.replace(".mp4", "")}
        </div>
        {/* <div className="eachContentL2Duration">03:15 min</div> */}
      </Grid>
    </Grid>
  );
}
