import axios from "axios";
import { constants, getBaseUrl } from "../constants/constants";
import Cookies from 'js-cookie';
import { routes } from "../services/routes";
import swal from "sweetalert";
import { getFromLocalStorage } from "../shared/utils/utilityFunctions";

// axios.defaults.withCredentials = true;
interface ResponseClass {
  response: any;
  data: any;
  headers: any;
}

export const callApi = async (method: string, url: string, params?: any) => {
  var result: ResponseClass = {
    response: undefined,
    data: undefined,
    headers: undefined
  }

  const apiKey = process.env.REACT_APP_API_KEY;
  // console.log(Cookies.get(constants.X_ACCESS_TOKEN))
  const xAccessToken = Cookies.get(constants.X_ACCESS_TOKEN)
  const xSecretToken = Cookies.get(constants.X_SECRET_TOKEN)
  const xAccessTokenTimestamp = Cookies.get(constants.AUTH_TOKEN_TIME)
  let tokenDelta = 0
  //Making the request object
  var request = {
    cache: false,
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
      "x-access-token": xAccessToken ? xAccessToken : null,
    },
    // withCredentials:true
  };
  if (xAccessTokenTimestamp)
    tokenDelta = new Date().getTime() - parseInt(xAccessTokenTimestamp)
  try {
    console.log(method, url, params);
    console.log(tokenDelta)
    if (tokenDelta > constants.AUTH_TIME_BUFFER) {
      console.log('calling refresh token')
      console.log({tokenDelta,xAccessTokenTimestamp,AUTH_TIME_BUFFER:constants.AUTH_TIME_BUFFER})
      //Call refresh token api here 
      const usr: any = getFromLocalStorage("userData");
      const refreshUrl = getBaseUrl() + routes.GET_APP_TOKEN
      let refreshParams = {
        "email": usr.email ? usr.email : "",
        "refreshToken": xSecretToken
      }
      console.log(refreshParams)
      result = await axios.post(refreshUrl, refreshParams, request);
      console.log(result);
      Cookies.set(constants.X_ACCESS_TOKEN, result.headers[constants.X_ACCESS_TOKEN])
      Cookies.set(constants.AUTH_TOKEN_TIME, JSON.stringify(new Date().getTime()))
      console.log("token refreshed")
    }


    function checkRedirect(result: ResponseClass) { 
      if (
        result &&
        result.hasOwnProperty("data") &&
        result.data.hasOwnProperty("redirect")
      ) {
        return (window.location.href = "/login");
      } else {
        return result;
      }
    }

    if (method === "post") {
      result = await axios.post(url, params, request);
      console.log(result);
      // checkRedirect(result);
      return result.data;
    }
    if (method === "get") {
      result = await axios.get(url, request);
      console.log(result);
      // checkRedirect(result);
      return result;
    }
    if (method === "put") {
      result = await axios.put(url, params, request);
      console.log(result);
      // checkRedirect(result);
      return result;
    }

    // Add new methods if required


  } catch (err: any) {
    console.error("↓↓↓ error logged below! ↓↓↓");
    console.log(err)
    if (err && err.response && err.response.data) {
      //Executing except for login apis
      if (!url.includes(routes.LOGIN)) {
        if (err.response.status === constants.HTTP_FORBIDDEN) {
          /**
             If the server returns a status code of 403, it indicates that the session has expired.
             To refresh the session, the user must log in again. As a result,
             the user is automatically logged out and redirected to the login page.
          */

          swal({
            title: "Session Expired!",
            text: "Authorization failed! Please login again!",
            icon: "info",
            buttons: {
              confirm: {
                text: "OK",
                value: false,
                visible: false,
                className: "",
                closeModal: false,
              },
            },
          });
          setTimeout(async () => {
            //log out after a duration of 1.5 sec. All local storage data is cleared
            await localStorage.clear()

            // Remove all cookies
            const cookies = Cookies.get();
            for (const cookieName in cookies) {
              Cookies.set(cookieName, '', { expires: new Date(0) });
            }
            window.location.href = "/login"
          }, 1500);
        }

      }

      err.response.data["failed"] = true;
      return err.response.data;
    } else {
      err["failed"] = true;
      return err;
    }
  } finally {
    //Executing only for login apis
    if (url.includes(routes.LOGIN) || url.includes(routes.VERIFY_LOGIN_OTP)) {
      // console.log(result.headers[constants.X_SECRET_TOKEN])
      if (result && result.headers && result.headers[constants.X_ACCESS_TOKEN]) {
        // should implement secure flag later on
        Cookies.set(constants.X_ACCESS_TOKEN, result.headers[constants.X_ACCESS_TOKEN])
        Cookies.set(constants.X_SECRET_TOKEN, result.headers[constants.X_SECRET_TOKEN])
        Cookies.set(constants.AUTH_TOKEN_TIME, JSON.stringify(new Date().getTime()))
      }

    }
  }
};
