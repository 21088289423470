/*
* utilityFunctions.ts
* 
* @author Rahul krishna.
* @date 01/11/2022.
* @version 1.0.0

=> Functions that are used mutiple times can be written and called from here
*/
import { Subject } from "@mui/icons-material";
import { constants, ERROR_CODES } from "../../constants/constants";

export function formatSecTommss(time: number) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + String(mins).padStart(2, "0") + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

export function setToLocalStorage(variable: string, item: any) {
  var itemString = JSON.stringify(item);
  localStorage.setItem(variable, itemString);
}

export function getFromLocalStorage(variable: string) {
  var val = localStorage.getItem(variable);

  if (val !== null && val !== undefined && val !== "undefined")
    return JSON.parse(val);
  else {
    return [];
  }
}

export function getDate(date?: any) {
  let objectDate = date ? new Date(date) : new Date();
  let day = objectDate.getDate();
  let month = objectDate.getMonth() - 1;
  let year = objectDate.getFullYear();

  return day + " " + constants.MONTH_NAMES_MIN[month + 1] + " " + year;
}

export function imageReplace(i?: string) {
  if (!i) return "img/chem8.jpg";
  i = i.replace("s3://", "https://s3.ap-south-1.amazonaws.com/");
  return i;
}

export function findTimeSpentOnQuestion(start: number) {
  let now = new Date();
  let end = now.getTime();
  let val = end - start;
  return Math.round(val / 1000);
}

export const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getButtonValue(sst: string, set: string, sd: string) {
  if (sst && set && sd) {
    let hostDate = new Date(sd);
    let today = new Date();
    let sstArr = sst.split(":");
    let h = parseInt(sstArr[0]);
    let sst1Arr = sstArr[1].split(" ");
    let m = sst1Arr[0];
    let ampm = sst1Arr[1];
    if (ampm === "AM" && h === 12) {
      h = 0;
    } else if (ampm === "PM" && h < 12) {
      h = h + 12;
    }
    let hostEndDate = new Date(sd);
    let setArrEnd = set.split(":");
    let hEnd = parseInt(setArrEnd[0]);
    let set1ArrEnd = setArrEnd[1].split(" ");
    let mEnd = set1ArrEnd[0];
    let ampmEnd = set1ArrEnd[1];
    if (ampmEnd === "AM" && hEnd === 12) {
      hEnd = 0;
    } else if (ampmEnd === "PM" && hEnd < 12) {
      hEnd = hEnd + 12;
    }

    hostDate.setHours(h);
    hostDate.setMinutes(parseInt(m));
    hostEndDate.setHours(hEnd);
    hostEndDate.setMinutes(parseInt(mEnd));
    if (hostDate <= today && today < hostEndDate) {
      return "Join Now";
    } else return "More Details";
  } else return null;
}

export function getTimer(sst: any) {
  let sessionStart = new Date(sst);
  let today = new Date();
  let timeRemaining = sessionStart.getTime() - today.getTime();
  return msToHMS(timeRemaining);
}
export function msToHMS(ms: number) {
  // 1- Convert to seconds:
  let seconds = Math.round(ms / 1000);
  // 2- Extract hours:
  // const hours = Math.round(seconds / 3600); // 3,600 seconds in 1 hour
  let hoursRaw = seconds / 3600;
  let decimal = hoursRaw % 1;
  const hours =
    decimal > 0.5 ? Math.round(seconds / 3600) - 1 : Math.round(seconds / 3600);
  seconds = Math.round(seconds % 3600); // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = Math.round(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = Math.round(seconds % 60);
  if (hours + minutes + seconds <= 0) return constants.JOIN_NOW;
  return (
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
}
export function formatAMPM(date: Date): string {
  let hours = date.getHours();
  let minutes: any = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  // if minutes are in numbers, 00 cannot be shown instead 0 will be shown
  // ex: if time is 7:00 PM, it will show 7:0 PM To fix this, below modification is made.
  minutes = minutes === 0 ? "00" : minutes;
  const strTime: string = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

// Returns true for landscape
export function checkOrientation() {
  const { innerWidth: width, innerHeight: height } = window;
  return width > height;
}

// returns string with no spaces
export function makeAgoraUid(name: string) {
  if (name) return name.split(" ").join("");
  else return "";
}

// returns intials for the given name
export function getInitials(name: string) {
  // Check if the name is null, undefined, or empty
  if (!name || name.trim() === "") {
    return "🙂";
  }

  name = name.trim();
  const words = name.split(" ");
  let initials = "";

  // Iterate over each word
  if (words.length && words.length > 0) {
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      
      // Check if the word is not empty
      if (word.trim() !== "") {
        initials += word[0].toUpperCase();
        if (initials.length === 2) {
          break;
        }
      }
    }
  } else {
    return "🙂";
  }

  // Return the initials
  return initials;
}


export function getWebsocketUrl(sessId: string, uname: string) {
  console.log(
    "ws url = wss://ennfpcenti.execute-api.eu-west-1.amazonaws.com/dev?channelid=" +
      sessId
  );
  return (
    "wss://ennfpcenti.execute-api.eu-west-1.amazonaws.com/dev?channelid=" +
    sessId
  );
  // +"&userName="+uname
}
export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// ===== Currently not in use =======
// export function evsCondition(elem: any, profileData: any, caseValue: any) {
//   There is an Issue with the contents in EVS subject for classess 3,4 and 5.
// The issue is related to a textbook so they have created two subjects with two different names representing a single Subject
// which causes some unintented results in the UI.

// So this function is used to display one EVS subject at a time which differs from learn-on to mock-tests for the
// mentioned classes
//  case a is for learn-on and assess-me
//  case b is for recorded VideoSettings, worksheets, mock-tests

// if (caseValue === "a") {
//   console.log(
//     profileData.standard === 3 ||
//       profileData.standard === 4 ||
//       profileData.standard === 5,
//     elem.id === constants.EVS_ID
//   );

//   if (
//     elem.id === constants.EVS_ID &&
//     (profileData.standard === 3 ||
//       profileData.standard === 4 ||
//       profileData.standard === 5)
//   )
//     return true;
//   else return false;
// } else if (caseValue === "b") {
//   if (
//     elem.id === constants.EVIRONMENTAL_STUDIES &&
//     (profileData.standard === 3 ||
//       profileData.standard === 4 ||
//       profileData.standard === 5)
//   )
//     return true;
//   else return false;
// }

// return false
// }

// This function exist because in recorded classes, there are no content for english.
// this function removes any subjects that have english in it (As requested.)
export function removeEnglish(arr: any) {
  for (let i = arr.length - 1; i >= 0; i--) {
    const obj = arr[i];
    if (obj.name && obj.name.toLowerCase().includes("english")) {
      arr.splice(i, 1);
    }
  }
  return arr;
}

export function getErrorCode(e: any) {
  let er = e.toString();
  er.trim();
  let errorArr: string = er.split(":");
  let key = errorArr[0];
  let message = errorArr[1].trim();
  let reason: string = message.split(" ")[1];
  console.log(errorArr, reason);
  let errCode = "";
  if (errorArr[0] === "AgoraRTCException") {
    if (reason === "UID_CONFLICT") {
      errCode = ERROR_CODES[reason];
    } else {
      errCode = ERROR_CODES[key];
    }
  }

  return { errCode, message };
}

export function setFlagForPurchased(sessionData:any){
  if(!sessionData || sessionData.length <=0)
  return []
  else
  for(let i=0; i<sessionData.length; i++){
    let elem = sessionData[i]
    elem.purchased = true
  }
  return sessionData;
}

export function base64ToDataURL(base64String:any) {
  return `data:image/png;base64,${base64String}`;
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        // Extract the Base64 string from the Data URL
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      } else {
        reject(new Error('Failed to convert Blob to Base64.'));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}
